// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { sendRequest, Url } from "../../config";
import VideoConfrence from "./VideoConfrence";

let local_media_stream = null;

const useStyles = makeStyles(styles);
function RoomTest(props) {
  const classes = useStyles();
  const [socketIo, setSocket] = useState(null);
  const [courseid, setCourseid] = useState(null);
  const [isFree, setIsFree] = useState(null);
  let producerTransport = null;
  let producerTransportDisplay = null;
  let videoProducer = null;
  let audioProducer = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};
  let device = null;
  useEffect(() => {
    connection();
    setCourseid(props.match.params.id);
  }, []);

  const connection = () => {
    let socket = io.connect(Url);
    setSocket(socket);
    socket.on("connect", function () {
      socket.emit("joinRoom", {
        roomId: props.match.params.id,
      });
    });
    // confirm(props.match.params.id);
    socket.on("disconnect", function () {
      setIsFree(false);
      socketIo && socketIo.disconnect();
    });
    async function subscribe(socketIo) {
      if (!consumerTransport) {
        const params = await sendRequest("createConsumerTransport", {}, socketIo);
        consumerTransport = await device.createRecvTransport(params);
        consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
            .then(callback)
            .catch(errback);
        });

        consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              // consumeCurrentProducers(clientId, socketIo);
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });
        // consumeCurrentProducers(0, socketIo);
      }

      // --- join & start publish --
    }
    function removeRemoteVideo(id) {
      $("#remote_" + id).remove();
      $("#audio_remote_" + id).remove();
    }
    const confirm = (courseid) => {
      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;
      //
    };
  };
  const confirmerr = () => {
    window.location.href = "/";
  };
  return (
    <GridContainer>
      <GridItem md={12} sm={12}>
        <div dir="rtl">
          <div className={"video-container"}>
            <div className={"calling admin"}>
              <div className={"btn-container-top btn-container-top-column"}>
                <div className="btns">
                  <a href="/admin">
                    <button>خروج</button>
                  </a>
                </div>
                <div className="VideoConfrence">
                  <VideoConfrence />
                </div>
              </div>

              <div
                id="video-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxWidth: "100%",
                  flex: "0 0 100%",
                }}
                className="video"
              ></div>

              <div id="audio-container" className="video" style={{ display: "none" }}></div>
            </div>
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default RoomTest;
