//کد رنگ اصلی
export const mainColor = "#1C39BB";
//کد رنگ دوم
export const secondaryColor = "#00A693";
//کد رنگ سوم
export const secondaryColor2 = "#00A693";
//کد رنگ متن
export const textColorPrimary = "#222f3e";

//نام کسب و کار
export const pageTitle = "الگوریتم";

//تغییر فایل لوگو
// public/favicon.ico
// public/logo.png
// src/assets/img/login/logo.png

//تغییر فایل انیمیشن
// src/assets/animation/loginAnimation.json
