import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";
import { pageTitle } from "web-config";

//

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  //

  const { loading, pagination } = props;
  useEffect(() => {
    getOperators();
    props.store.dispatch(ReportAction.GetAllQueues());
    getTransferReports();
  }, loading);
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
  }, props.operators);

  const getOperators = () => {
    return props.store.dispatch(UserAction.GetOperators());
  };

  const getTransferReports = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.GetAllTransferCall(pagination));
  };
  const [flag, setFlag] = React.useState("ALL");

  /////
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperatorTwo(options);
      setDataSelectOperator(options);
    }
  }, props.operators);

  let handleChange = (newValue, actionMeta) => {
    setOperator(newValue);
  };
  let handleChangeTwo = (newValue, actionMeta) => {
    setOperatorTwo(newValue);
  };
  const [operator, setOperator] = React.useState("");
  const [operatorTwo, setOperatorTwo] = React.useState("");
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [dataSelectOperatorTwo, setDataSelectOperatorTwo] = React.useState([]);
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  ////

  ////

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 480,
        }}
        className="my-custom-input-class" // a styling class
      />
    </>
  );
  //// status
  // const [callStatus, setCallStatus] = React.useState("");
  // let handleChangeCallStatus = (newValue, actionMeta) => {
  //   setCallStatus(newValue);
  // };
  // let callStatusoptions = [
  //   { value: "answer", label: "تماس های تصویری موفق" },
  //   { value: "calling", label: "تماس های تصویری بی پاسخ یا لغو شده" },
  //   { value: "rejected", label: "تماس های تصویری رد شده" },
  //   { value: "answer-chat", label: "تماس های متنی موفق" },
  //   { value: "calling-chat", label: "تماس های متنی بی پاسخ یا لغو شده" },
  //   { value: "rejected-chat", label: "تماس های متنی رد شده" },
  // ];

  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };

  const [size, setSize] = React.useState(20);

  const fetchHistories = (pagination = { per_page: 20, page: 1 }) => {
    let arrayOperatorsId = [];
    if (operator && operator.length) {
      operator.forEach((item) => {
        //
        arrayOperatorsId.push(item.value);
      });
    }
    let arraySecoundOperatorsId = [];
    if (operatorTwo && operatorTwo.length) {
      operatorTwo.forEach((item) => {
        //
        arraySecoundOperatorsId.push(item.value);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    // let callStatusValues = [];
    // if (callStatus) {
    //   callStatus.forEach((item) => {
    //     callStatusValues.push(item.value);
    //   });
    // }

    props.store.dispatch(
      ReportAction.GetTransferCallReport(
        // callStatusValues,
        arrayOperatorsId,
        arraySecoundOperatorsId,
        selectedDayRange,
        queuesId
      )
    );
  };
  const onPageChangeFunction = (pageSize, pageIndex) => {
    //
    if (flag === "ALL") {
      getTransferReports({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      fetchHistories({ per_page: pageSize, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    setSize(pageSize);
    //
    if (flag === "ALL") {
      getTransferReports({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      fetchHistories({ per_page: pageSize, page: pageIndex + 1 });
    }
  };

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>انتقال تماس - {pageTitle}</title>
      </Helmet>
      <GridContainer>
        <Card>
          <GridItem xs={12}>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>

            <Grid container spacing={3}>
              <Grid item xs={5} style={{ margin: "24px", marginTop: "0px", marginLeft: "0px" }}>
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeQueue}
                    options={dataSelectQueue}
                    styles={customStyles}
                    defaultValue={queue}
                    placeholder="صف مورد نظر خود را انتخاب کنید"
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={5} style={{ margin: "24px", marginTop: "0px" }}>
                  <CreatableSelect
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={handleChangeCallStatus}
                    options={callStatusoptions}
                    styles={customStyles}
                    defaultValue={callStatus}
                    placeholder="وضعیت اپراتور"
                  />
                </Grid> */}
              <Grid item xs={6} style={{ marginTop: "0px", marginRight: "24px" }}>
                <Box>
                  <DatePicker
                    value={selectedDayRange}
                    renderInput={renderCustomInput}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="زمان گزارش"
                    shouldHighlightWeekends
                    calendarPopperPosition="bottom"
                    locale="fa"
                  />
                  <a>
                    <img
                      src={Reset}
                      style={{ width: "30px", height: "30px" }}
                      onClick={() => {
                        setSelectedDayRange({
                          from: null,
                          to: null,
                        });
                      }}
                    />
                  </a>
                </Box>
              </Grid>

              <Grid
                item
                xs={5}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  marginRight: "24px",
                }}
              >
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChange}
                    options={dataSelectOperator}
                    styles={customStyles}
                    defaultValue={operator}
                    placeholder="اپراتور  اول را انتخاب کنید"
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={5}
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  marginRight: "24px",
                }}
              >
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeTwo}
                    options={dataSelectOperatorTwo}
                    styles={customStyles}
                    defaultValue={operatorTwo}
                    placeholder="اپراتور  دوم را انتخاب کنید"
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  margin: "24px",
                  marginBottom: "24px",
                  marginTop: "0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    //
                    setFlag("FILTER");
                    fetchHistories({ per_page: 20, page: 1 });
                  }}
                >
                  اعمال
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    //
                    setFlag("ALL");
                  }}
                >
                  دریافت همه گزارشات
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {}}
                >
                  دریافت فایل
                </Button>
              </Grid>
            </Grid>

            <CardBody>
              <ReactTable
                rtl
                data={props.transferCall ? props.transferCall : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "اپراتور اول",
                    accessor: "operator",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.original.operator
                          ? row.original.operator.first_name + " " + row.original.operator.last_name
                          : ""}
                      </div>
                    ),
                  },
                  {
                    Header: "اپراتور دوم",
                    accessor: "second_operator",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.original.second_operator
                          ? row.original.second_operator.first_name + " " + row.original.second_operator.last_name
                          : ""}
                      </div>
                    ),
                  },
                  {
                    Header: "صف",
                    accessor: "menu",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div>{row.original.menu ? row.original.menu.name : ""}</div>,
                  },
                  {
                    Header: "تاریخ",
                    accessor: "date",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>{moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss")}</div>
                    ),
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  //
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  //
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  //
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </GridItem>
        </Card>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  //
  return {
    pagination: state.reports_2.pagination,
    loading: state.reports_2.loading,
    error: state.reports_2.error,
    transferCall: state.reports_2.transferCall,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};

const mapDispatchToProps = (dispatch) => {
  //
  return {
    UserAction: bindActionCreators({ UserAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
