import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function MediaBox(props) {
  const { loading, history, pagination } = props;
  useEffect(() => {
    getAll();
  }, loading);
  useEffect(() => {
    props.store.dispatch(ReportAction.GetAllQueues());
  }, []);

  const getAll = (pagination = { per_page: 20, page: 1 }) => {
    //
    return props.store.dispatch(UserAction.GetAllMediaBox(pagination));
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  ////

  /////
  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 500,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );
  /////

  ///////////
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    //
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  ///////////

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const getFilter = (pagination = { per_page: 20, page: 1 }) => {
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    props.store.dispatch(UserAction.GetFilterMediaBox(selectedDayRange, queuesId, pagination, timeStart, timeEnd));
  };

  const [flag, setFlag] = React.useState("ALL");
  const [size, setSize] = React.useState(20);

  const onPageChangeFunction = (pageIndex) => {
    //
    if (flag === "ALL") {
      getAll({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "HISTORY") {
      getFilter({ per_page: size, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    //
    setSize(pageSize);
    if (flag === "ALL") {
      getAll({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "HISTORY") {
      getFilter({ per_page: pageSize, page: pageIndex + 1 });
    }
  };

  const getFileExcell = () => {
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    //
    axios({
      url: ApiUrl + "/reports-media-box-excell",
      method: "post",
      params: {},
      data: {
        dateFrom: dateG_from,
        dateTo: dateG_to,
        queuesId: queuesId,
        timeStart,
        timeEnd,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> گزارش صندوق تصویری</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <Card>
              <Grid container spacing={1}>
                <Grid item xs={5} style={{ margin: "24px", marginButton: "0px" }}>
                  <Box>
                    <CreatableSelect
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable
                      onChange={handleChangeQueue}
                      options={dataSelectQueue}
                      styles={customStyles}
                      defaultValue={queue}
                      placeholder="صف مورد نظر خود را انتخاب کنید"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "24px",
                    marginBottom: "0px",
                    marginLeft: "0px",
                  }}
                >
                  <DatePicker
                    value={selectedDayRange}
                    renderInput={renderCustomInput}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="زمان گزارش"
                    shouldHighlightWeekends
                    locale="fa"
                    calendarPopperPosition={"bottom"}
                    calendarPosition={"bottom"}
                  />
                </Grid>
                <Grid item xs={5} style={{ margin: "0  24px 24px" }}>
                  <RangePicker
                    onChange={onChange}
                    placeholder={["زمان آغاز", "زمان پایان"]}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{
                      height: "60px",
                      width: "300px",
                      border: "1px solid black",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "17px",
                    }}
                  />
                </Grid>

                <Grid item xs={6} style={{ marginBottom: "24px", marginRight: "40px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      //
                      setFlag("HISTORY");
                      getFilter({ per_page: 20, page: 1 });
                    }}
                  >
                    اعمال
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      //
                      getAll();
                      setFlag("ALL");
                    }}
                  >
                    دریافت همه گزارشات
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      getFileExcell();
                    }}
                  >
                    دریافت فایل
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                data={props.historyCall ? props.historyCall : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "زمان شروع",
                    accessor: "start",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        return (
                          <div>
                            {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                              ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                              : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    },
                  },

                  {
                    Header: "تاریخ شروع",
                    accessor: "start",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        return (
                          <div>
                            {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                              ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                              : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    },
                  },

                  {
                    Header: "زمان پایان",
                    accessor: "end",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        return (
                          <div>
                            {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                              ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                              : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    },
                  },

                  {
                    Header: "تاریخ پایان",
                    accessor: "end",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        return (
                          <div>
                            {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                              ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                              : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")}
                          </div>
                        );
                      } else {
                        return "";
                      }
                    },
                  },

                  {
                    Header: "طول مکالمه",
                    accessor: "waitingTime",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.original) {
                        let answerField = row.original.end;
                        let startField = row.original.start;
                        let waitingTime = 0;
                        if (!answerField) {
                          return waitingTime;
                        }
                        let start;
                        let answer;
                        let answerFormat = moment(answerField, "YYYY-MM-DDTHH:mm:ss.sTZD").format(
                          "jYYYY/jM/jD HH:mm:ss"
                        );
                        let startFormat = moment(startField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
                        answer = new Date(answerFormat).getTime();
                        start = new Date(startFormat).getTime();
                        let min = Math.abs((answer - start) / (1000 * 60)).toFixed(2);
                        let san = Math.abs(answer - start) / 1000;
                        //
                        //   answerFormat,
                        //   startFormat,
                        //   answer,
                        //   start,
                        //   san,
                        //   min,
                        //   "answerFormat , startFormat ,answer ,start,san ,min"
                        // );
                        let minRound = Math.abs((answer - start) / (1000 * 60)).toFixed(0);
                        let second = Math.abs((answer - start) / 1000);
                        let n = min.indexOf(".");
                        let ss = (min.slice(n, n + 3) * 60).toFixed(0);
                        //
                        if (min === "NaN") {
                          waitingTime = 0;
                        } else if (min > 1) {
                          waitingTime = minRound + ":" + ss + " " + "دقیقه";
                        } else {
                          waitingTime = second + " " + "ثانیه";
                        }
                        return waitingTime;
                      }
                    },
                  },
                  {
                    Header: "صف",
                    accessor: "menu.name",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "وضعیت",
                    accessor: "videobox.is_checked",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div>{row.value ? <p>بررسی شده</p> : <p>بررسی نشده</p>}</div>,
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        <a href={"/admin/history/" + row.value}>مشاهده جزییات</a>
                      </div>
                    ),
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  //
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  //
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  //
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    historyCall: state.textBox.mediaBox,
    pagination: state.textBox.pagination_media,
    loading: state.textBox.loading,
    error: state.textBox.error,
    queues: state.reports_2.queues,
  };
};
const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaBox);
