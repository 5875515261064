import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Snackbar from "components/Snackbar/Snackbar";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import io from "socket.io-client";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { ApiUrl, Url } from "../../config";
import CustomerAction from "../../js/actions/customer_action";
import MissedCalls from "../../components/Recharts/MissedCalls";
import MissedCalls24Hours from "components/Recharts/MissedCalls24Hours";
import Box from "@material-ui/core/Box";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import AdminTabs from "../../components/AdminTabs";
import DurationBarChart from "../../components/Recharts/DurationBarChart";
import WaitingBarChart from "../../components/Recharts/WaitingBarChart";
import { ExitToAppRounded } from "@material-ui/icons";
import SuccessCall24Hours from "../../components/Recharts/SuccessCall24Hours";
import { pageTitle } from "web-config";

require("./style.css");

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const classes = useStyles();

  const [loading, isLoading] = useState(false);
  const [menuLoading, isMenuLoading] = useState(false);
  const [data, setData] = useState([]);
  const [qs, setQs] = useState({});
  const [qsOp, setQsOpr] = useState({});
  const [qsAc, setQsAc] = useState({});

  const [tabValue, setTabValue] = useState("");
  const [tabData, setTabData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [alert, setAlert] = React.useState(null);
  const [tl, setTL] = React.useState(false);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [onlineOperator, setOnlineOperator] = useState([]);
  const [active_chats, setactive_chats] = useState([]);
  const [socketIo, setSocketIO] = useState(null);

  if (props.message == 100) {
    if (!tl) {
      setTL(true);
      setTimeout(function () {
        setTL(false);
      }, 6000);
    }
  }

  useEffect(function () {
    if (!loading) {
      axios
        .get(ApiUrl + "/menu-items?status=true", {
          params: {},
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          isMenuLoading(false);
          //
          setData(response.data.data.menu_items);
          connection();
        })
        .catch(function (error) {
          isMenuLoading(false);
        })
        .finally(function () {
          isMenuLoading(false);
        });
      isLoading(true);
    }
  }, []);

  const getUserData = (national_code) => {
    return props.store.dispatch(CustomerAction.getCustomer(national_code));
  };
  const exit = (socket, idMenu, idOperator) => {
    socketIo.emit("exit", socket, idMenu, idOperator);
  };

  // useEffect(() => {
  //     //
  //     //
  // }, [qsAc,qsOp])

  const connection = () => {
    let socket = io.connect(Url);
    setSocketIO(socket);
    socket.on("connect", function () {
      socket.emit("joinAdmin");
    });
    socket.on("online_users", function (users) {
      setQs({});

      let items = [];
      if (users) {
        let users_items = users.filter((e) => {
          if (e) {
            if (!items[e.menu]) items[e.menu] = [];

            items[e.menu].push(e);
          }

          // return e && e
        });

        setOnlineUsers(users_items);
        setQs(items);
      }
    });
    socket.on("online_operators", function (users) {
      //
      setQsOpr({});
      let items = [];

      //
      if (users) {
        let users_items = users.filter((e) => {
          if (e) {
            e.menu.map((i) => {
              if (!items[i]) items[i] = [];
              items[i].push(e);
            });
          }
        });

        setQsOpr(items);
        setOnlineOperator(users_items);
      }
    });

    socket.on("active_chats", function (users) {
      setQsAc({});

      let items = [];
      if (users) {
        let users_items = users.filter((e) => {
          if (e) {
            if (!items[e.user.menu]) items[e.user.menu] = [];

            items[e.user.menu].push(e);
          }
        });

        setactive_chats(users_items);
        setQsAc(items);
      }
    });
  };
  //
  //
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getCallsData = async () => {
    await axios
      .get(ApiUrl + "/menu-today-call", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setTabData(res.data.data);
      })
      .catch((res) => {
        //
      });
  };
  const getChartData = async () => {
    await axios
      .get(ApiUrl + "/hourly-menu-wating-duration-time", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setChartData(res.data.data[0]);
      })
      .catch((res) => {
        //
      });
  };

  useEffect(() => {
    getCallsData();
    getChartData();
  }, []);
  const [fontSize, setFontSize] = useState(12);
  const queueRef = useRef();

  return (
    <GridContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>داشبورد - {pageTitle}</title>
      </Helmet>
      {alert}
      <Snackbar
        place="tl"
        color="success"
        icon={AddAlert}
        message="پیام با موفقیت ثبت شد"
        open={tl}
        closeNotification={() => setTL(false)}
        close
      />

      <Box width={"100%"} height={"100%"}>
        <TabContext value={tabValue}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <MissedCalls24Hours />
              <SuccessCall24Hours />
            </Box>
            <Box margin={"0 5px"}>
              {data.map((e, i) => {
                return (
                  <TabPanel
                    key={e._id}
                    value={e._id}
                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: "0 !important",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "5px",
                      flexWrap: "wrap",
                    }}
                  >
                    <DurationBarChart data={chartData} menu={e.name} />
                    <WaitingBarChart data={chartData} menu={e.name} />
                  </TabPanel>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={tabHandleChange}
              aria-label="lab API tabs example"
              style={{
                overflow: "auto",
              }}
              TabIndicatorProps={{
                style: { bottom: 0 },
              }}
            >
              {data.map((e, i) => {
                return (
                  <Tab
                    style={{
                      margin: "0 5px",
                      backgroundColor: "var(--darkWhite)",
                      color: "black",
                      borderRadius: "10px",
                    }}
                    label={<AdminTabs title={e.name} data={tabData} />}
                    key={e.name}
                    value={e._id}
                    id={e._id}
                  ></Tab>
                );
              })}
            </TabList>
          </Box>
        </TabContext>
      </Box>

      <GridContainer style={{ margin: "0" }}>
        <GridItem md={12} sm={12}>
          <h4>صف انتظار کاربران</h4>
        </GridItem>
        {data &&
          data.map((e, i) => {
            try {
              return (
                <GridItem
                  key={i}
                  style={{
                    margin: "3px",
                    borderRadius: "5px",
                    overflow: "auto",
                    boxShadow: "0 0 5px rgba(0,0,0,.4)",
                    width: "150px",
                    height: "200px",
                    fontSize: `10px`,
                  }}
                >
                  <div className={"list-container"} dir="rtl" ref={queueRef}>
                    <span>صف {e.name === "کیپاد" ? "پی پاد" : e.name}</span>

                    <ul id={e._id} className={"online-user-list-all"}>
                      {qs[e._id] &&
                        qs[e._id].map((i, index) => {
                          return (
                            <li key={index}>
                              <span className="pulsating-circle"></span>
                              <span>{i.code}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            } catch (error) {
              return (
                <GridItem md={3} sm={12} key={i}>
                  <div className={"list-container"} dir="rtl">
                    <span>صف {e.name}</span>

                    <ul className={"online-user-list-all"}>
                      {qs[e._id] &&
                        qs[e._id].map((i, index) => {
                          return (
                            <li key={index}>
                              <span>خطای سرور</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            }
          })}
      </GridContainer>
      <GridContainer style={{ margin: "0" }}>
        <GridItem md={12} sm={12}>
          <h4>اپراتور های آزاد</h4>
        </GridItem>
        {data &&
          data.map((e, i) => {
            try {
              return (
                <GridItem
                  key={i}
                  style={{
                    margin: "3px",
                    borderRadius: "5px",
                    overflow: "auto",
                    boxShadow: "0 0 5px rgba(0,0,0,.4)",
                    width: "150px",
                    height: "200px",
                    fontSize: "10px",
                  }}
                >
                  <div className={"list-container"} dir="rtl">
                    <span>صف {e.name === "کیپاد" ? "پی پاد" : e.name}</span>

                    <ul id={"operator_" + e._id} className={"online-user-list-all"}>
                      {qsOp[e._id] &&
                        qsOp[e._id].map((i, index) => {
                          return (
                            <li
                              key={index}
                              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                            >
                              <p className="pulsating-circle"></p>
                              <p style={{ marginTop: "5px" }}>{i.first_name + " " + i.last_name}</p>
                              {/*                              <p className="removeo"*/}
                              {/*                                    onClick={(v) => exit(i.socket, e._id, i._id)}>*/}
                              {/*  اخراج از صف*/}
                              {/*</p>*/}
                              <ExitToAppRounded
                                onClick={(v) => exit(i.socket, e._id, i._id)}
                                style={{ fill: "var(--chiliRed)", transform: "rotate(180deg)" }}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            } catch (error) {
              return (
                <GridItem md={3} sm={12} key={i}>
                  <div className={"list-container"} dir="rtl">
                    <span>صف {e.name}</span>

                    <ul id={"operator_" + e._id} className={"online-user-list-all"}>
                      {qsOp[e._id] &&
                        qsOp[e._id].map((i, index) => {
                          return (
                            <li key={index}>
                              <span>خطای سرور</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            }
          })}
      </GridContainer>
      <GridContainer style={{ margin: "0" }}>
        <GridItem md={12} sm={12}>
          <h4>گفت و گو های فعال</h4>
        </GridItem>
        {data &&
          data.map((e, i) => {
            try {
              return (
                <GridItem
                  style={{
                    margin: "3px",
                    borderRadius: "5px",
                    overflow: "auto",
                    boxShadow: "0 0 5px rgba(0,0,0,.4)",
                    width: "150px",
                    height: "200px",
                    fontSize: "10px",
                  }}
                  key={i}
                >
                  <div className={"list-container"} dir="rtl">
                    <span>صف {e.name === "کیپاد" ? "پی پاد" : e.name}</span>

                    <ul id={"qsAc_" + e._id} className={"online-user-list-all"}>
                      {qsAc[e._id] &&
                        qsAc[e._id].map((i, index) => {
                          return (
                            <li key={index}>
                              <span className="pulsating-circle"> </span>

                              {i.user && [
                                <>
                                  <span> اپراتور: {i.operator.first_name + " " + i.operator.last_name} </span>,
                                  <br />,<span> عضو: {i.user.code} </span>,
                                  <span className="removeo">
                                    <a href={"/admin/check-session/" + i.call_history} rel="noopener noreferrer">
                                      مشاهده
                                    </a>
                                  </span>
                                  ,
                                </>,
                              ]}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            } catch (error) {
              return (
                <GridItem md={3} sm={12} key={i}>
                  <div className={"list-container"} dir="rtl">
                    <span>صف {e.name}</span>

                    <ul id={"qsAc_" + e._id} className={"online-user-list-all"}>
                      {qsAc[e._id] &&
                        qsAc[e._id].map((i, index) => {
                          return (
                            <li key={index}>
                              <span>خطای سرور</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </GridItem>
              );
            }
          })}
      </GridContainer>
    </GridContainer>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
  customer_loading: state.customer.loading,
  message: state.customer.message,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
