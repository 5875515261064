import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import styles from "../../style/Recharts/ReportsCallActivity.module.scss";
import { BsSortDown } from "react-icons/bs";
import ButtonXlsx from "components/Recharts/ButtonChartXls/ButtonXlsx";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Loading from "components/Loading";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { ApiUrl } from "../../config";

const token = localStorage.getItem("token");

function ReportsCallActivity(props) {
  const [min, setMin] = useState(10);
  const [chartData, setChartData] = useState([]);
  const [apiRequest, setApiRequest] = useState("reports-mid-calls-activity");
  const [loading, setLoading] = useState(true);
  const [tickDate, setTickDate] = useState({});
  const filterOptions = [
    { value: "0", label: "زمان" },
    { value: "1", label: "اپراتور" },
  ];
  const minOption = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ];

  const data = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${ApiUrl}/${apiRequest}/${min}`, {
        headers: {
          Authorization: token,
        },
      });
      convertData(res.data.data);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    data();
  }, [min]);
  const convertMinsToHrsMins = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  const convertData = (data) => {
    let arr = [];
    let dateTemp = {
      data: [],
    };
    data.forEach((item, index) => {
      if (!item._id) {
        return index++;
      }
      item.data.forEach((item2) => {
        dateTemp.data.push({
          startDay: new Date(item2.startDay).toLocaleDateString("fa-IR", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }),
        });
      });
      //   dateArr.push(obj);
    });
    dateTemp.data.sort((a, b) => {
      if (a.startDay < b.startDay) {
        return -1;
      }
      if (a.startDay > b.startDay) {
        return 1;
      }
      return 0;
    });
    setTickDate(dateTemp);
    data.forEach((item, index) => {
      if (!item._id) {
        return index++;
      }
      let obj = {
        name: item._id.trim(),
        data: item.data,
      };
      arr.push(obj);
    });
    arr.forEach((item) => {
      item.data.forEach((item2) => {
        item2.time = Math.abs(Math.round(item2.time));
        item2.startDay = new Date(item2.startDay).toLocaleDateString("fa-IR", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
        });
      });
    });
    arr.forEach((item) => {
      return item.data.sort((a, b) => {
        if (a.startDay < b.startDay) {
          return -1;
        }
        if (a.startDay > b.startDay) {
          return 1;
        }
        return 0;
      });
    });

    // arr.sort((a, b) => {
    //     if (a._id < b._id) {
    //         return -1;
    //     }
    //     if (a._id > b._id) {
    //         return 1
    //     }
    //     return 0
    //
    // })
    setChartData(arr);
    setLoading(false);
  };
  const selectMinHandler = (e) => {
    setMin(e.value);
  };
  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customLabel}>
          <h4>{`تاریخ : ${label}`}</h4>
          {payload.map((item, index) => {
            return (
              <h5 key={index} style={{ color: `${item.fill}` }}>{` اپراتور : ${item.name} , تعداد : ${
                item.payload.count
              } , زمان :  ${convertMinsToHrsMins(item.payload.time)}`}</h5>
            );
          })}
        </div>
      );
    }
    return <></>;
  };
  const LineMapData = () => {
    return chartData.map((item, index) => {
      let arrayOfColors = ["1", "1", "2", "3", "4", "5", "6", "7", "8", "9", "b", "b", "c", "d", "e", "f"];
      let color = "#";
      for (let i = 0; i < 6; i++) {
        let index = Math.floor(Math.random() * 16);
        let value = arrayOfColors[index];
        color += value;
      }
      return (
        <Line
          key={index}
          type="monotone"
          name={item.name}
          data={item.data}
          dataKey={`time`}
          stroke={color}
          fill={color}
          dot={{ strokeWidth: 2, r: 4 }}
        />
      );
    });
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 200,
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <Card>
      <CardHeader color="info">
        <h4>میزان کارکرد اپراتور ها</h4>
      </CardHeader>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.filters}>
            <div className={styles.filterWrapper}>
              <div className={styles.selectContainer}>
                <Select
                  styles={customStyles}
                  defaultValue={min}
                  options={minOption}
                  onChange={selectMinHandler}
                  placeholder={"میزان زمان گذشته"}
                  className={styles.SelectBox}
                />
              </div>
              <ButtonXlsx apiRequest={apiRequest} days={min} />
            </div>
          </div>
          <div className={styles.chart}>
            <ResponsiveContainer className={styles.rechartResponsiveContainer}>
              {!loading ? (
                <LineChart
                  width={300}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey={"startDay"} orientation="bottom" allowDuplicatedCategory={false} type={"category"} />
                  <YAxis dataKey={"time"} orientation="left" domain={[0, "auto"]} />
                  <Tooltip cursor={{ stroke: "grey", strokeWidth: 1 }} content={<LabelCustom />} />
                  <Line data={tickDate.data} />
                  {LineMapData()}
                </LineChart>
              ) : (
                <Loading loading={loading} />
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ReportsCallActivity;
