import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/login/logo.png";
import ChatBotAvandIframe from "../../views/Pages/ChatBotAvand-iframe";
import VideoBoxCalling from "../../components/VideoBoxCalling/VideoBoxCalling";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 999,
    margin: "25px",
  },
  chatContainer: (props) => ({
    borderRadius: "10px",
  }),
  logoContainer: {
    borderRadius: "50%",
    padding: "5px",
    backgroundColor: "white",
    margin: "0 5px",
    cursor: "pointer",
    boxShadow: "0 0 5px rgba(0,0,0,.5)",
    transition: ".2s",
    "&:active": {
      transform: "scale(80%)",
    },
  },
  logo: {
    width: "80px",
    height: "80px",
    objectFit: "cover",
  },
  iframe: {
    width: "390px",
    height: "600px",
    borderRadius: "10px",
    boxShadow: "0 0 5px rgba(0,0,0,0.5)",
  },
});

function ChatBotAvandBubble(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open: open });

  window.addEventListener("click", () => {
    setOpen(false);
  });
  const openChat = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };
  return (
    <div className={classes.container}>
      {open && (
        <div className={classes.chatContainer}>
          <div style={{ width: "100%", height: "100%" }}>
            <iframe
              id="iframeId"
              allowTransparency="true"
              frameBorder="0"
              src="http://localhost:3000/client-bo/direction-avand/09121234567/1272705676"
              allow="geolocation; microphone; camera"
              className={classes.iframe}
            ></iframe>
          </div>
          {/*<VideoBoxCalling/>*/}
        </div>
      )}
      <div className={classes.logoContainer} onClick={openChat}>
        <img src={logo} alt="لوگو" className={classes.logo} />
      </div>
    </div>
  );
}

export default ChatBotAvandBubble;
