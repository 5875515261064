import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { grayColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "100%",
    direction: "rtl",
  },
  title: {
    fontSize: 12,
    color: grayColor[0],
  },
});
const CustomCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <h6 className={classes.title} color="textSecondary" gutterBottom>
          {props.title}
        </h6>
        {props.type == "onlineClients" ? (
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Hidden only={["lg", "xl", "xs"]}>
                <div style={{ textAlign: "center", fontSize: "10px" }}>شماره موبایل</div>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <div style={{ textAlign: "center" }}>شماره موبایل</div>
              </Hidden>
            </Grid>
            <Grid item xs={4}>
              <Hidden only={["lg", "xl", "xs"]}>
                <div style={{ textAlign: "center", fontSize: "10px" }}>نوع تماس</div>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <div style={{ textAlign: "center" }}>نوع تماس</div>
              </Hidden>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Hidden only={["lg", "xl", "xs"]}>
                <div style={{ textAlign: "center", fontSize: "10px" }}>نام</div>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <div style={{ textAlign: "center" }}>نام</div>
              </Hidden>
            </Grid>
            <Grid item xs={7}>
              <Hidden only={["lg", "xl", "xs"]}>
                <div style={{ textAlign: "center", fontSize: "10px" }}>صف</div>
              </Hidden>

              <Hidden only={["sm", "md"]}>
                <div style={{ textAlign: "center" }}>صف</div>
              </Hidden>
            </Grid>
            {/* <Grid item xs={1} >
                          
                        </Grid> */}
          </Grid>
        )}

        {props.children}
      </CardContent>
    </Card>
  );
};
export default CustomCard;
