import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { ApiUrl, Url } from "../../config";
import { toast } from "react-toastify";
import { Add, ImageTwoTone } from "@material-ui/icons";

function AddGalleryNews(props) {
  const id = props.match.params.id;
  const [gallery, setGallery] = useState([]);
  const [data, setData] = useState([]);
  const addGallery = async () => {
    let fd = new FormData();
    for (let i = 0; i < gallery.length; i++) {
      fd.append("gallery", gallery[i]);
    }
    await axios
      .patch(ApiUrl + "/add-gallery-news/" + id, fd, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success("با موفقیت ثبت شد");
        getNews();
      })
      .catch((res) => {
        toast.error("خطا در آپلود");
      });
  };
  const deleteImage = async (name) => {
    const based = btoa(name);
    await axios
      .delete(ApiUrl + "/delete-gallery-news/" + id + "/" + name, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        toast.success("با موفقیت حذف شد");
        getNews();
      })
      .catch((res) => {
        toast.error("خطا در حذف");
      });
  };
  const getNews = async () => {
    await axios
      .get(ApiUrl + "/get-blog/" + id, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.gallery && setData(res.data.data.gallery);
      });
  };
  useEffect(() => {
    getNews();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",

          gap: "10px",
        }}
      >
        <Button
          variant={"text"}
          component={"label"}
          style={{
            width: "150px",
            height: "100%",
            minWidth: "100px",
            backgroundColor: "var(--primary)",
            color: "var(--white)",
          }}
          startIcon={<Add style={{ margin: "0 10px" }} />}
        >
          <input
            type="file"
            multiple
            accept=".jpg,.jpeg,.png"
            hidden
            onChange={(e) => {
              setGallery(e.target.files);
            }}
          />
          {gallery.length > 0 ? " تعداد عکس ها : " + gallery.length : "افزودن عکس"}
        </Button>
        {gallery.length > 0 && (
          <Button
            variant={"contained"}
            style={{
              width: "150px",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
            startIcon={<ImageTwoTone style={{ margin: "0 10px" }} />}
            onClick={addGallery}
          >
            آپلود عکس ها
          </Button>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          flexWrap: "wrap",
          display: "flex",
          gap: "10px",
        }}
      >
        {data.length > 0 &&
          data.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: "300px",
                height: "150px",
                display: "flex",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "0 0 4px rgba(0,0,0,.2)",
                transition: ".5s",
                position: "relative",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "var(--primarySky)",
                  top: "0",
                  color: "var(--white)",
                  bottom: "0",
                  marginTop: "auto",
                  marginBottom: "auto",
                  height: "100%",
                  "&:hover": {
                    color: "var(--black)",
                    backgroundColor: "var(--primarySky)",
                  },
                }}
                onClick={(e) => deleteImage(btoa(item))}
              >
                حذف
              </Button>
              <img
                src={Url + item}
                alt=""
                style={{ width: "100%", height: "150px", objectFit: "contain", padding: "5px" }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default AddGalleryNews;
