import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl, Url } from "../../config";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import { Box, Button, Typography } from "@mui/material";
import Loading from "components/Loading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    width: "150px",
    height: "200px",
    padding: "20px",
    background: "var(--gray)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "5px",
    position: "relative",
  },
  image: {
    objectFit: (props) => "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    transition: ".5s",
    filter: "brightness(45%)",
    "&:hover": {
      transform: "scale(105%)",
      transition: ".5s",
    },
  },
});

function CardViewProduct({ img = "", name = "", text = "", data = {}, api, setReload }) {
  const classes = useStyles();
  const [queue, setQueue] = useState({});
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewIcon, setPreviewIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState({
    name: "",
    text: "",
    image: "",
    icon: "",
  });
  const updateHandler = async () => {
    // setLoading(true);
    // console.log("clicked");
    // const formData = new FormData();
    // selectedFile && formData.append("image", edit.image);
    // formData.append("name", edit.name);
    // formData.append("text", edit.text);
    // selectedIcon && formData.append("icon", edit.icon);
    // await axios
    //   .patch(ApiUrl + `${api}/${data._id}`, formData, {
    //     headers: {
    //       Authorization: localStorage.getItem("token"),
    //       "Content-Type": "multipart/form-data"
    //     }
    //   })
    //   .then((res) => {
    //     toast.success("با موفقیت ویرایش شد");
    //     setReload(true);
    //   })
    //   .catch((res) => {
    //     toast.error("خطا در ویرایش");
    //   })
    //   .finally(() => setLoading(false));
    if (selectedFile || selectedIcon) {
      const formData = new FormData();
      selectedFile && formData.append("image", edit.image);
      selectedIcon && formData.append("icon", edit.icon);
      await axios
        .patch(ApiUrl + `${api}/${data._id}`, formData, { headers: { Authorization: localStorage.getItem("token") } })
        .then((res) => {
          toast.success("با موفقیت ویرایش شد");
          setReload(true);
        })
        .catch((res) => {
          toast.error("خطا در ویرایش");
        })
        .finally(() => setLoading(false));
    } else {
      await axios
        .patch(ApiUrl + `${api}/${data._id}`, edit, { headers: { Authorization: localStorage.getItem("token") } })
        .then((res) => {
          toast.success("با موفقیت ویرایش شد");
          setReload(true);
        })
        .catch((res) => {
          toast.error("خطا در ویرایش");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setEdit({ ...data });
  }, []);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    console.log(selectedFile);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  useEffect(() => {
    if (!selectedIcon) {
      setPreviewIcon(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedIcon);
    setPreviewIcon(objectUrl);
    console.log(selectedIcon);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedIcon]);
  const customStyles = {
    valueContainer: () => ({
      padding: 10,
      display: "flex",
      alignItems: "center",
    }),
    control: (base) => ({
      ...base,
      height: "100%",
      width: "100%",
      border: "1px solid black",
    }),
    menu: (base) => ({
      ...base,
      position: "relative",
    }),
  };
  useEffect(() => {
    setEdit({
      ...data,
    });
  }, []);

  const confirm = async () => {
    updateHandler();
    setReload(true);
    setOpen(false);
  };
  const editItem = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, [name]: value });
  };
  const deleteHandler = async () => {
    setLoading(true);
    setOpenDelete(false);
    setReload(true);
    await axios
      .delete(ApiUrl + `/delete-product-slide/${data._id}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        toast.success("با موفقیت حذف شد");
        setReload(true);
      })
      .catch((res) => {
        toast.error("خطا در انجام عملیات");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showEditInput = () => {
    return (
      <SweetAlert
        style={{ display: "block" }}
        onConfirm={confirm}
        confirmBtnText="تایید"
        cancelBtnText="انصراف"
        showCancel={true}
        onCancel={() => setOpen(false)}
      >
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"5px"} sx={{ direction: "rtl" }}>
          <textarea
            name="name"
            value={edit.name}
            placeholder="عنوان"
            onChange={editItem}
            style={{
              padding: "10px",
              outline: "none",
              resize: "none",
              borderRadius: "4px",
              fontSize: "12px",
            }}
          />

          <textarea
            name="text"
            value={edit.text}
            placeholder="محتوا"
            onChange={editItem}
            style={{
              padding: "10px",
              outline: "none",
              resize: "none",
              borderRadius: "4px",
              fontSize: "12px",
            }}
          />

          {preview ? (
            <img
              style={{ maxHeight: "150px", objectFit: "none", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={preview}
              alt=""
            />
          ) : (
            <img
              style={{ maxHeight: "150px", objectFit: "contain", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={Url + edit.image}
              alt=""
            />
          )}
          {previewIcon ? (
            <img
              style={{ maxHeight: "100px", objectFit: "contain", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={previewIcon}
              alt=""
            />
          ) : (
            <img
              style={{ maxHeight: "100px", objectFit: "contain", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={Url + edit.icon}
              alt=""
            />
          )}
          <Button
            variant={"text"}
            component={"label"}
            style={{
              width: "100%",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
              hidden
              onChange={(e) => {
                setEdit({ ...edit, image: e.target.files[0] });
                setSelectedFile(e.target.files[0]);
              }}
            />
            تغییر عکس
          </Button>
          <Button
            variant={"text"}
            component={"label"}
            style={{
              width: "100%",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              hidden
              onChange={(e) => {
                setEdit({ ...edit, icon: e.target.files[0] });
                setSelectedIcon(e.target.files[0]);
              }}
            />
            تغییر آیکون
          </Button>
        </Box>
      </SweetAlert>
    );
  };
  const deleteAlert = () => {
    return (
      <SweetAlert
        style={{ display: "block" }}
        onConfirm={() => deleteHandler()}
        confirmBtnText="تایید"
        cancelBtnText="انصراف"
        showCancel={true}
        onCancel={() => setOpenDelete(false)}
      />
    );
  };
  return (
    <div className={classes.container}>
      {open && showEditInput()}
      {openDelete && deleteAlert()}
      <img className={classes.image} src={Url + img} alt={name} />
      <Box width={"100%"} overflow={"hidden"} zIndex={999}>
        <Typography fontWeight={400} fontSize={12} color={"var(--white)"} textAlign={"right"}>
          {edit.text}
        </Typography>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        gap={"10px"}
        alignItems={"center"}
        position={"absolute"}
        bottom={5}
      >
        <Button
          sx={{
            backgroundColor: "var(--chiliRed)",
            color: "var(--white)",
            fontSize: "12px",
            border: "1px solid var(--gray)",
          }}
          onClick={(e) => setOpenDelete(true)}
        >
          حذف
        </Button>
        <Button
          sx={{
            backgroundColor: "var(--primary)",
            color: "var(--white)",
            fontSize: "12px",
            border: "1px solid var(--gray)",
          }}
          onClick={(e) => setOpen(true)}
        >
          ویرایش
        </Button>
      </Box>
      {loading && (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
          left={0}
          right={0}
          marginTop={"auto"}
          marginBottom={"auto"}
        >
          <Loading showText={false} />
        </Box>
      )}
    </div>
  );
}

export default CardViewProduct;
