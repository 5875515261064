import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl } from "config";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import * as err from "antd";
import { Delete, DeleteOutlined } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
  hover: {
    cursor: "pointer",
    transition: ".5s",
    "&:hover": {
      transition: ".5s",
      transform: "scale(110%)",
    },
  },
});

function FormUpdate(props) {
  const [data, setData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [deleteForm, setDeleteForm] = useState(false);
  const history = useHistory();
  const getMenu = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.forEach(async (element, index) => {
          await axios
            .get(ApiUrl + "/get-service-models-by-menu/" + element._id, {
              headers: { Authorization: localStorage.getItem("token") },
            })
            .then((models) => {
              if (models.data.data.histories.length <= 0) {
                return index++;
              }
              let obj = {
                menu: element._id,
                menuName: element.name,
                forms: models.data.data.histories.filter((x) => x.enabled === true),
              };
              setData((prev) => [...prev, obj]);
            });
        });
        setMenu(res.data.data);
      })
      .catch(() => {
        toast.error("خطا در دریافت صف ها");
      });
  };
  const getData = async () => {
    try {
      const res = await axios.get(ApiUrl + "/get-service-models", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setData(res.data.data.histories);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteModel = async (id) => {
    await axios
      .delete(ApiUrl + "/service-model/" + id, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        toast.success("با موفقیت حذف شد");
        history.go(0);
      })
      .catch((res) => {
        toast.error("خطا در حذف");
      });
  };
  useEffect(() => {
    getMenu();
  }, [deleteForm]);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Box flexDirection={"column"} display={"flex"} gap={"16px"}>
        {data.map((item, index) => {
          if (item.forms.length === 0) {
            index++;
          } else
            return (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={"bold"} fontSize={"1rem"}>
                  {item.menuName}
                </Typography>
                {item.forms.map((item) => {
                  return (
                    <Box
                      key={item._id}
                      className={classes.hover}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "var(--darkWhite)",
                        padding: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 0 4px rgba(0,0,0,.1)",
                        gap: "10px",
                      }}
                    >
                      <Typography>
                        <Link to={"/admin/update-form/" + item._id}>{item.name}</Link>
                      </Typography>
                      <Tooltip title={"حذف فرم"}>
                        <DeleteOutlined
                          style={{ fill: "var(--chiliRed)" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteModel(item._id);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            );
        })}
      </Box>
    </div>
  );
}

export default FormUpdate;
