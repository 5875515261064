/* eslint-disable no-console */
/* eslint-disable no-redeclare */
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddAlert from "@material-ui/icons/AddAlert";
import Assignment from "@material-ui/icons/Assignment";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import ClipboardJS from "clipboard";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Detail from "components/Detail/Detail";
import Operator from "components/Operator/Operator";
import Snackbar from "components/Snackbar/Snackbar";
import $ from "jquery";
import { LinkMessage, Message, MessageList, Socketid } from "layouts/Recoil";
import { Device } from "mediasoup-client";
import Push from "push.js";
import React, { Fragment, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet } from "react-helmet";
// import connect from "react-redux/es/connect/connect";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { bindActionCreators } from "redux";
import io from "socket.io-client";
import MessageIcon from "../../../assets/img/ic_message_black_18dp.png";
import rin from "../../../assets/ring.mp3";
import notification from "../../../assets/sounds/notification.mp3";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { Btn } from "../../../components/share/Btn/Btn";
import { ApiUrl, sendRequest, Url } from "../../../config";
import CustomerAction from "../../../js/actions/customer_action";
import imgMess from "../../../views/Client/files/call_message.gif";
import imgcall from "../../../views/Client/files/waiting.gif";
import Off from "../../../views/Dashboard/files/off.png";
import on from "../../../views/Dashboard/files/on.png";
import standby from "../../../views/Dashboard/files/standby.png";
import btnStyles from "./CircularButton.module.scss";
import { Box } from "@mui/material";
import AddNotes from "../../../components/AddNotes/AddNotes";
import VideoBoxCallingDesktop from "../../../components/VideoBoxCallingDesktop/VideoBoxCallingDesktop";
import {
  createCollectionIndexed,
  clearTransaction,
  createTransaction,
  deleteIndexedDb,
  readDataIndexedDb,
  updateIndexedDb,
} from "indexedDB/adminDB";
import { pageTitle } from "web-config";
require("../../../views/Dashboard/style.css");

const useStyles = makeStyles(styles);
let local_media_stream = null;
Array.prototype.contains = function (_id) {
  var i = this.length;
  while (i--) {
    if (this[i]._id === _id) {
      return true;
    }
  }
  return false;
};

function CircularButton(props) {
  const classes = useStyles();
  const [audio, setAudio] = useState(new Audio(rin));
  const [blobUrl, setBlobUrl] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [flagReason, setFlagReason] = useState(false);
  const [voice, setVoice] = useState(false);
  //Get audio from server and play for ringtone
  const ringtoneHandler = async () => {
    try {
      const url = Url + localStorage.getItem("ringtone").replaceAll(" ", "%20");
      const res = await axios.get(url, { responseType: "arraybuffer", headers: { "Content-Type": "audio/mp3" } });
      const blob = new Blob([res.data], { type: "audio/mp3" });
      const blobUrl = window.URL.createObjectURL(blob);
      setBlobUrl(blobUrl);
      setAudio(new Audio(blobUrl));
    } catch (e) {
      let m = 0;
    }
  };
  useEffect(() => {
    ringtoneHandler();
  }, []);

  useEffect(() => {
    audio.loop = true;
    playing ? audio.play() : audio.pause();
  }, [playing]);
  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  //////
  const [reasonsList, setReasonsList] = useState([
    { name: "پیگیری", id: 1 },
    { name: "امور محوله", id: 2 },
    { name: "آموزش", id: 3 },
    { name: "پایان شیفت", id: 4 },
  ]);
  let vState = [false, false, false, false];
  /////

  const [isFree, setIsFree] = useState(false);
  const [data, setData] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [flagSend, setFlagSend] = useState("");
  const [uploadEvent, setUploadEvent] = useState("");
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isChat, setIsChat] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [status, setStatus] = useState("off");
  const [chooseMenu, setChooseMenu] = useState(false);
  const [socketIo, setSocket] = useState(null);
  const [userNationalCode, setUserNationalCode] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [isEnded, setEnded] = useState(false);
  const [id, setId] = useState(0);
  const [alerts, setAlert] = React.useState(null);
  const [tl, setTL] = React.useState(false);
  const [streamLocal, setStream] = React.useState(null);
  const [is_hold, setIsHold] = React.useState(false);
  const [roomId, setRoomId] = React.useState(false);
  const [onlineOperator, setOnlineOperator] = useState([]);
  const [qsOp, setQsOpr] = useState({});
  const [message, setMessageForm] = useRecoilState(Message);
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [isTyping, setIsTyping] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [otherTyping, setOtherTyping] = useState(false);
  //new state
  const [tracks, setTracks] = useState(null);
  const [editMessage, setEditMessage] = useState("");
  const [editMessageInput, setEditMessageInput] = useState({
    message: "",
    msg_id: "",
    is_delete: false,
  });
  const [operator, setOperator] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const [chatToVideo, setChatToVideo] = useState(false);
  const [localMedia, setLocalMedia] = useState(null);

  //indexedDb

  //

  useEffect(() => {
    readDataIndexedDb(setMessagList, userNationalCode);
  }, []);
  useEffect(() => {
    socketIo && socketIo.emit("is_typing", isTyping);
  }, [isTyping]);

  useEffect(() => {
    socketIo &&
      socketIo.on("other_typing", (otherTyping) => {
        setOtherTyping(!!otherTyping);
      });
  }, [socketIo]);
  useEffect(() => {
    // console.log("tracks", tracks);
  }, [tracks]);
  const onTypingMessage = () => {
    //
    if (timeoutId !== null) clearTimeout(timeoutId);
    if (!isTyping) setIsTyping(true);

    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTimeoutId(timeout);
  };

  const ICE_SERVERS = [
    {
      urls: "turn:185.60.32.129:3478",
      credential: "HmCht_3432",
      username: "hamichat",
    },
  ];
  let local_screen_stream = null;
  let peerConnection = null;
  let sharePeerConnection = null;

  let device = null;
  let deviceScreen = null;
  //
  useEffect(() => {
    if (isFree && !isChat) {
      // let self = document.getElementById("selfoperator");

      let stream = local_media_stream;
      // self.srcObject = stream;
      // self.setAttribute("autoplay", "autoplay");
      // self.setAttribute("playsinline", "playsinline");
    }
    setMessagList([]);
    setSocktid([]);
  }, [isFree]);

  let producerTransport = null;
  let producerTransportDisplay = null;
  let videoProducer = null;
  let audioProducer = null;
  let consumerTransport = null;
  let videoConsumers = {};
  let audioConsumers = {};
  let clientId = null;

  const [onlineUsers, setOnlineUsers] = useState([]);
  if (props.message == 100) {
    if (!tl) {
      setTL(true);
      setTimeout(function () {
        setTL(false);
      }, 6000);
    }
  }

  function removeRemoteVideo(id) {
    $("#remote").remove();
    $("#audio_remote").remove();
  }

  useEffect(() => {
    axios
      .get(ApiUrl + "/menu-items?status=true", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        //
        setDataItems(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});

    axios({
      method: "get",
      url: ApiUrl + "/get-user/" + localStorage.getItem("_id"),
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        setOperator(response.data.data.user);
        //
        let all_menus = response.data.data.user.expertList;
        //
        setMenus(all_menus);
        let v = [];
        all_menus.forEach((item) => {
          v.push(false);
        });
        setSelectedMenu(v);
        //no menu selected
      })
      .catch(function (error) {})
      .finally(function () {});
    setMessagList([]);
    setSocktid([]);
  }, [null]);
  useEffect(() => {
    if (local_media_stream)
      local_media_stream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
  }, [is_hold]);
  const init = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        local_media_stream = stream;
        setLocalMedia(stream);
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به دوربین مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };
  const init_voice = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        local_media_stream = stream;
        setLocalMedia(stream);
      })
      .catch((e) => {
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="سیستم شما می بایست به میکروفن مجهز باشد"
            onConfirm={() => confirm()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      });
  };
  useEffect(() => {}, []);
  const confirm = () => {
    window.location.href = "/admin";
  };

  const getUserData = (national_code) => {
    return props.store.dispatch(CustomerAction.getCustomer(national_code));
  };

  async function loadDevice(routerRtpCapabilities) {
    try {
      device = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        // console.error("browser not supported");
      }
    }
    await device.load({ routerRtpCapabilities });
  }

  async function loadDeviceScreen(routerRtpCapabilities) {
    try {
      deviceScreen = new Device();
    } catch (error) {
      if (error.name === "UnsupportedError") {
        // console.error("browser not supported");
      }
    }
    await deviceScreen.load({ routerRtpCapabilities });
  }

  const connection = () => {
    let socket = io.connect(Url, {
      extraHeaders: {
        "x-auth-token": localStorage.getItem("wstoken"),
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            "x-auth-token": localStorage.getItem("wstoken"),
          },
        },
      },
    });
    setSocket(socket);

    socket.on("connect", function () {
      let menuList = [];
      menus.forEach((item, index) => {
        if (selectedMenu[index] == true) {
          menuList.push(item._id);
        }
      });
      console.log("🚀 ~ connection 352 ...... ~ socket id :", socket.id);

      //
      setSocket(socket);
      socket.emit("join", {
        type: "operator",
        _id: localStorage.getItem("_id"),
        menuList: menuList,
      });
    });

    socket.on("disconnect", function () {
      // clearTransaction();
      setIsFree(false);
      localStorage.setItem("status", "off");
      socketIo &&
        socketIo.emit("exit_operator", {
          id: localStorage.getItem("_id"),
          reason: "بدون دلیل",
          // menuList: menuList,
        });
      socketIo && socketIo.disconnect();
      props.setOperatorStatus("off");
    });

    socket.on("checkAlive", () => {
      socket.emit("stillAlive");
    });

    socket.on("online_users", function (users) {
      let list = document.getElementById("OnlineUsers");
      if (users) {
        let users_items = users.filter((e) => {
          return e && e;
        });

        setOnlineUsers(users_items);
      }
    });
    socket.on("update_menu", function (menusId) {
      let v = [];
      menus.forEach((item, index) => {
        if (menusId.includes(item._id)) {
          v.push(true);
        } else {
          v.push(false);
        }
      });
      setSelectedMenu(v);
    });

    async function subscribe(socketIo) {
      if (!consumerTransport) {
        const params = await sendRequest("createConsumerTransport", {}, socketIo);
        consumerTransport = await device.createRecvTransport(params);
        consumerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
          sendRequest("connectConsumerTransport", { dtlsParameters: dtlsParameters }, socketIo)
            .then(callback)
            .catch(errback);
        });

        consumerTransport.on("connectionstatechange", (state) => {
          switch (state) {
            case "connecting":
              break;

            case "connected":
              // consumeCurrentProducers(clientId, socketIo);
              break;

            case "failed":
              producerTransport.close();
              break;

            default:
              break;
          }
        });
        consumeCurrentProducers(0, socketIo);
      }

      // --- join & start publish --
    }

    async function consumeCurrentProducers(clientId, socketIo) {
      const remoteInfo = await sendRequest("getCurrentProducers", { localId: socketIo.id }, socketIo).catch((err) => {
        return;
      });

      consumeAll(consumerTransport, remoteInfo.remoteVideoIds, remoteInfo.remoteAudioIds, socketIo);
    }

    socket.on("newProducer", async function (message) {
      // const remoteId = message.socketId;
      // const prdId = message.producerId;
      // const kind = message.kind;
      // const name = message.name;
      // const type = message.type;
      // const size = message.size;
      // // await subscribe(socket)
      //
      // //
      //
      // if (type !== "screen") {
      //   if (kind === "video") {
      //     //
      //    await consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
      //   } else if (kind === "audio") {
      //   await  consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
      //   }
      // }

      if (device) {
        const remoteId = message.socketId;
        const prdId = message.producerId;
        const kind = message.kind;
        const name = message.name;
        const type = message.type;
        const size = message.size;

        if (!consumerTransport) {
          console.error("Failed to create transport");
          return;
        }

        if (kind === "video") {
          await consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        } else if (kind === "audio") {
          console.warn("-- audio NOT SUPPORTED YET. skip remoteId=" + remoteId + ", prdId=" + prdId + ", kind=" + kind);

          await consumeAdd(consumerTransport, remoteId, prdId, kind, socket, name, type);
        }
      }
    });
    socket.on("online_operators", function (users) {
      setQsOpr({});
      let items = [];

      //
      if (users) {
        let users_items = users.filter((e) => {
          if (e) {
            e.menu.map((i) => {
              if (!items[i]) items[i] = [];
              items[i].push(e);
            });
          }
        });

        setQsOpr(items);
      }
    });
    socket.on("producerClosed", function (message) {
      const localId = message.localId;
      const size = message.size;
      const remoteId = message.remoteId;
      const kind = message.kind;
      const type = message.type;

      removeConsumer(remoteId, kind);
      // removeRemoteVideo(remoteId);
    });

    function removeConsumer(id, kind) {
      if (kind === "video") {
        delete videoConsumers[id];
        //
      } else if (kind === "audio") {
        delete audioConsumers[id];
        //
      } else {
        console.warn("UNKNOWN consumer kind=" + kind);
      }
    }

    function consumeAll(transport, remoteVideoIds, remotAudioIds, socket) {
      remoteVideoIds.forEach(async (rId) => {
        await consumeAdd(transport, rId.id, null, "video", socket, rId.name, rId.type);
      });
      remotAudioIds.forEach(async (rId) => {
        await consumeAdd(transport, rId.id, null, "audio", socket, rId.name, rId.type);
      });
    }

    async function consumeAdd(transport, remoteSocketId, prdId, trackKind, socket, name = "", type = "student") {
      let codecOptions = {};
      let consumer = null;
      const { rtpCapabilities } = device;

      console.log(`${socket} ,${transport}, ${remoteSocketId}, ${prdId}, ${trackKind}, ${socket}, ${name}, ${type}`);

      console.log(transport);

      if (!transport) {
        console.error("Transport is null or undefined");
        return;
      }

      try {
        const res = await sendRequest(
          "consumeAdd",
          {
            rtpCapabilities: rtpCapabilities,
            remoteId: remoteSocketId,
            kind: trackKind,
          },
          socket
        );
        console.log(" res   >>");
        console.log(res);

        const { producerId, id, kind, rtpParameters } = res;
        if (prdId && prdId !== producerId) {
          console.warn("ProducerID NOT MATCH");
        }

        consumer = await transport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
          codecOptions,
        });

        console.log("consumer", kind);
        console.log(consumer);

        setTracks(consumer.track);
        addConsumer(remoteSocketId, consumer, kind);

        const remoteId = remoteSocketId;

        consumer.on("transportclose", () => {
          console.log("transport   close >> ", remoteId, kind);
          consumer.close();
          removeConsumer(remoteId, kind);
        });

        consumer.on("producerclose", () => {
          consumer.close();
          console.log("producerclose     close >> ", remoteId, kind);
          removeConsumer(remoteId, kind);
        });

        consumer.on("trackended", () => {
          // Handle track ended
          console.log("trackended call ");
        });

        if (kind === "video") {
          try {
            console.log("resumeAdd  for video type  >> ", remoteSocketId, kind);
            await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket);
          } catch (err) {
            console.error("Failed to resume video:", err);
          }
        } else if (kind === "audio") {
          try {
            console.log("resumeAdd  for audio type  >> ", remoteSocketId, kind);
            await sendRequest("resumeAdd", { remoteId: remoteSocketId, kind: kind }, socket);
          } catch (err) {
            console.error("Failed to resume video:", err);
          }
        }
      } catch (err) {
        console.error("Error in consumeAdd:", err);
      }

      return consumer;
    }

    function addConsumer(id, consumer, kind) {
      if (kind === "video") {
        console.log(" addConsumer  ");
        videoConsumers[id] = consumer;
        //
      } else if (kind === "audio") {
        audioConsumers[id] = consumer;
        //
      } else {
        console.warn("UNKNOWN consumer kind=" + kind);
      }
    }

    console.log("set id in accept-chat", id);

    const hideAlert = (id) => {
      setAlert(null);
      setPlaying(false);
      socket.emit("rejected", id);
    };

    const hideAlertChat = (id) => {
      setAlert(null);
      setPlaying(false);
      socket.emit("rejected-chat", id);
    };
    socket.on("chatTovoice", (id, room_id, mobile, code, menu, vip = false) => {
      endChat();
      setVoice(true);
      setPlaying(true);
      init_voice();
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title=" درخواست تبدیل تماس به صوتی "
          onConfirm={() => confirmChatToVoice(id, room_id, code)}
          onCancel={() => hideAlert(id)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText=" تبدیل تماس "
          cancelBtnText="رد تماس"
          closeOnClickOutside={false}
          onClickOutside={() => null}
          showCancel
        >
          <span>
            شماره تماس: {mobile}
            <br />
            کد ملی: {code}
            <br /> خدمت: {menu}
            <br />
            {vip ? "مشتری VIP" : ""}
          </span>

          <br />

          <img width={"150"} src={imgcall} />
        </SweetAlert>
      );
    });

    socket.on("chatTovideo", function (id, room_id, mobile, code, menu, vip = false) {
      // eslint-disable-next-line no-console
      console.log("chatTovideo emmited  in way ........");
      console.log(`chatTovideo emmited  id ........${id}, room_id${room_id} ,code ${code}`);
      console.log("client socket id  ........", id);
      console.log("operator socket id   ........", socket.id);
      endChat();
      setVoice(false);
      setPlaying(true);
      init();
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title=" درخواست تبدیل تماس به تصویری "
          onConfirm={() => confirmChatToVideo(id, room_id, code)}
          onCancel={() => hideAlert(id)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText=" تبدیل تماس "
          cancelBtnText="رد تماس"
          closeOnClickOutside={false}
          onClickOutside={() => null}
          showCancel
        >
          <span>
            شماره تماس: {mobile}
            <br />
            کد ملی: {code}
            <br /> خدمت: {menu}
            <br />
            {vip ? "مشتری VIP" : ""}
          </span>

          <br />

          <img width={"150"} src={imgcall} />
        </SweetAlert>
      );
    });
    socket.on("call", function (id, room_id, mobile = "", code = "", menu = "", vip = false) {
      setPlaying(true);
      createCollectionIndexed(code);
      init();
      setVoice(false);
      setAlert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="درخواست تماس جدید"
          onConfirm={() => confirm(id, room_id, code)}
          onCancel={() => hideAlert(id)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="برقراری تماس"
          cancelBtnText="رد تماس"
          closeOnClickOutside={false}
          onClickOutside={() => null}
          showCancel
        >
          <span>
            شماره تماس: {mobile}
            <br />
            کد ملی: {code}
            <br /> خدمت: {menu}
            <br />
            {vip ? "مشتری VIP" : ""}
          </span>

          <br />

          <img width={"150"} src={imgcall} />
        </SweetAlert>
      );
    });

    socket.on("chat", function (id, room_id, mobile = "", code = "", menu = "", vip = false) {
      console.log("chat calling");

      setPlaying(true);
      createCollectionIndexed(code);
      setAlert(
        <SweetAlert
          style={{ display: "block" }}
          title="درخواست تماس متنی جدید"
          onConfirm={() => confirmChat(id)}
          onCancel={() => hideAlertChat(id)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="برقراری تماس"
          cancelBtnText="رد تماس"
          closeOnClickOutside={false}
          onClickOutside={() => null}
          showCancel
        >
          <span>
            شماره تماس: {mobile}
            <br />
            کد ملی: {code}
            <br /> خدمت: {menu}
            <br />
            {vip ? "مشتری VIP" : ""}
          </span>
          <br />
          <img width={"150"} src={imgMess} />
        </SweetAlert>
      );
    });
    socket.on("answer", function (id, description) {
      try {
        peerConnection.setRemoteDescription(description);

        peerConnection.ontrack = function (event) {
          // let self = document.getElementById("client");
          // self.srcObject = event.streams[0];
        };
      } catch (e) {
        //
      }
    });

    socket.on("answer-chat", function (id, national_code, mobile) {
      setUserNationalCode(national_code);
      setUserMobile(mobile);
      // getUserData(national_code);
      console.log("set id in answer-chat ", id);

      setIsChat(true);
      setIsFree(true);

      axios
        .get(ApiUrl + "/get-problem/" + national_code, {
          params: {},
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          setData(response.data.data.problems);
        })
        .catch(function (error) {})
        .finally(function () {});
    });

    socket.on("watcher", async function (id, national_code, mobile) {
      setUserNationalCode(national_code);
      setUserMobile(mobile);
      getUserData(national_code);

      setIsFree(true);

      let self = document.getElementById("self");
      peerConnection = new RTCPeerConnection({ iceServers: ICE_SERVERS });
      let stream = local_media_stream;
      self.srcObject = stream;
      self.setAttribute("autoplay", "autoplay");
      self.setAttribute("playsinline", "playsinline");
      stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
      peerConnection
        .createOffer()
        .then((sdp) => peerConnection.setLocalDescription(sdp))
        .then(function () {
          socket.emit("offer", id, peerConnection.localDescription);
        });
      peerConnection.onicecandidate = function (event) {
        if (event.candidate) {
          socket.emit("candidate", id, event.candidate);
        }
      };
    });

    socket.on("candidate", function (id, candidate) {
      peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket.on("chat_message", function (msg) {
      {
        msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
      }
      let msg_notification = new Audio(notification);
      msg_notification.loop = false;
      if (msg.operator_id == 0) {
        msg_notification.play();
        Push.create(`${msg.national_code}`, {
          body: `${msg.message}`,
          icon: MessageIcon,
          timeout: 4000,
          onClick: function () {
            window.focus();
            this.close();
          },
        });
      }
      createTransaction(msg, userNationalCode);
      readDataIndexedDb(setMessagList, userNationalCode);
      // setMessagList((prev) => [...prev, msg]);
      setLinkMessag((prev) => [...prev, false]);
      // var messages = document.getElementById("messages");
      // messages.innerHTML = messages.innerHTML + message;
      // messages.scrollTop = messages.scrollHeight;
    });
    socket.on("chat_message_file", function (msg) {
      {
        msg.id === socket.id ? setSocktid((prev) => [...prev, true]) : setSocktid((prev) => [...prev, false]);
      }
      const obj = {
        ...msg,
        file: true,
      };

      createTransaction(obj, userNationalCode);
      readDataIndexedDb(setMessagList, userNationalCode);
      // setLinkMessag((prev) => [...prev, true]);

      // var messages = document.getElementById("messages");
      // messages.innerHTML = messages.innerHTML + message;
      // messages.scrollTop = messages.scrollHeight;
    });

    socket.on("end_call", function () {
      // clearTransaction();
      console.warn("in on end call");
      if (producerTransport) producerTransport.close();
      producerTransport = null;
      setDegree(0);

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      removeRemoteVideo(0);

      device = null;
      setIsFree(false);
      setIsChat(false);

      if (tracks) {
        console.log("tracks in end call ", tracks);
      }

      if (local_media_stream || tracks) {
        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        console.log(" beforre tracks del ", tracks);
        local_media_stream = null;
        setTracks(null);
      }
      if (local_screen_stream) {
        const tracks = local_screen_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        setIsShare(false);
      }
    });
    socket.on("disconnect", function () {
      // clearTransaction();
      console.warn("call disconnect ");
      setIsShare(false);
    });

    socket.on("left_user", function () {
      console.warn("call left_user ");
      if (producerTransport) producerTransport.close();
      producerTransport = null;
      setDegree(0);

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;
      device = null;
      setIsFree(false);
      setIsChat(false);
      if (tracks) {
        console.log("tracks", tracks);
      }

      if (local_media_stream || tracks) {
        const tracks = local_media_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });
        console.log(" beforre tracks", tracks);
        local_media_stream = null;
        setTracks(null);
      }
      if (local_screen_stream) {
        const tracks = local_screen_stream.getTracks();

        tracks.forEach(function (track) {
          track.stop();
        });

        setIsShare(false);
      }
      setAlert(null);
      setPlaying(false);
      setIsShare(false);
      socket.emit("cancel_display", id);
      removeRemoteVideo(0);
    });
    socket.on("message_edit", (msg) => {
      if (msg.is_delete) {
        deleteIndexedDb(msg._id, setMessagList, userNationalCode);
      }
      updateIndexedDb(msg, setMessagList, userNationalCode);
    });

    const confirmChat = (id) => {
      readDataIndexedDb(setMessagList);
      setAlert(null);
      setPlaying(false);
      setId(id);
      socket.emit("accept-chat", id);
      setIsHold(false);
      getMostMessage();
      setTimeout(() => {
        sendOperatorInformation(socket);
      }, 1000);
    };

    const confirm = async (id, course_id, code) => {
      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;

      setAlert(null);
      setPlaying(false);
      setId(id);

      //
      const dat = await sendRequest("accept", { id }, socket);
      setTimeout(() => {
        sendOperatorInformation(socket);
      }, 1000);
      setIsFree(true);

      axios
        .get(ApiUrl + "/get-problem/" + code, {
          params: {},
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          setData(response.data.data.problems);
        })
        .catch(function (error) {})
        .finally(function () {});

      const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);

      await loadDevice(data);

      const params = await sendRequest("createProducerTransport", {}, socket);

      //
      producerTransport = device.createSendTransport(params);

      producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
          .then(callback)
          .catch(errback);
      });
      producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { id } = await sendRequest(
            "produce",
            {
              transportId: producerTransport.id,
              kind,
              rtpParameters,
              type: "teacher",
            },
            socket
          );

          callback({ id });
        } catch (err) {
          errback(err);
        }
      });

      producerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            break;

          case "failed":
            producerTransport.close();
            break;

          default:
            break;
        }
      });

      const videoTrack = local_media_stream.getVideoTracks()[0];

      if (videoTrack) {
        const trackParams = {
          track: videoTrack,
          encodings: [{ maxBitrate: 90000 }],
        };
        videoProducer = await producerTransport.produce(trackParams);
      }
      const audioTrack = local_media_stream.getAudioTracks()[0];
      if (audioTrack) {
        const trackParams = {
          track: audioTrack,
          encodings: [{ maxBitrate: 48000 }],
        };
        audioProducer = await producerTransport.produce(trackParams);
      }
      console.log("socket id in line 545 ", socket);
      subscribe(socket);

      setIsHold(false);
    };
    const confirmChatToVoice = async (id, course_id, code) => {
      setRoomId(course_id);

      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;

      // setAlert(null);
      // setPlaying(false);
      // setId(id);
      console.log(`  in .... line 562 id,  ${id}, course_id : ${course_id},code : ${code}    `);
      console.log(`  in .... line 563 socket,  ${socket}, socket_id : ${socket.id},    `);
      console.log(`  in .... line 564 operator  socket id  ,  ${socket.id}, client socket id  : ${id},    `);
      //

      //

      setAlert(null);
      setPlaying(false);
      setId(id);

      //

      const dat = await sendRequest("accept-to-voice", { id, course_id }, socket);
      // setTimeout(() => {
      //   sendOperatorInformation(socket);
      // }, 1000);
      setIsFree(true);

      // axios
      //   .get(ApiUrl + "/get-problem/" + code, {
      //     params: {},
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //       Authorization: localStorage.getItem("token"),
      //     },
      //   })
      //   .then(function (response) {
      //     setData(response.data.data.problems);
      //   })
      //   .catch(function (error) {})
      //   .finally(function () {});

      const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);

      console.log("response  sendRequest(getRouterRtpCapabilities", data, course_id);

      await loadDevice(data);

      const params = await sendRequest("createProducerTransport", {}, socket);

      console.log("response await sendRequest(createProducerTransport", params);
      //
      producerTransport = device.createSendTransport(params);

      producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        await sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
          .then(callback)
          .catch(errback);
      });
      producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { id } = await sendRequest(
            "produce",
            {
              transportId: producerTransport.id,
              kind,
              rtpParameters,
              type: "teacher",
            },
            socket
          );

          callback({ id });
        } catch (err) {
          errback(err);
        }
      });

      producerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            break;

          case "failed":
            producerTransport.close();
            break;

          default:
            break;
        }
      });

      console.log("local_media_stream.getVideoTracks()", local_media_stream);
      // const videoTrack = local_media_stream.getVideoTracks()[0];

      // if (videoTrack) {
      //   const trackParams = {
      //     track: videoTrack,
      //     encodings: [{ maxBitrate: 90000 }],
      //   };
      //   videoProducer = await producerTransport.produce(trackParams);
      // }
      const audioTrack = local_media_stream.getAudioTracks()[0];
      if (audioTrack) {
        const trackParams = {
          track: audioTrack,
          encodings: [{ maxBitrate: 48000 }],
        };
        audioProducer = await producerTransport.produce(trackParams);
      }

      await subscribe(socket);

      setIsHold(false);
      readDataIndexedDb(setMessagList);
    };
    const confirmChatToVideo = async (id, course_id, code) => {
      setRoomId(course_id);

      if (producerTransport) producerTransport.close();
      producerTransport = null;

      if (consumerTransport) consumerTransport.close();
      consumerTransport = null;

      device = null;

      // setAlert(null);
      // setPlaying(false);
      // setId(id);
      console.log(`  in .... line 562 id,  ${id}, course_id : ${course_id},code : ${code}    `);
      console.log(`  in .... line 563 socket,  ${socket}, socket_id : ${socket.id},    `);
      console.log(`  in .... line 564 operator  socket id  ,  ${socket.id}, client socket id  : ${id},    `);
      //

      //

      setAlert(null);
      setPlaying(false);
      setId(id);

      //

      const dat = await sendRequest("accept-to-video", { id, course_id }, socket);
      // setTimeout(() => {
      //   sendOperatorInformation(socket);
      // }, 1000);
      setIsFree(true);

      // axios
      //   .get(ApiUrl + "/get-problem/" + code, {
      //     params: {},
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //       Authorization: localStorage.getItem("token"),
      //     },
      //   })
      //   .then(function (response) {
      //     setData(response.data.data.problems);
      //   })
      //   .catch(function (error) {})
      //   .finally(function () {});

      const data = await sendRequest("getRouterRtpCapabilities", { room_id: course_id }, socket);

      console.log("response  sendRequest(getRouterRtpCapabilities", data, course_id);

      await loadDevice(data);

      const params = await sendRequest("createProducerTransport", {}, socket);

      console.log("response await sendRequest(createProducerTransport", params);
      //
      producerTransport = device.createSendTransport(params);

      producerTransport.on("connect", async ({ dtlsParameters }, callback, errback) => {
        await sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket)
          .then(callback)
          .catch(errback);
      });
      producerTransport.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
        try {
          const { id } = await sendRequest(
            "produce",
            {
              transportId: producerTransport.id,
              kind,
              rtpParameters,
              type: "teacher",
            },
            socket
          );

          callback({ id });
        } catch (err) {
          errback(err);
        }
      });

      producerTransport.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            break;

          case "connected":
            break;

          case "failed":
            producerTransport.close();
            break;

          default:
            break;
        }
      });

      console.log("local_media_stream.getVideoTracks()", local_media_stream);
      const videoTrack = local_media_stream.getVideoTracks()[0];

      if (videoTrack) {
        const trackParams = {
          track: videoTrack,
          encodings: [{ maxBitrate: 90000 }],
        };
        videoProducer = await producerTransport.produce(trackParams);
      }
      const audioTrack = local_media_stream.getAudioTracks()[0];
      if (audioTrack) {
        const trackParams = {
          track: audioTrack,
          encodings: [{ maxBitrate: 48000 }],
        };
        audioProducer = await producerTransport.produce(trackParams);
      }

      await subscribe(socket);

      setIsHold(false);
      readDataIndexedDb(setMessagList);
    };
  };
  useEffect(() => {
    socketIo && socketIo.emit("message_edit", editMessage);
  }, [editMessage]);
  useEffect(() => {
    // console.log(operator);
    // let items = {
    //   message: "سیلام",
    //   id: socketIo.id ? socketIo.id : "",
    //   operator_id: localStorage.getItem("_id"),
    // };
    // socketIo && socketIo.emit("chat_message" ,items);
  }, []);

  const [flagConfirmVip, setFlagConfirmVip] = useState(null);
  const [dataVip, setDataVip] = useState(null);
  const confirmVip = (e) => {
    e.preventDefault();
    setDataVip(e.target);
    setFlagConfirmVip(true);
  };
  const sendVip = () => {
    let fd = new FormData(dataVip);
    setFlagConfirmVip(false);
    let data = {
      first_name: fd.get("first_name"),
      last_name: fd.get("last_name"),
      national_code: fd.get("national_code"),
      mobile: fd.get("mobile"),
      vip: fd.get("vip") == "on" ? true : false,
    };
    return props.store.dispatch(CustomerAction.createCustomer(data, []));
  };
  ///////////

  ///////////
  const [flagConfirm, setFlagConfirm] = useState(null);
  const [dataProblem, setDataProblem] = useState(null);

  const sbmitCustomerProblem = (e) => {
    e.preventDefault();
    setFlagConfirm(true);
    setDataProblem(e.target);
    // let fd = new FormData(e.target)
    // return props.store.dispatch(CustomerAction.sendProblem(fd))
  };
  const sendProblem = () => {
    let fd = new FormData(dataProblem);
    setFlagConfirm(false);
    return props.store.dispatch(CustomerAction.sendProblem(fd));
  };
  ///////////

  const endChat = () => {
    // clearTransaction();
    if (producerTransport) producerTransport.close();
    producerTransport = null;
    setDegree(0);

    if (consumerTransport) consumerTransport.close();
    consumerTransport = null;

    device = null;
    setIsFree(false);
    setIsChat(false);
    removeRemoteVideo(0);

    if (local_media_stream) {
      const tracks = local_media_stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });
    }
    if (local_screen_stream) {
      const tracks = local_screen_stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });

      setIsShare(false);
    }
  };
  const endCall = () => {
    // clearTransaction();
    if (producerTransport) producerTransport.close();
    producerTransport = null;
    setDegree(0);

    if (consumerTransport) consumerTransport.close();
    consumerTransport = null;
    removeRemoteVideo(0);
    device = null;
    socketIo.emit("end_call");
    setIsFree(false);
    setIsChat(false);
    if (tracks) {
      console.log("tracks", tracks);
    }

    if (local_media_stream || tracks) {
      const tracks = local_media_stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });
      console.log(" beforre tracks", tracks);
      local_media_stream = null;
      setTracks(null);
    }
    if (local_screen_stream) {
      const tracks = local_screen_stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });

      setIsShare(false);
    }
  };

  function holdCall() {
    socketIo.emit("hold_call", !is_hold);
    setIsHold(!is_hold);
  }

  const init_display = (callback) => {
    if (navigator.getDisplayMedia) {
      navigator.getDisplayMedia({ video: true }).then(async (screenStream) => {
        setIsShare(true);
        local_screen_stream = await screenStream;
        let self = document.getElementById("screen");
        self.srcObject = local_screen_stream;
        if (callback) callback();
      });
    } else if (navigator.mediaDevices.getDisplayMedia) {
      navigator.mediaDevices.getDisplayMedia({ video: true }).then(async (screenStream) => {
        setIsShare(true);

        local_screen_stream = await screenStream;
        let self = document.getElementById("screen");
        self.srcObject = local_screen_stream;
        if (callback) callback();
      });
    } else {
      alert("خطا در دسترسی به صفحه نمایش");
    }
  };

  useEffect(() => {
    if (local_screen_stream)
      local_screen_stream.getVideoTracks()[0].onended = function () {
        setIsShare(false);

        socketIo.emit("cancel_display", id);
      };
  });

  const onSubmit = (e) => {
    e.preventDefault();
    let f = selectedMenu.find((el) => el == true);
    if (f) {
      let menusId = [];
      menus.forEach((item, index) => {
        if (selectedMenu[index] == true) {
          menusId.push(item._id);
        }
      });
      let data = {
        menusId,
        id: localStorage.getItem("_id"),
      };
      updateMenu(data, true);
    }
  };

  const updateMenu = (data, activeMenuFlag) => {
    axios({
      url: ApiUrl + "/update-menu",
      method: "post",
      params: {},
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        if (activeMenuFlag) {
          activeMenus();
        }
      })
      .catch(function (error) {
        //
      })
      .finally(function () {
        // always executed
      });
  };

  const stopDisplay = (local_screen_stream) => {
    if (local_screen_stream) {
      local_screen_stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }

    setIsShare(false);
    if (socketIo) socketIo.emit("cancel_display", id);
  };

  const shareScreen = async (room) => {
    //

    const socket_screen = await io(Url, { auth: localStorage.getItem("token") });
    // await setSocketDisplay(socket_screen)
    socket_screen.on("connect", async function () {
      //
      console.log("🚀 ~ connection ~ socket id :", socket_screen.id);
      console.log("🚀 ~ connection ~ socket  id :", socket_screen.socket.sessionid);
      await sendRequest("prepare_room", { roomId: room }, socket_screen);
    });
    init_display(async function () {
      //

      const data = await sendRequest("getRouterRtpCapabilities", { room_id: room }, socket_screen);
      //
      await loadDeviceScreen(data);
      //

      const params = await sendRequest("createProducerTransport", {}, socket_screen);
      producerTransportDisplay = deviceScreen.createSendTransport(params);

      producerTransportDisplay.on("connect", async ({ dtlsParameters }, callback, errback) => {
        //
        sendRequest("connectProducerTransport", { dtlsParameters: dtlsParameters }, socket_screen)
          .then(callback)
          .catch(errback);
      });

      producerTransportDisplay.on("produce", async ({ kind, rtpParameters }, callback, errback) => {
        //
        try {
          const { id } = await sendRequest(
            "produce",
            {
              transportId: producerTransportDisplay.id,
              kind,
              rtpParameters,
              type: "screen",
            },
            socket_screen
          );

          callback({ id });
          //
        } catch (err) {
          errback(err);
        }
      });

      producerTransportDisplay.on("connectionstatechange", (state) => {
        switch (state) {
          case "connecting":
            //
            break;

          case "connected":
            //
            break;

          case "failed":
            //
            producerTransportDisplay.close();
            break;

          default:
            break;
        }
      });
      const videoTrack = local_screen_stream.getVideoTracks()[0];
      if (videoTrack) {
        const trackParams = { track: videoTrack };
        videoProducer = await producerTransportDisplay.produce(trackParams);
        //
      }

      // local_screen_stream.getTracks().forEach(track => sharePeerConnection.addTrack(track, local_screen_stream));
      // sharePeerConnection.createOffer()
      //     .then(sdp => sharePeerConnection.setLocalDescription(sdp))
      //     .then(function () {
      //         socketIo.emit('offer_screen', id, sharePeerConnection.localDescription);
      //     });
      // sharePeerConnection.onicecandidate = function (event) {
      //     if (event.candidate) {
      //         socketIo.emit('candidate_screen', id, event.candidate);
      //     }
      // };
      // socketIo.on('candidate_screen', function (id, candidate) {
      //     sharePeerConnection && sharePeerConnection.addIceCandidate(new RTCIceCandidate(candidate));

      // });

      // socketIo.on('answer_screen', function (id, description) {
      //     try {

      //         sharePeerConnection.setRemoteDescription(description)

      //     } catch (e) {
      //         //

      //     }

      // });
      // socketIo.on('cancel_display', function (id, description) {

      //     sharePeerConnection && sharePeerConnection.close();

      //     if (local_screen_stream) {
      //         const tracks = local_screen_stream.getTracks();

      //         tracks.forEach(function (track) {
      //             track.stop();
      //         });
      //         sharePeerConnection = null

      //         setIsShare(false)
      //     }

      // });
    });
  };
  const divert = (socket) => {
    socketIo.emit("divert", socket);
  };
  // {isFree && !isChat && (
  //   <div className={"self1"}>
  //     <video muted={true} autoPlay={true} id={"self"} />
  //   </div>
  // )}

  // new ClipboardJS('.copyButton');
  var clipboard = new ClipboardJS(".copyButton");
  clipboard.on("success", function (e) {
    e.clearSelection();
    // toast.success("کپی شد")
  });

  clipboard.on("error", function (e) {});
  //Most sent messages
  const getMostMessage = function (e) {
    axios({
      url: ApiUrl + `/oprator-top-message/${localStorage._id}`,
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        response.data.data.forEach((msg) => {
          let message = `<div class="me" style='display:flex;justify-content: space-between;padding: 10px;align-items: center;' >
          <p class="copyTarget" >${msg._id.message} </p>
      
          <div >
        
            <button type="button" class="copyButton btn btn-secondary"  data-clipboard-text="${msg._id.message}" style="background-color: inherit;
                   border: none;"  >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--black)" class="bi bi-clipboard" viewBox="0 0 16 16">
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                </svg>
            </button>

          </div>
      </div>`;

          var messages = document.getElementById("messages");
          messages.innerHTML = messages.innerHTML + message;
        });
        // }
      })
      .catch(function (error) {});
  };
  let renderCalling = () => {
    // try{
    return [
      <div
        className={"calling admin"}
        key={0}
        style={{
          backgroundColor: "var(--white)",
        }}
      >
        <ToastContainer position={"top-center"} />
        {!isChat && (
          <VideoBoxCallingDesktop
            track={tracks}
            voice={voice}
            localMedia={local_media_stream}
            endCall={endCall}
            isShare={isShare}
            setIsShare={setIsShare}
            sendMessage={sendMessage}
            setFlagSend={setFlagSend}
            setUploadEvent={setUploadEvent}
            uploadEvent={uploadEvent}
            isTyping={isTyping}
            setIsTyping={setIsTyping}
            otherTyping={otherTyping}
            onTypingMessage={onTypingMessage}
            shareScreen={shareScreen}
            roomId={roomId}
            local_screen_stream={local_screen_stream}
            stopDisplay={stopDisplay}
            operator={true}
            editMessage={editMessage}
            setEditMessage={setEditMessage}
            editMessageInput={editMessageInput}
            setEditMessageInput={setEditMessageInput}
            customer={props.customer}
            confirmVip={confirmVip}
            userNationalCode={userNationalCode}
            userMobile={userMobile}
            loading={props.loading}
            sbmitCustomerProblem={sbmitCustomerProblem}
            onKeyDown={onKeyDown}
          />
        )}
        {isChat && (
          <div
            style={{
              // height: "60rem",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Detail
              sendMessage={sendMessage}
              customer={props.customer}
              confirmVip={confirmVip}
              userNationalCode={userNationalCode}
              userMobile={userMobile}
              loading={props.loading}
              sbmitCustomerProblem={sbmitCustomerProblem}
              setFlagSend={setFlagSend}
              setUploadEvent={setUploadEvent}
              uploadEvent={uploadEvent}
              otherTyping={otherTyping}
              onTypingMessage={onTypingMessage}
              onKeyDown={onKeyDown}
              setEditMessage={setEditMessage}
            />
            <Btn
              style={{ alignSelf: "center" }}
              className="OperatorHeader__endCall OperatorHeader__ChatEndCall"
              onClick={() => endCall()}
            >
              پایان تماس
            </Btn>
          </div>
        )}
        {/* // data.length !== 0 && (
      //   <div className={"calling admin"} key={1}>
      //     <h4>مشکلات قبلی ثبت شده</h4>
      //     <div className="problems-pr">
      //       {data &&
      //         data.map((e, i) => {
      //           return (
      //             <div className="pr" key={i}>
      //               {e.problem}
      //             </div>
      //           );
      //         })}
      //     </div>
      //   </div>
      // ), */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          width={"100%"}
          sx={{ backgroundColor: "var(--white)" }}
        >
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242", textAlign: "start", margin: "2%" }}>بیشترین پیام های ارسال شده</h4>
            </CardHeader>
            <CardBody style={{ maxHeight: "400px", overflow: "auto", minHeight: "120px" }}>
              <Fragment>
                <div id={"messages"} className={"messages"}></div>
              </Fragment>
            </CardBody>
          </Card>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242", textAlign: "start", margin: "2%" }}>یادداشت ها</h4>
            </CardHeader>
            <CardBody style={{ minHeight: "120px" }}>
              <AddNotes />
            </CardBody>
          </Card>
        </Box>
        <div className="row" key={3}>
          <GridContainer>
            <GridItem md={12} className="mt-4" sm={12} xs={12}>
              <h4>اپراتورهای آزاد</h4>
            </GridItem>
            {dataItems &&
              dataItems.map((e, i) => {
                return (
                  <GridItem md={6} sm={6} xs={12} lg={4} key={i}>
                    <div className={"list-container"} dir="rtl">
                      <span>صف {e.name === "کیپاد" ? "پی پاد" : e.name}</span>

                      <ul id={"operator_" + e._id} className={"online-user-list-all"}>
                        {qsOp[e._id] &&
                          qsOp[e._id].map((index) => {
                            return (
                              <li key={index}>
                                <span className="pulsating-circle"></span>
                                <span>{index.first_name + " " + index.last_name}</span>
                                <span
                                  className="removeo"
                                  onClick={(v) => {
                                    divert(index.socket);
                                  }}
                                >
                                  انتقال تماس
                                </span>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </GridItem>
                );
              })}
          </GridContainer>
        </div>
        ,
      </div>,
    ];
    // }catch(error){
    //     return(
    //       // <div
    //       //   className={"calling admin"}
    //       // >
    //       <ErrorCard>
    //         <div style={{height : '80%', width : '100%', textAlign: 'center' }}>با عرض پوزش، خطایی در سرور رخ داده است.</div>
    //         <Btn className="OperatorHeader__endCall OperatorHeader__ChatEndCall" onClick={() => endCall()}>
    //               پایان تماس
    //         </Btn>
    //       </ErrorCard>
    //     //  </div>
    //     )
    //   }
  };

  const makeAlert = (text) => {
    alert(text);
  };

  const sendMessage = function () {
    if (flagSend === false) {
      setFlagSend(true);
      uploadFile();
    }

    // let message = document.getElementById("message");
    if (!message == "") {
      let items = {
        message: message,
        id: socketIo.id,
        operator_id: localStorage.getItem("_id"),
      };
      socketIo.emit("chat_message", items);
      // message = "";
      setMessageForm("");
    }
  };
  const uploadFile = function (e) {
    let event = uploadEvent;
    var fd = new FormData();
    fd.append("file", event);

    let items = {
      message: " ... در حال ارسال فایل",
      id: socketIo.id,
      operator_id: localStorage.getItem("_id"),
    };
    socketIo.emit("chat_message", items);

    axios({
      url: ApiUrl + "/upload-chat-file",
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
      data: fd,
    })
      .then(function (response) {
        let message = document.getElementById("selectFile");

        let items = {
          message: Url + response.data.data.path,
          id: socketIo.id,
          operator_id: localStorage.getItem("_id"),
        };

        socketIo.emit("chat_message_file", items);
        message.value = "";
        setUploadEvent("");
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  const sendOperatorInformation = (socket) => {
    // let items = {
    //   message: `سلام ${operator.last_name} هستم بفرمایید `,
    //   id: socket.id,
    //   operator_id: localStorage.getItem("_id"),
    // };
    // socket && socket.emit("chat_message", items);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
    // else if (event.key === "Enter" && event.shiftKey) {
    // }
  };

  const renderStatus = () => {
    switch (props.operatorStatus) {
      case "off":
        return Off;
      case "standby":
        return standby;
      case "on":
        return on;
    }
  };
  const renderStatusText = () => {
    switch (props.operatorStatus) {
      case "off":
        return "خاموش";
      case "standby":
        return "استراحت";
      case "on":
        return "فعال";
    }
  };
  const [degree, setDegree] = useState(0);
  let rtate = () => {
    setDegree(degree + 90);
  };

  useEffect(() => {
    let element = document.querySelector("#remote video");
    //

    if (element) element.setAttribute("style", `transform: rotate(${degree}deg);width:100vh;height:100vh`);
  }, [degree]);
  const activeMenus = () => {
    setIsFree(false);
    setIsChat(false);
    setChooseMenu(false);
    connection();

    // setStatus("on");
    props.setOperatorStatus("on");
    localStorage.setItem("status", "on");
  };
  const renderWaiting = () => {
    let menuActive = [];
    menus &&
      menus.forEach((item, index) => {
        if (selectedMenu[index] == true) {
          menuActive.push(item.name);
        }
      });
    props.setOperatorQueues(menuActive);
    return (
      // <div className={"waiting admin"}>
      // {/* <img src={renderStatus()}/> */}
      // {/* <h1>{"وضعیت: " + renderStatusText()}</h1> */}
      <button
        id="active"
        onClick={function () {
          if (props.operatorStatus == "on") {
            // makeAlert("شما هم اکنون در حالت آماده به کار هستید");
            setFlagReason(true);
          } else {
            setChooseMenu(true);
          }
        }}
        // className={"status-btn active"}
        className={props.operatorStatus == "off" ? btnStyles["glow-on-hover"] : btnStyles["deactive-on-hover"]}
        type={"button"}
      >
        {props.operatorStatus !== "on" ? <p>فعال کردن</p> : <p>غیر فعال کردن</p>}
      </button>
      // {/* <input
      //   className={"status-btn standby"}
      //   value={"استراحت"}
      //   type={"button"}
      //   onClick={function () {
      //     if (status == "on") {
      //       setIsFree(false);
      //       setIsChat(false);
      //       let menuList = [];
      //       menus.forEach((item, index) => {
      //         if (selectedMenu[index] == true) {
      //           menuList.push(item._id);
      //         }
      //       });
      //       socketIo &&
      //         socketIo.emit("exit_operator", {
      //           id: localStorage.getItem("_id"),
      //           reason: "استراحت",
      //           menuList: menuList,
      //         });
      //       socketIo && socketIo.disconnect();
      //       setStatus("standby");
      //     }
      //   }}
      // /> */}

      //   {/* <input
      //     className={"status-btn off"}
      //     value={"خاموش"}
      //     type={"button"}
      //     onClick={function () {
      //       if (status == "on") {
      //         setFlagReason(true);
      //       }
      //     }}
      //   /> */}

      //   {/* <br />
      //   <br />
      //   <br /> */}
      //   {/* <div>
      //     {status == "on"
      //       ? menuActive && menuActive.length
      //         ? menuActive.map((item, index) => <h4 key={index}>{item}</h4>)
      //         : "شما در هیچ صفی فعال نیستید"
      //       : "شما در هیچ صفی فعال نیستید"}
      //   </div> */}
      // // </div>
    );
  };

  const onSubmitReason = (e) => {
    e.preventDefault();
    //
    let reason = "";
    vState.forEach((item, index) => {
      if (item) {
        reason = reasonsList[index].name;
      }
    });
    if (!reason == "") {
      //if reason choose
      setFlagReason(false);
      setIsFree(false);
      setIsChat(false);
      let data = {
        menusId: [],
        id: localStorage.getItem("_id"),
      };
      updateMenu(data);
      let menuList = [];
      menus.forEach((item, index) => {
        if (selectedMenu[index] == true) {
          menuList.push(item._id);
        }
      });
      let v = selectedMenu;
      selectedMenu.forEach((item, index) => {
        v[index] = false;
      });
      setSelectedMenu(v);
      socketIo &&
        socketIo.emit("exit_operator", {
          id: localStorage.getItem("_id"),
          reason,
          menuList: menuList,
        });
      socketIo && socketIo.disconnect();
      localStorage.setItem("status", "off");
      // setStatus("off");
      props.setOperatorStatus("off");
    }
  };

  return (
    <GridContainer>
      {flagReason && (
        <div className="overlay">
          <div className="choose-menu">
            <h4 style={{ textAlign: "start" }}>لطفا علت خروج خود را مشخص کنید</h4>

            <form onSubmit={(e) => onSubmitReason(e)}>
              <ul>
                {reasonsList &&
                  reasonsList.map((e, index) => {
                    return (
                      <li key={index} style={{ display: "flex", alignItems: "center" }}>
                        <input
                          onChange={(e) => {
                            vState.forEach((vs, i) => {
                              if (i == index) {
                                vState[i] = !vState[i];
                              } else {
                                vState[i] = false;
                              }
                              //
                            });
                          }}
                          id={e.id}
                          name="menus"
                          value={e.id}
                          type="radio"
                        />
                        &nbsp;
                        <label htmlFor={e.id}>{e.name}</label>
                      </li>
                    );
                  })}

                <div className="center">
                  <input type="submit" className="status-btn active" value="انتخاب" />
                  <input
                    type="button"
                    className="status-btn cancel"
                    onClick={() => {
                      setFlagReason(false);
                    }}
                    value="لغو"
                  />
                </div>
              </ul>
            </form>
          </div>
        </div>
      )}
      {chooseMenu && (
        <div className="overlay">
          <div className="choose-menu">
            <h4 style={{ textAlign: "start" }}>لطفا صف های مورد نظر خود را انتخاب کنید:</h4>
            <form onSubmit={(e) => onSubmit(e)}>
              <ul>
                {menus &&
                  menus.map((e, index) => {
                    //
                    return (
                      <li key={index} style={{ display: "flex", alignItems: "center" }}>
                        <input
                          onChange={(e) => {
                            let v = selectedMenu;
                            v[index] = !v[index];
                            setSelectedMenu(v);
                            if (!e.target.checked) {
                              let inp = document.getElementById("all_menus");
                              inp.checked = e.target.checked;
                            }
                          }}
                          id={e._id}
                          key={e._id}
                          name="menus"
                          defaultChecked={selectedMenu[index]}
                          value={e._id}
                          type="checkbox"
                        />
                        &nbsp;
                        <label htmlFor={e._id}>{e.name === "کیپاد" ? "پی پاد" : e.name}</label>
                      </li>
                    );
                  })}
                <li style={{ display: "flex", alignItems: "center" }}>
                  <input
                    onChange={(event) => {
                      let v = selectedMenu;
                      menus &&
                        menus.forEach((el, i) => {
                          let inp = document.getElementById(el._id);
                          inp.checked = event.target.checked;
                          v[i] = event.target.checked;
                        });
                      //
                      setSelectedMenu(v);
                    }}
                    id="all_menus"
                    name="menus"
                    value="all_menus"
                    type="checkbox"
                  />
                  <label htmlFor="all_menus">همه ی صف ها</label>
                </li>
              </ul>
              <div className="center">
                <input type="submit" className="status-btn active" value="فعال" />
                <input
                  type="button"
                  className="status-btn cancel"
                  onClick={() => {
                    setChooseMenu(false);
                  }}
                  value="لغو"
                />
              </div>
            </form>
          </div>
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>داشبورد - {pageTitle}</title>
      </Helmet>
      {alerts}
      <Snackbar
        place="tl"
        color="success"
        icon={AddAlert}
        message="ویزیت با موفقیت ثبت شد"
        open={tl}
        closeNotification={() => setTL(false)}
        close
      />

      {/* <GridItem md={12} className="online-user-list-parent" sm={12}>
                <div dir="rtl" className="online-user-list-parent">
                    <h4>صف انتظار</h4>

                    <ul style={{
                        display: "flex",
                        flexWrap: "wrap",
                        height: 200
                    }} id={"OnlineUsers"} className={"online-user-list"}>


                        {
                            onlineUsers.map(e => {
                                // 
                                return <li style={{
                                    width: "30%",
                                    margin: 5,
                                    height: 30
                                }}><span className="pulsating-circle"> </span>

                                    <span>{e.code}</span>


                                </li>

                            })
                        }

                    </ul>


                </div>
            </GridItem> */}
      <GridItem md={12} sm={12} style={{ width: "100%" }}>
        <div
          style={
            isFree
              ? {
                  position: "absolute",
                  top: "0",
                  right: "0",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "var(--white)",
                  zIndex: "12",
                }
              : null
          }
        >
          <div
            dir="rtl"
            style={
              isFree
                ? {
                    backgroundColor: "var(--white)",
                    zIndex: "100",
                    width: "99%",
                    height: "100%",
                    top: "6rem",
                    right: "5px",
                    position: "absolute",
                  }
                : null
            }
          >
            {/* style={{zIndex : '100', width : '100%', height : '100%', top : '6rem', right : '0', position : 'absolute'}}  */}
            <div className={"video-container operator"} style={{ height: "100%" }}>
              {flagConfirm && (
                <SweetAlert
                  style={{ display: "block", marginTop: "-100px" }}
                  title="آیا مشکل ثبت شود؟"
                  onConfirm={() => sendProblem()}
                  onCancel={() => setFlagConfirm(false)}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                  cancelBtnCssClass={classes.button + " " + classes.danger}
                  confirmBtnText="تایید"
                  cancelBtnText="انصراف"
                  showCancel
                ></SweetAlert>
              )}
              {flagConfirmVip && (
                <SweetAlert
                  style={{ display: "block", marginTop: "-100px" }}
                  title="آیا کاربر ثبت شود؟"
                  onConfirm={() => sendVip()}
                  onCancel={() => setFlagConfirmVip(false)}
                  confirmBtnCssClass={classes.button + " " + classes.success}
                  cancelBtnCssClass={classes.button + " " + classes.danger}
                  confirmBtnText="تایید"
                  cancelBtnText="انصراف"
                  showCancel
                ></SweetAlert>
              )}

              {isFree ? renderCalling() : renderWaiting()}

              {isShare && (
                <div className={"screen"}>
                  <video muted={true} autoPlay={true} id={"screen"} />
                </div>
              )}
              {/* <div
                id="audio-container"
                className="video"
                style={{ display: "none" }}
              ></div> */}
            </div>

            {/* {isFree ? (
                <div className="Landing">
                  <div className="Landing__detailBox">
                    <Detail />
                  </div>
                  <div className="Landing__videoBox">
                    <Operator />
                  </div>
                </div>
              ) : (
                renderWaiting()
              )} */}
          </div>
        </div>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = (state) => ({
  customer: state.customer.customer,
  customer_loading: state.customer.loading,
  message: state.customer.message,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CircularButton);
