import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl, Url } from "../../config";
import UserAction from "../../js/actions/user_action";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState({
    email: "",
    username: "",
    type: "operator",
    first_name: "",
    last_name: "",
    national_code: "",
    mobile: "",
  });
  const [rolls, setRolls] = useState([]);
  const [dataSelectLevel, setDataSelectLevel] = useState("");
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const getRolls = async () => {
    try {
      const res = await axios.get(ApiUrl + "/rolls", { headers: { Authorization: localStorage.getItem("token") } });
      setRolls(res.data.data.data);
      let options = [];
      res.data.data.data.forEach((item) => {
        options.push({
          value: item.name,
          label: item.name,
          roll: item.roll,
        });
      });
      setDataSelectLevel(options);
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line no-console
  };
  const getUsers = () => {
    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setMenus(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  useEffect(() => {
    getUsers();
    getRolls();
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    props.store.dispatch(UserAction.CreateNewUser(data));
    setData({
      email: "",
      username: "",
      menu: [],
      first_name: "",
      last_name: "",
      national_code: "",
      mobile: "",
      roles: [],
    });
    setLoading(false);
  }

  function handle_change(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  function handle_change_menu(e) {
    var options = e.target.options;
    var value = [];
    setData({
      ...data,
      menu: [],
    });
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setData({
      ...data,
      menu: value,
    });
  }

  const classes = useStyles();

  if (props.loading) return <div>...در حال ارسال</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>اپراتور - {pageTitle}</title>
      </Helmet>
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitleWhite} color={"var(--textColor)"}>
                    افزودن عضو جدید
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <TextField
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    name={"username"}
                    value={data.username}
                    label={"نام کاربری دیدار"}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    type={"email"}
                    value={data.email}
                    name={"email"}
                    label={"ایمیل"}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    required
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    type={"number"}
                    value={data.mobile}
                    name={"mobile"}
                    label={"تلفن همراه"}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    required
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    value={data.first_name}
                    name={"first_name"}
                    label={"نام"}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    required
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    value={data.last_name}
                    name={"last_name"}
                    label={"نام خانوادگی"}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    required
                    onChange={(e) => {
                      handle_change(e);
                    }}
                    type={"number"}
                    value={data.national_code}
                    name={"national_code"}
                    label={"کد ملی"}
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                  />

                  <TextField
                    select
                    required
                    label={"نوع عضو"}
                    name={"type"}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                    onChange={(e) => {
                      handle_change(e);
                    }}
                  >
                    <MenuItem value={"operator"}>اپراتور</MenuItem>
                    <MenuItem value={"admin"}>مدیر</MenuItem>
                  </TextField>

                  <TextField
                    select
                    required
                    label={"سطح دسترسی"}
                    name={"roles"}
                    InputLabelProps={{
                      shrink: true,
                      sx: { ".MuiInputLabel-asterisk": { color: "red", fontSize: "1.2rem" } },
                    }}
                    sx={{
                      "& .MuiFormLabel-root": {
                        top: "-20px !important",
                      },
                    }}
                    onChange={(e) => {
                      handle_change(e);
                    }}
                  >
                    {rolls.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormLabel className={classes.labelHorizontal}>بخش های مربوطه</FormLabel>

                  <select
                    onChange={(e) => {
                      handle_change_menu(e);
                    }}
                    name="menu"
                    className={"menus"}
                    multiple
                  >
                    {menus.map((e, i) => {
                      return (
                        <option style={{ background: `url(${Url + e.icon}) no-repeat` }} value={e._id} key={i}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                </Box>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {/*<Button type={"submit"} color="rose">*/}
                {/*  ذخیره*/}
                {/*</Button>*/}
                <Button
                  type={"submit"}
                  disabled={loading}
                  sx={{
                    padding: "10px 30px",
                    backgroundColor: "var(--pink)",
                    fontSize: "1rem",
                    color: "var(--white)",
                    "&:hover": {
                      color: "var(--black)",
                    },
                  }}
                >
                  {loading ? "درحال ذخیره..." : "ذخیره"}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
