// import store from "../js/store";
import routes from "client_routes";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import ChatBotAvandBubble from "../components/ChatBotAvandBubble/ChatBotAvandBubble";
import useWindowSize from "../Sizes/UseWindowSize";
import { pageTitle } from "web-config";

const TextScroller = ({ text }) => {
  const [key, setKey] = useState(1);
  const scrolling = useSpring({
    from: { transform: "translate(-100%,0)" },
    to: { transform: "translate(100%,0)" },
    config: { duration: 60000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div key={key} style={{ height: "100%" }}>
      <animated.div className="changeanim" style={scrolling}>
        <ul>
          <li>
            <b>** قوانین و مقررات {pageTitle} **</b>
          </li>
          <li>
            {" "}
            * به جهت افزایش رضایت و پاسخگویی بهتر به شما عزیزان کلیه مکالمات تصویری و متنی با کارشناسان ما ضبط می گردد .
          </li>
          <li>* رعایت کلیه شئونات اسلامی در هنگام برقراری ارتباط با کارشناسان ما الزامی می باشد.</li>
          <li>* جهت استفاده بهتر از این سرویس می بایست از یک بستر اینترنت پایدار و پرسرعت استفاده نمائید.</li>
        </ul>
      </animated.div>
    </div>
  );
};

const getRoutes = (routes, store) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }
    if (prop.layout === "/client") {
      return (
        <Route
          path={prop.layout + prop.path}
          key={key}
          render={(props) => <prop.component {...props} store={store} />}
        />
      );
    } else if (prop.layout === "/api") {
      return (
        <Route
          path={prop.layout + prop.path}
          key={key}
          render={(props) => <prop.component {...props} store={store} />}
        />
      );
    } else {
      return null;
    }
  });
};

export default function Client(props) {
  const windowSize = useWindowSize();
  const { store } = props;
  return (
    <div style={{ height: "100%" }}>
      {/* <ChatBotAvandBubble/> */}
      <Switch>
        {getRoutes(routes, store)}
        <Redirect from="/client" to="/client/welcome" />
      </Switch>
      {/* eslint-disable-next-line no-restricted-globals */}
      {windowSize === "lg" ||
        windowSize === "big" ||
        (windowSize === "xlg" && (
          <div
            className="slidingtrms"
            style={{
              background: window.location.pathname === "/client/welcome" && "transparent",
            }}
          >
            <TextScroller />
          </div>
        ))}
    </div>
  );
}
