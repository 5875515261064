import FAQCard from "../FAQCard/FAQCard";
import { Box, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Url } from "config";
import { ApiUrl } from "config";
import React, { useEffect, useState } from "react";
import CardView from "components/CardView/CardView";
function AdminSlider(props) {
  const [faqData, setFaqData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [queue, setQueue] = useState([]);
  const [word, setWord] = useState("");
  const [mainSlide, setMainSlide] = useState([]);
  const [reload, setReload] = useState(false);
  const getSlides = async () => {
    await axios
      .get(ApiUrl + "/main-slide", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setMainSlide(res.data.data.slider);
      })
      .catch((res) => {});
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.forEach(async (item) => {
          await axios
            .get(ApiUrl + "/menu-slide/" + item._id, {
              headers: { Authorization: localStorage.getItem("token") },
            })
            .then((res) => {
              console.log(res.data.data);
              if (res.data.data.slider.length > 0) {
                let obj = {
                  menu: item._id,
                  name: item.name,
                  slides: res.data.data.slider.filter((x) => x.status === true),
                };
                setMenu((prev) => [...prev, obj]);
              }
            });
        });
        setQueue(res.data.data);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    getSlides();
    getQueues();
  }, []);
  useEffect(() => {
    // getFaqs();
  }, [word]);
  return (
    <Box width={"100%"} sx={{ direction: "ltr" }} display={"list-item"} overflow={"auto"} maxHeight={"600px"}>
      {/*<Box width={"100%"}>*/}
      {/*  <TextField*/}
      {/*    variant="outlined"*/}
      {/*    placeholder="جستجو"*/}
      {/*    onChange={(e) => setWord(e.target.value)}*/}
      {/*    style={{ width: "100%" }}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box
        width={"100%"}
        padding={"16px"}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box>
          <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
            صفحه اصلی
          </Typography>
          <hr />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {mainSlide.length > 0
              ? mainSlide.map((p, index) => {
                  p.status === false && index++;
                  return (
                    <Box key={p._id}>
                      <CardView
                        setReload={setReload}
                        data={p}
                        img={p.image}
                        title={p.title}
                        text={p.text}
                        api={"/update-slide"}
                      />
                    </Box>
                  );
                })
              : "هیچ خبری در صفحه اصلی وجود ندارد"}
          </Box>
        </Box>
        {menu.map((item) => {
          return (
            <Box key={item._id}>
              <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
                {item.name}
              </Typography>
              <hr />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "16px",
                  flexWrap: "wrap",
                }}
              >
                {item.slides.map((p, index) => {
                  p.status === false && index++;
                  return (
                    <Box key={p._id}>
                      <CardView
                        setReload={setReload}
                        data={p}
                        img={p.image}
                        title={p.title}
                        text={p.text}
                        api={"/update-product-slide"}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default AdminSlider;
