// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
// core components
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { ApiUrl, Url } from "../../config";
import { toast } from "react-toastify";
import { CloseRounded, DeleteOutlineRounded, DoneAllRounded, DoneRounded } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "center",
    fontFamily: "IRANSans !important",
  },
  table: {
    // overflow: "hidden",
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
    borderRadius: "10px !important",
    maxHeight: "calc(100vh - 200px)",
  },
  thead: {
    backgroundColor: "var(--white)",
    border: "none !important",
    boxShadow: "none !important",
  },
  tbody: {
    // border:"none"
  },
  tr: {
    border: "none",
  },
  td: {
    border: "none",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState([]);
  const [loading, isLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const getMenus = () => {
    isLoading(true);

    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        setData(response.data.data.menu_items);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const changestatus = (id, status) => {
    isLoading(true);

    axios
      .get(ApiUrl + "/change-menu-item/" + id + "?status=" + status, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);

        getMenus();
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const deleteQueue = async (id) => {
    await axios
      .post(
        ApiUrl + "/delete-menu-id",
        {
          id: localStorage.getItem("_id"),
          menusId: id,
          is_delete: true,
        },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        setUpdate(!update);
        toast.success("با موفقیت آپدیت شد");
      })
      .catch((err) => {
        toast.error("خطا در حذف");
      });
  };
  useEffect(() => {
    getMenus();
  }, [update]);

  const classes = useStyles();
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>صف ها - {pageTitle}</title>
      </Helmet>

      <ReactTable
        rtl
        data={data}
        loading={loading}
        loadingText={"در  حال بارگذاری"}
        style={{ borderRadius: "10px ", width: "100%" }}
        pagination={true}
        manual
        getTableProps={() => ({ className: classes.table })}
        getTheadProps={() => ({ className: classes.thead })}
        getResizerProps={() => ({ className: classes.thead })}
        getTrProps={() => ({ className: classes.tr })}
        getTdProps={() => ({ className: classes.td })}
        getTbodyProps={() => ({ className: classes.tbody })}
        getPaginationProps={() => ({ className: classes.pagination })}
        filterable={true}
        rowsText={"سطر"}
        pageText={"صفحه"}
        nextText={"بعدی"}
        previousText={"قبلی"}
        ofText={"از"}
        noDataText={"..."}
        columns={[
          {
            Header: "آیکون",
            accessor: "icon",
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <div>
                <img style={{ width: 25 }} src={Url + row.value} />
              </div>
            ),
          },
          {
            Header: "نام صف",
            accessor: "name",
            sortable: false,
            filterable: false,
          },
          {
            Header: "وضعیت",
            accessor: "is_active",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value == true ? "فعال" : "غیر فعال"}</div>,
          },

          {
            Header: "عملیات",
            accessor: "_id",
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <div style={{ width: "100%", display: "flex" }}>
                <button
                  onClick={(e) => changestatus(row.value, !row.original.is_active)}
                  className={"status-btn active"}
                >
                  {row.original.is_active == false ? (
                    <Tooltip title={"فعال کردن"}>
                      <DoneRounded />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"غیر فعال کردن"}>
                      <CloseRounded />
                    </Tooltip>
                  )}
                </button>
                <button
                  onClick={(e) => deleteQueue(row.value)}
                  className={"status-btn active"}
                  style={{ backgroundColor: "var(--chiliRed)" }}
                >
                  <Tooltip title={"حذف"}>
                    <DeleteOutlineRounded />
                  </Tooltip>
                </button>
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        showPaginationTop={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      />
    </div>
  );
}

export default Users;
