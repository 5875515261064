import React, { useEffect, useState } from "react";
import { Box, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import { ApiUrl } from "config";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  textarea: {
    resize: "none",
    borderRadius: "8px",
    width: "100%",
    border: "1px solid var(--black)",
    padding: "10px",
  },
  root: {
    width: "100%",
  },
});
function AddFaq(props) {
  const classes = useStyles();
  const [queue, setQueue] = useState({});
  const [data, setData] = useState({
    question: "",
    answer: "",
    menu: "",
    status: true,
  });
  const [dataSelectQueue, setDataSelectQueue] = useState([]);
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const options = [];
        res.data.data.forEach((item) => {
          options.push({
            value: item._id,
            label: item.name === "کیپاد" ? "پی پاد" : item.name,
          });
        });
        setDataSelectQueue(options);
      })
      .catch((res) => {});
  };
  const addFaq = async () => {
    await axios
      .post(
        ApiUrl + "/add-faq",
        { ...data, menu: queue.value },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.success("با موفقیت ثبت شد");
      })
      .catch((res) => toast.error("خطا در ثبت"));
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  useEffect(() => {
    getQueues();
  }, []);
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: 10,
      display: "flex",
      alignItems: "center",
      zIndex: 999999,
    }),
    control: (base) => ({
      ...base,
      // height: "100px",
      width: "100%",
      border: "1px solid black",
      zIndex: 999999,
    }),
    menu: (base) => ({
      ...base,
      height: "fit-content",
      position: "relative",
      zIndex: 999999,
    }),
  };
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
      width={"100%"}
      height={"100%"}
      sx={{ gap: "10px" }}
    >
      <TextField
        name="question"
        variant="standard"
        placeholder="عنوان سوال"
        className={classes.root}
        onChange={onChangeHandler}
        value={data.question}
      />

      <textarea
        name="answer"
        className={classes.textarea}
        placeholder="جواب"
        onChange={onChangeHandler}
        value={data.answer}
      />

      <Box width={"200px"} style={{ gap: "10px" }} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <CreatableSelect
          closeMenuOnSelect={false}
          isClearable
          onChange={handleChangeQueue}
          options={dataSelectQueue}
          styles={customStyles}
          placeholder="انتخاب صف"
        />
      </Box>
      <Button color="primary" style={{ backgroundColor: "var(--primary)", color: "var(--white)" }} onClick={addFaq}>
        افزودن
      </Button>
    </Box>
  );
}

export default AddFaq;
