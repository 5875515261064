import { makeStyles } from "@material-ui/core/styles";
import { Audiotrack } from "@material-ui/icons";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import { ApiUrl } from "../../config";
const styles = {
  cardForm: {},
  form: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid black",
    borderRadius: "5px",
    padding: "5px",
    margin: "10px 0",
  },
  fileNameInput: {
    width: "100%",
    padding: "5px 10px",
    border: "none",
    background: "rgba(0,0,0,0.2)",
    borderRadius: "5px",
    textAlign: "center",
  },
  fileLabel: {
    padding: "5px 10px",
    color: "black",
    border: "1px solid #e91e63",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: ".3s",
    "&:hover": {
      background: "#e91e63",
      color: "white",
      transition: ".3s",
      transform: "scale(90%)",
    },
  },
  buttonSendFile: {
    padding: "10px 15px",
    background: "var(--primary)",
    color: "white",
    borderRadius: "5px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: ".3s",
    "&:hover": {},
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
};
const useStyles = makeStyles(styles);
const token = localStorage.getItem("token");
const id = localStorage.getItem("_id");

function Ringtone(props) {
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [operatorId, setOperatorId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRingtone, setIsRingtone] = useState(() => !localStorage.getItem("ringtone"));

  const onChangeFile = (e) => {
    if (e.target.files[0].size > 500000) {
      return toast.error("حداکثر حجم فایل 500 کیلوبایت");
    }
    setFile(e.target.files[0]);
  };
  const sendFile = async () => {
    const fd = new FormData();
    fd.append("ringtone", file, file.name);
    fd.append("id", id);
    try {
      setLoading(true);
      const res = await axios.post(`${ApiUrl}/update-users-ringtone`, fd, {
        headers: {
          Authorization: token,
        },
      });
      setLoading(false);
      if (res.status === 200) toast.success("زنگ تماس تغییر کرد");
      localStorage.setItem("ringtone", res.data.data.ringtone);
      setFile(null);
    } catch (e) {
      toast.error(e.message);
    }
  };
  useEffect(() => {
    localStorage.getItem("ringtone") ? setIsRingtone(false) : setIsRingtone(true);
  }, [file]);
  const defaultRingtone = () => {
    localStorage.removeItem("ringtone");
    toast.success("زنگ تماس به پیشرفض تغییر یافت");
    setIsRingtone(true);
  };
  return (
    <Card>
      <CardHeader color={"rose"} text>
        <CardText color={"rose"}>
          <h4 style={{ color: "var(--textColor)" }}>تغییر زنگ تماس</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <div className={classes.form}>
          <label className={classes.fileLabel} htmlFor="file-mp3">
            انتخاب فایل
            <Audiotrack />
          </label>
          <input
            name={"file-mp3"}
            type="file"
            id={"file-mp3"}
            style={{ display: "none" }}
            onChange={onChangeFile}
            accept={".mp3"}
          />
          <div style={{ display: "inline-block" }}>
            <input
              className={classes.fileNameInput}
              type="text"
              readOnly={true}
              value={file ? file.name : "حداکثر حجم آهنگ 500KB"}
            />
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <button className={classes.buttonSendFile} onClick={sendFile} disabled={!file}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                direction: "rtl",
              }}
            >
              <BeatLoader
                loading={loading}
                cssOverride={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 0",
                  borderColor: "black",
                }}
                color={"white"}
              />
            </div>
          ) : (
            "تغییر زنگ"
          )}
        </button>
        <button className={classes.buttonSendFile} disabled={isRingtone} onClick={defaultRingtone}>
          زنگ پیشفرض
        </button>
      </CardFooter>
      <ToastContainer position={"top-center"} />
    </Card>
  );
}

export default Ringtone;
