import React from "react";
import { ClimbingBoxLoader } from "react-spinners";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";

function LoadingLayout(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        direction: "rtl",
      }}
    >
      <ClimbingBoxLoader
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: dangerColor[0],
        }}
        color={"var(--primary)"}
      />
    </div>
  );
}

export default LoadingLayout;
