import React, { useEffect, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import styles from "../../style/Recharts/MissedCalls.module.scss";
import Select from "react-select";
import ButtonXlsx from "components/Recharts/ButtonChartXls/ButtonXlsx";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Loading from "components/Loading";
import Card from "components/Card/Card";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-modern-calendar-datepicker";
import Grid from "@material-ui/core/Grid";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import moment from "jalali-moment";
import { Box } from "@mui/material";

function MissedCalls24Hours(props) {
  const [days, setDays] = useState(1);
  const [chartData, setChartData] = useState([]);
  const [filterMode, setFilterMode] = useState("0");
  const [loading, setLoading] = useState(false);
  const [tickDate, setTickDate] = useState({});
  const [apiRequest, setApiRequest] = useState("inputcall-past");

  const token = localStorage.getItem("token");
  const daysOption = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ];
  const getMissedCall = async () => {
    try {
      setLoading(true);
      const date = moment(
        selectedDay.year + "-" + selectedDay.month + "-" + selectedDay.day + "-",
        "jYYYY-jM-jD"
      ).format("YYYY-MM-DD");
      const res = await axios.post(
        ApiUrl + `/inputcall-past-day-op/`,
        { date: date },
        { headers: { Authorization: token } }
      );
      convertData(res.data.data);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const convertData = (data) => {
    const arr = [];
    data.forEach((item, index) => {
      if (item.missed == false) {
        index++;
      } else {
        arr.push(item);
      }
    });
    const reduce = arr.reduce((total, current) => {
      let index = total.findIndex((value) => value.hour === current.hour);
      index === -1 ? total.push(current) : (total[index].hour += current.hour);
      return total;
    }, []);
    const sorted = reduce.sort((a, b) => {
      if (a.hour < b.hour) {
        return -1;
      }
      if (a.hour > b.hour) {
        return 1;
      }
      return 0;
    });
    //
    setChartData(sorted);
  };

  const selectDayHandler = (e) => {
    setDays(e.value);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 200,
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customLabel}>
          <h4>{`ساعت : ${label}`}</h4>
          <h4>{` تعداد تماس از دست رفته :${payload[0].value} `}</h4>
        </div>
      );
    }
    return <></>;
  };

  //date picker code
  const today = new Date().toLocaleDateString();

  const dateDefault = {
    year: parseInt(moment().format("jYYYY")),
    month: parseInt(moment().format("jM")),
    day: parseInt(moment().format("jD")),
  };
  const [selectedDay, setSelectedDay] = useState(dateDefault);
  useEffect(() => {
    getMissedCall();
  }, [selectedDay]);
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="زمان گزارش را انتخاب کنید"
      value={
        selectedDay.year && selectedDay.day && selectedDay.month
          ? "زمان گزارش : " + `${selectedDay.year}` + "/" + `${selectedDay.month}` + "/" + `${selectedDay.day}`
          : ""
      }
      style={{
        textAlign: "center",
        padding: "8px",
        fontSize: "1rem",
        border: "1px solid ",
        borderRadius: "5px",
        outline: "none",
        height: 40,
        minHeight: 35,
        width: 250,
      }}
      className="my-custom-input-class" // a styling class
    />
  );

  return (
    <Box>
      <h5 style={{ backgroundColor: "var(--primarySky)", padding: "10px", boxShadow: "0 0 2px rgba(0,0,0,.5)" }}>
        تماس های از دست رفته در 24 ساعت گذشته
      </h5>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/*<div className={styles.filters}>*/}
          {/*    {*/}
          {/*        !props.filterDisable &&*/}
          {/*        <div className={styles.filterWrapper}>*/}
          {/*            <div item xs={3} style={{marginTop: "32px", marginBottom: "32px"}}>*/}
          {/*                <DatePicker*/}
          {/*                    value={selectedDay}*/}
          {/*                    onChange={e => setSelectedDay(prev => ({*/}
          {/*                        day: e.day,*/}
          {/*                        month: e.month,*/}
          {/*                        year: e.year*/}
          {/*                    }))}*/}
          {/*                    renderInput={renderCustomInput}*/}
          {/*                    inputPlaceholder="زمان گزارش"*/}
          {/*                    locale={"fa"}*/}
          {/*                    shouldHighlightWeekends*/}
          {/*                    calendarPopperPosition={"bottom"}*/}
          {/*                    calendarPosition={"bottom"}*/}
          {/*                />*/}
          {/*            </div>*/}
          {/*            /!* <ButtonXlsx apiRequest={apiRequest} days={days}/> *!/*/}
          {/*        </div>*/}
          {/*    }*/}
          {/*</div>*/}
          <div className={styles.chart}>
            <ResponsiveContainer className={styles.rechartResponsiveContainer}>
              {!loading ? (
                <LineChart
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey={"hour"}
                    tickFormatter={(tick) => tick + ":00"}
                    orientation="bottom"
                    allowDuplicatedCategory={false}
                    type="category"
                    alignmentBaseline={"auto"}
                  />
                  <YAxis dataKey={"count"} orientation="left" />
                  <Tooltip cursor={{ stroke: "grey", strokeWidth: 2 }} content={<LabelCustom />} />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#9c27b0"
                    dot={{ stroke: "#9c27b0", strokeWidth: 1, r: 4, strokeDasharray: "" }}
                  />
                </LineChart>
              ) : (
                <Loading loading={loading} />
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Box>
  );
}

export default MissedCalls24Hours;
