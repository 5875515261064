import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Reset from "./../../assets/img/reset.png";

import "react-table/react-table.css";
import "./style.css";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading, history, pagination } = props;
  useEffect(() => {
    fetchMore();
  }, loading);
  useEffect(() => {
    props.store.dispatch(UserAction.GetOperators());
    props.store.dispatch(ReportAction.GetAllQueues());
  }, []);

  const fetchMore = (pagination = { per_page: 20, page: 1 }) => {
    //
    return props.store.dispatch(UserAction.GetAllHIstories(pagination));
  };

  /////
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    //
  }, props.operators);
  let handleChange = (newValue, actionMeta) => {
    setOperator(newValue);
  };
  const [operator, setOperator] = React.useState("");
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 45,
      maxWidth: 350,
      border: "1px solid black",
    }),
  };
  ////

  /////
  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");

  ////
  const [callStatus, setCallStatus] = React.useState("");
  let handleChangeCallStatus = (newValue, actionMeta) => {
    setCallStatus(newValue);
  };
  let callStatusoptions = [
    { value: "answer", label: "تماس های تصویری موفق" },
    { value: "calling", label: "تماس های تصویری بی پاسخ یا لغو شده" },
    { value: "rejected", label: "تماس های تصویری رد شده" },
    { value: "answer-chat", label: "تماس های متنی موفق" },
    { value: "calling-chat", label: "تماس های متنی بی پاسخ یا لغو شده" },
    { value: "rejected-chat", label: "تماس های متنی رد شده" },
  ];

  const classes = useStyles();

  ////
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  //

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 490,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );
  /////

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const fetchHistories = (pagination = { per_page: 20, page: 1 }) => {
    let arrayOperatorsId = [];
    if (operator && operator.length) {
      operator.forEach((item) => {
        //
        arrayOperatorsId.push(item.value);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let callStatusValues = [];
    if (callStatus) {
      callStatus.forEach((item) => {
        callStatusValues.push(item.value);
      });
    }
    props.store.dispatch(
      UserAction.GetInputCallsReports(
        callStatusValues,
        arrayOperatorsId,
        selectedDayRange,
        queuesId,
        pagination,
        timeStart,
        timeEnd
      )
    );
  };

  const [flag, setFlag] = React.useState("ALL");
  const [size, setSize] = React.useState(20);
  const [index, setindex] = React.useState(1);
  const onPageChangeFunction = (pageIndex) => {
    //
    if (flag === "ALL") {
      fetchMore({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "HISTORY") {
      fetchHistories({ per_page: size, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    //
    setSize(pageSize);
    if (flag === "ALL") {
      fetchMore({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "HISTORY") {
      fetchHistories({ per_page: pageSize, page: pageIndex + 1 });
    }
  };

  const getFileExcell = () => {
    let arrayOperatorsId = [];
    if (operator && operator.length) {
      operator.forEach((item) => {
        //
        arrayOperatorsId.push(item.value);
      });
    }

    let queuesId = [];
    if (queue && queue.length) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let callStatusValues = [];
    if (callStatus && callStatus.length) {
      callStatus.forEach((item) => {
        callStatusValues.push(item.value);
      });
    }
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }

    axios({
      // url: ApiUrl + "/reports-call-status-excell",
      url: ApiUrl + "/reports-call-status-excell",
      method: "post",
      params: {},
      data: {
        operators: arrayOperatorsId,
        dateFrom: dateG_from,
        dateTo: dateG_to,
        queuesId: queuesId,
        status: callStatusValues,
        timeStart,
        timeEnd,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  //////////RangePicker
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");

  function onChange(time, timeString) {
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }

  if (loading) return "loading";

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> تاریخچه اپراتور ها </title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <Grid container spacing={3}>
              <Grid
                item
                xs={5}
                style={{
                  marginTop: "24px",
                  marginBottom: "0px",
                  marginRight: "24px",
                }}
              >
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChange}
                    options={dataSelectOperator}
                    styles={customStyles}
                    defaultValue={operator}
                    placeholder="اپراتور مورد نظر را انتخاب کنید"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  marginTop: "24px",
                  marginBottom: "0px",
                  marginRight: "24px",
                  height: "45",
                }}
              >
                <DatePicker
                  value={selectedDayRange}
                  renderInput={renderCustomInput}
                  onChange={setSelectedDayRange}
                  inputPlaceholder="زمان گزارش"
                  shouldHighlightWeekends
                  locale="fa"
                  calendarPopperPosition={"bottom"}
                  calendarPosition={"bottom"}
                  calendarClassName={"datepicker-calldetails"}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  margin: "24px",
                  marginTop: "0px",
                  marginLeft: "0px",
                }}
              >
                <Box>
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeQueue}
                    options={dataSelectQueue}
                    styles={customStyles}
                    defaultValue={queue}
                    placeholder="صف مورد نظر خود را انتخاب کنید"
                  />
                </Box>
              </Grid>

              <Grid item xs={5} style={{ margin: "24px", marginTop: "0px" }}>
                <CreatableSelect
                  isClearable
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={handleChangeCallStatus}
                  options={callStatusoptions}
                  styles={customStyles}
                  defaultValue={callStatus}
                  placeholder="وضعیت اپراتور"
                />
              </Grid>
              <Grid item xs={5} style={{ margin: "24px", marginTop: "0px" }}>
                <RangePicker
                  onChange={onChange}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placeholder={["زمان آغاز", "زمان پایان"]}
                  defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                  defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  style={{
                    height: "60px",
                    width: "300px",
                    border: "1px solid black",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontSize: "17px",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  margin: "auto",
                  marginBottom: "24px",
                  marginTop: "0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    //
                    setFlag("HISTORY");
                    fetchHistories({ per_page: 20, page: 1 });
                  }}
                >
                  اعمال
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    //
                    fetchMore();
                    setFlag("ALL");
                  }}
                >
                  دریافت همه گزارشات
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  name="buttonname"
                  style={{ height: 55 }}
                  onClick={() => {
                    getFileExcell();
                  }}
                >
                  دریافت فایل
                </Button>
              </Grid>
            </Grid>
          </Card>
          <CardHeader className={classes.cardIconTitle} color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <ReactTable
              rtl
              data={props.historyCall ? props.historyCall : []}
              loading={props.loading}
              pagination={true}
              manual
              rowsText={"سطر"}
              pageText={"صفحه"}
              nextText={"بعدی"}
              previousText={"قبلی"}
              ofText={"از"}
              style={{ height: "500px" }}
              columns={[
                {
                  Header: "اپراتور",
                  accessor: "operator",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <div>
                      {row.original.operator
                        ? row.original.operator.first_name + " " + row.original.operator.last_name
                        : ""}
                    </div>
                  ),
                },

                {
                  Header: " زمان شروع",
                  accessor: "start",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  },
                },

                {
                  Header: "تاریخ شروع",
                  accessor: "start",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  },
                },

                {
                  Header: "زمان پاسخ",
                  accessor: "answer",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  },
                },

                {
                  Header: "تاریخ پاسخ",
                  accessor: "answer",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  },
                },

                {
                  Header: "زمان پایان",
                  accessor: "end",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("HH:mm:ss")}
                        </div>
                      );
                    } else {
                      return "0";
                    }
                  },
                },

                {
                  Header: " تاریخ پایان",
                  accessor: "end",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.value) {
                      return (
                        <div>
                          {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD") === "Invalid date"
                            ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD")
                            : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD")}
                        </div>
                      );
                    } else {
                      return "0";
                    }
                  },
                },
                {
                  Header: "طول مکالمه",
                  accessor: "courseTime",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.original) {
                      let answerField = row.original.answer;
                      let endField = row.original.end;
                      let courseTime = 0;
                      if (!answerField) {
                        return courseTime;
                      }
                      let start;
                      let end;
                      if (
                        moment(endField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss") === "Invalid date"
                      ) {
                        end = new Date(endField).getTime();
                        start = new Date(answerField).getTime();
                      } else {
                        let endFormat = moment(endField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
                        let startFormat = moment(answerField, "YYYY-MM-DDTHH:mm:ss.sTZD").format(
                          "jYYYY/jM/jD HH:mm:ss"
                        );
                        end = new Date(endFormat).getTime();
                        start = new Date(startFormat).getTime();
                      }
                      let min = Math.abs((end - start) / (1000 * 60)).toFixed(2);
                      let minRound = Math.abs((end - start) / (1000 * 60)).toFixed(0);
                      let second = Math.abs((end - start) / 1000);
                      let n = min.indexOf(".");
                      let ss = (min.slice(n, n + 3) * 60).toFixed(0);
                      //
                      if (min === "NaN") {
                        courseTime = 0;
                      } else if (min > 1) {
                        courseTime = minRound + ":" + ss + " " + "دقیقه";
                      } else {
                        courseTime = second + " " + "ثانیه";
                      }
                      return courseTime;
                    }
                  },
                },
                {
                  Header: "زمان انتظار",
                  accessor: "waitingTime",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    if (row.original) {
                      let answerField = row.original.answer;
                      let startField = row.original.start;
                      let waitingTime = 0;
                      if (!answerField) {
                        return waitingTime;
                      }
                      let start;
                      let answer;
                      let answerFormat = moment(answerField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
                      let startFormat = moment(startField, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss");
                      answer = new Date(answerFormat).getTime();
                      start = new Date(startFormat).getTime();
                      let min = Math.abs((answer - start) / (1000 * 60)).toFixed(2);
                      let san = Math.abs(answer - start) / 1000;
                      //
                      //   answerFormat,
                      //   startFormat,
                      //   answer,
                      //   start,
                      //   san,
                      //   min,
                      //   "answerFormat , startFormat ,answer ,start,san ,min"
                      // );
                      let minRound = Math.abs((answer - start) / (1000 * 60)).toFixed(0);
                      let second = Math.abs((answer - start) / 1000);
                      let n = min.indexOf(".");
                      let ss = (min.slice(n, n + 3) * 60).toFixed(0);
                      //
                      if (min === "NaN") {
                        waitingTime = 0;
                      } else if (min > 1) {
                        waitingTime = minRound + ":" + ss + " " + "دقیقه";
                      } else {
                        waitingTime = second + " " + "ثانیه";
                      }
                      return waitingTime;
                    }
                  },
                },
                {
                  Header: "صف",
                  accessor: "menu.name",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "وضعیت",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <div>
                      {row.value == "calling"
                        ? "عدم پاسخ"
                        : row.value == "answer"
                        ? "پاسخ داده شده"
                        : row.value == "rejected"
                        ? "رد شده"
                        : row.value == "calling-chat"
                        ? "عدم پاسخ"
                        : row.value == "answer-chat"
                        ? "پاسخ داده شده"
                        : row.value == "rejected-chat"
                        ? "رد شده"
                        : row.value == "diverted"
                        ? "منتقل شده"
                        : ""}
                    </div>
                  ),
                },
                {
                  Header: "شماره پیگیری ",
                  accessor: "tracking_number",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "نوع تماس",
                  accessor: "status",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    let s = row.value && row.value.search("chat").toString();
                    return <div>{s === "-1" ? "تصویری" : "متنی"}</div>;
                  },
                },
                {
                  Header: "توضیحات",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <div>
                      <a href={"/admin/history/" + row.value} rel="noopener noreferrer">
                        مشاهده جزییات
                      </a>
                    </div>
                  ),
                },
              ]}
              pageSize={props.pagination && props.pagination.size}
              page={props.pagination && props.pagination.page}
              pages={props.pagination && props.pagination.total}
              onPageChange={(pageIndex) => {
                //
                onPageChangeFunction(pageIndex);
              }}
              onPageSizeChange={(pageSize, pageIndex) => {
                //
                onPageSizeChangeFunction(pageSize, pageIndex);
              }}
              onFilteredChange={(column, value) => {
                //
              }}
              className="-striped -highlight"
              showPaginationTop={false}
              showPaginationBottom={true}
            />
          </CardBody>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  //
  return {
    historyCall: state.user.history,
    pagination: state.user.pagination_call,
    loading: state.user.loading,
    error: state.user.error,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};
const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
