import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
import { ApiUrl, Url } from "config";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CreatableSelect from "react-select/creatable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "../../Users/Users";
import ReactTable from "react-table";
import ReportAction from "../../../js/actions/report_action";
import { bindActionCreators } from "redux";
import UserAction from "../../../js/actions/user_action";
import { connect } from "react-redux";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles((theme) => ({
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
  //check box style
  root: {
    display: "block",
    marginTop: "20px",
  },
  formControl: {
    width: "49%",
    color: "black",
  },
  addAccessContainer: {
    margin: "10px 0 0 0",
    display: "flex",
    alignItems: "center",
  },
  accessInput: (props) => ({
    border: "1px solid var(--pink)",
    borderRadius: "5px",
    padding: "10px 10px",
    width: props.inputWidth,
    opacity: props.opacity,
    transition: "1s",
  }),
}));
const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 55,
    width: "50%",
    border: "1px solid black",
  }),
};

function Accessibility(props) {
  const [styleProps, setStyleProps] = useState({
    inputWidth: "0",
    opacity: 0,
  });

  const classes = useStyles(styleProps);
  //set level
  const token = localStorage.getItem("token");
  const [rolls, setRolls] = useState([]);
  const [level, setLevel] = React.useState("");
  const [levelOperator, setLevelOperator] = useState("");
  const [accessGroup, setAccessGroup] = useState("");
  const [dataSelectLevel, setDataSelectLevel] = React.useState([]);
  const [alert, setAlert] = useState("");
  const defaultCheckBox = {
    reports: false,
    callDetails: false,
    inputCall: false,
    chartCalls: false,
    operatorsPoll: false,
    reportEnterExit: false,
    textBoxReport: false,
    mediaBoxReport: false,
    textBoxMessages: false,
    transferCall: false,
    outCall: false,
    reportMidCalls: false,
    clientsReport: false,
    option: false,
    roomOperator: false,
    reportOperator: false,
    history: false,
    customers: false,
    customersList: false,
    addCustomers: false,
    queue: false,
    queuesList: false,
    addQueue: false,
    form: false,
    productSlider: false,
    slider: false,
    blog: false,
    faq: false,
  };
  const [checkBox, setCheck] = React.useState(defaultCheckBox);
  const getRolls = async () => {
    try {
      const res = await axios.get(ApiUrl + "/rolls", { headers: { Authorization: token } });
      setRolls(res.data.data.data);
      let options = [];
      res.data.data.data.forEach((item) => {
        options.push({
          value: item.name,
          label: item.name,
          roll: item.roll,
        });
      });
      setDataSelectLevel(options);
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line no-console
  };

  const showRolls = () => {
    rolls.forEach((item) => {});
    // setCheck({...checkBox,})
  };
  useEffect(() => {
    showRolls();
  }, [level]);

  useEffect(() => {
    getRolls();
    let data = [
      {
        name: "سطح 1",
        _id: "level",
      },
      {
        name: "سطح 2",
        _id: "level2",
      },
      {
        name: "سطح 3",
        _id: "level3",
      },
    ];
  }, []);

  let handleChangeLevel = (newValue) => {
    setLevel(newValue);
    setCheck(defaultCheckBox);
    newValue &&
      newValue.roll.forEach((item) => {
        setCheck((checkBox) => ({ ...checkBox, [item.charAt(0).toLowerCase() + item.slice(1)]: true }));
      });
  };
  //checkbox

  const handleChange = (event) => {
    setCheck({ ...checkBox, [event.target.name]: event.target.checked });
  };

  const handleRoll = (event) => {
    let trues = Object.keys(checkBox).filter((n) => checkBox[n]);
    if (
      trues.includes("callDetails") ||
      trues.includes("textBoxReport") ||
      trues.includes("transferCall") ||
      trues.includes("textBoxMessages") ||
      trues.includes("reportMidCalls") ||
      trues.includes("reportEnterExit") ||
      trues.includes("outCall") ||
      trues.includes("chartCalls") ||
      trues.includes("clientsReport") ||
      trues.includes("inputCall") ||
      trues.includes("mediaBoxReport") ||
      trues.includes("operatorsPoll")
    ) {
      trues.push("reports");
    }
    if (trues.includes("addCustomers") || trues.includes("customersList")) {
      trues.push("customers");
    }
    if (trues.includes("addQueue") || trues.includes("queuesList")) {
      trues.push("queue");
    }
    axios({
      url: ApiUrl + "/add-roll",
      method: "post",
      params: {},
      data: {
        name: accessGroup ? accessGroup : level.value,
        roll: trues,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        //

        toast.success(" دسترسی با موفقیت داده شد", {
          position: toast.POSITION.TOP_CENTER,
        });
        getRolls();
      })
      .catch((error) => {
        //
        toast.error(" دسترسی نا موفق بود", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const inputWidthHandle = () => {
    setAccessGroup("");
    if (styleProps.inputWidth === "300px") {
      return setStyleProps((value) => ({ inputWidth: "0", opacity: 0 }));
    } else {
      return setStyleProps((value) => ({ inputWidth: "300px", opacity: 1 }));
    }
  };
  //users code
  const { loading, data } = props;

  const getAllUsers = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(UserAction.GetAllUsers(pagination));
  };
  useEffect(() => {
    getAllUsers();
    props.store.dispatch(ReportAction.GetAllQueues());
  }, loading);
  const onPageChangeFunction = (pageIndex) => {
    getAllUsers({ per_page: 20, page: pageIndex + 1 });
  };

  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    getAllUsers({ per_page: pageSize, page: pageIndex + 1 });
  };
  const customStylesSelect = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
      overflow: "hidden",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: "100%",
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  const changeAccess = (id, value) => {
    {
      axios({
        url: ApiUrl + "/update-oprator-roll/",
        method: "post",
        params: {},
        data: {
          id: id,
          roll: value,
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      })
        .then(function (response) {
          toast.success("سطح دسترسی با موفقیت تغییر کرد");
        })
        .catch((error) => {
          toast.error("خطا در تغییر سطح دسترسی");
        });
    }
  };
  const accessHandler = (id) => {
    setAlert(
      <SweetAlert
        showConfirm={false}
        cancelBtnText={"بستن"}
        showCancel={true}
        onCancel={(e) => setAlert("")}
        style={{ overflow: "hidden", height: "400px" }}
      >
        <Select
          styles={customStylesSelect}
          onChange={(e) => changeAccess(id, e.value)}
          options={dataSelectLevel}
          placeholder={"تعیین سطح دسترسی"}
        />
      </SweetAlert>
    );
  };
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title> تعیین سطح دسترسی</title>
      </Helmet>
      <Card>
        <CardHeader className={classes.cardIconTitle} color="primary" icon>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>تعیین سطح دسترسی</h4>
        </CardHeader>
        <CardBody>
          <Box>
            <CreatableSelect
              closeMenuOnSelect={false}
              onChange={handleChangeLevel}
              isDisabled={accessGroup && true}
              isClearable={true}
              options={dataSelectLevel}
              styles={customStyles}
              defaultValue={level}
              placeholder="سطح دسترسی مورد نظر خود را انتخاب کنید"
            />
          </Box>
          <div className={classes.addAccessContainer}>
            <Button color={"rose"} onClick={inputWidthHandle}>
              {styleProps.opacity === 1 ? "بستن" : "ساخت گروه"}
            </Button>
            <input
              className={classes.accessInput}
              type="text"
              name="access-name"
              id="access-name"
              placeholder={"نام گروه:"}
              value={accessGroup}
              onChange={(e) => setAccessGroup(e.target.value)}
            />
          </div>
          <FormGroup className={classes.root}>
            {/* <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.reports} onChange={handleChange} name="reports" color="primary" />}
              label="گزارشات"
            /> */}
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.callDetails} onChange={handleChange} name="callDetails" color="primary" />
              }
              label="تاریخچه اپراتور ها"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.inputCall} onChange={handleChange} name="inputCall" color="primary" />
              }
              label="تماس های ورودی"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.chartCalls} onChange={handleChange} name="chartCalls" color="primary" />
              }
              label="نمودار اپراتور ها"
            />

            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.operatorsPoll}
                  onChange={handleChange}
                  name="operatorsPoll"
                  color="primary"
                />
              }
              label="گزارشات نظرسنجی"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.reportEnterExit}
                  onChange={handleChange}
                  name="reportEnterExit"
                  color="primary"
                />
              }
              label="گزارش ورود و خروج از صف"
            />
            {/* <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.textBoxReport}
                  onChange={handleChange}
                  name="textBoxReport"
                  color="primary"
                />
              }
              label="گزارش صندوق متنی"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.mediaBoxReport}
                  onChange={handleChange}
                  name="mediaBoxReport"
                  color="primary"
                />
              }
              label="گزارش صندوق تصویری"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.textBoxMessages}
                  onChange={handleChange}
                  name="textBoxMessages"
                  color="primary"
                />
              }
              label="پیام های ارسالی"
            /> */}
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.transferCall} onChange={handleChange} name="transferCall" color="primary" />
              }
              label="گزارش انتقال تماس"
            />

            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.outCall} onChange={handleChange} name="outCall" color="primary" />}
              label="گزارش تماس خروجی"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.reportMidCalls}
                  onChange={handleChange}
                  name="reportMidCalls"
                  color="primary"
                />
              }
              label="گزارش اپراتور ها"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.clientsReport}
                  onChange={handleChange}
                  name="clientsReport"
                  color="primary"
                />
              }
              label="گزارش کاربران"
            />

            {/* <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.customers} onChange={handleChange} name="customers" color="primary" />
              }
              label="کاربران"
            /> */}
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.customersList}
                  onChange={handleChange}
                  name="customersList"
                  color="primary"
                />
              }
              label="لیست کاربران"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.addCustomers} onChange={handleChange} name="addCustomers" color="primary" />
              }
              label="افزودن کاربر"
            />
            {/* <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.queue} onChange={handleChange} name="queue" color="primary" />}
              label="صف ها"
            /> */}
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.queuesList} onChange={handleChange} name="queuesList" color="primary" />
              }
              label="لیست صف ها"
            />
            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.addQueue} onChange={handleChange} name="addQueue" color="primary" />}
              label="افزودن صف"
            />
            {/* <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox checked={checkBox.appointment} onChange={handleChange} name="appointment" color="primary" />
              }
              label="نوبت دهی"
            /> */}
            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.form} onChange={handleChange} name="form" color="primary" />}
              label="فرم ها"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Checkbox
                  checked={checkBox.productSlider}
                  onChange={handleChange}
                  name="productSlider"
                  color="primary"
                />
              }
              label="اسلایدر محصول"
            />
            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.slider} onChange={handleChange} name="slider" color="primary" />}
              label="اسلایدر"
            />
            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.blog} onChange={handleChange} name="blog" color="primary" />}
              label="خبرنامه"
            />
            <FormControlLabel
              className={classes.formControl}
              control={<Checkbox checked={checkBox.faq} onChange={handleChange} name="faq" color="primary" />}
              label="سوالات متداول"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            name="buttonname"
            style={{ height: 40, display: "block", margin: "20px auto" }}
            onClick={() => {
              handleRoll();
            }}
          >
            ثبت
          </Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardIcon color="primary">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>سطح دسترسی اپراتور ها</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            rtl
            pagination={true}
            getTableProps={() => ({
              style: { display: "block", textAlign: "right" },
            })}
            data={data ? data.users : []}
            loading={loading}
            filterable
            manual
            columns={[
              {
                Header: "نام کاربری دیدار",
                accessor: "username",
                sortable: false,
                filterable: false,
              },
              {
                Header: "نام",
                accessor: "first_name",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <div className="actions-right">{row.original.first_name + " " + row.original.last_name}</div>
                ),
              },
              {
                Header: "نقش",
                accessor: "roles",
                sortable: false,
                filterable: false,
                Cell: (row) => <div className="actions-right">{row.original.roll}</div>,
              },
              // {
              //     Header: "ایمیل",
              //     accessor: "email",
              //     sortable: false,
              //     filterable: false,
              // },
              {
                Header: "کد ملی",
                accessor: "national_code",
                sortable: false,
                filterable: false,
              },
              {
                Header: "تلفن همراه",
                accessor: "mobile",
                sortable: false,
                filterable: false,
              },
              // {
              //     Header: "بخش های مربوطه",
              //     accessor: "expertList",
              //     sortable: false,
              //     filterable: false,
              //     Cell: (row) => (
              //         <div className="actions-right">
              //             {row.value.map((e, i) => {
              //                 return (
              //                     <div className={"parts"} key={i}>
              //                         <img style={{ width: 25 }} src={Url + e.icon} />
              //                         <span>{e.name}</span>
              //                     </div>
              //                 );
              //             })}
              //         </div>
              //     ),
              // },
              {
                Header: "نوع عضو",
                accessor: "type",
                sortable: false,
                filterable: false,
                Cell: (row) => <div className="actions-right">{row.value !== "admin" ? "اپراتور" : "مدیر سیستم"}</div>,
              },
              {
                Header: "عملیات",
                accessor: "_id",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <div className="actions-right">
                    <a href={"#"} onClick={(e) => accessHandler(row.value)}>
                      تعیین سطح دسترسی
                    </a>
                  </div>
                ),
              },
            ]}
            showPaginationTop={false}
            pageSize={props.pagination && props.pagination.size}
            page={props.pagination && props.pagination.page}
            pages={props.pagination && props.pagination.total}
            onPageChange={(pageIndex) => {
              onPageChangeFunction(pageIndex);
            }}
            onPageSizeChange={(pageSize, pageIndex) => {
              onPageSizeChangeFunction(pageSize, pageIndex);
            }}
            onFilteredChange={(column, value) => {}}
            showPaginationBottom={true}
            className="-striped -highlight"
          />
        </CardBody>
      </Card>
      {alert}
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  pagination: state.user.pagination_users,
  loading: state.user.loading,
  error: state.user.error,
  queues: state.reports_2.queues,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Accessibility);
