const Url = process.env.REACT_APP_URL;

export function sendRequest(type, data, socket) {
  return new Promise((resolve, reject) => {
    socket.emit(type, data, (err, response) => {
      if (!err) {
        resolve(response);
      } else {
        reject(err);
      }
    });
  });
}

export const ApiUrl = Url + "api";

export const ApiUrlConference = "https://185.60.33.29/";
export const ConfUrl = "https://room.hamichat.pep.co.ir/";
export const appVersion = "1.1.50 alpha";
export const saniarMenuId = "5edb6a4002543350a3ad78d0";
export const avandMenuId = "6467b50418fbe754fa4c0bf8";
export const cardMenuId = "5edb6a4002543350a3ad78d0";
export { Url };
