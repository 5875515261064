import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@mui/material";
import {
  AttachFile,
  CallEnd,
  CallEndOutlined,
  ChatBubble,
  ChatBubbleOutline,
  CloseOutlined,
  DoneAll,
  EditOutlined,
  MicOffOutlined,
  MicOutlined,
  MoreHoriz,
  MoreHorizOutlined,
  RadioButtonChecked,
  Send,
  TimelapseOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@material-ui/icons";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { useRecoilState } from "recoil";
import { LinkMessage, Message, MessageDelivered, MessageList, MessageSended, Socketid } from "../../layouts/Recoil";
import Check from "@material-ui/icons/Check";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100vh",
    direction: "rtl",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
  },
  meContainer: {
    height: "150px",
    minHeight: "100px",
    padding: "10px",
    overflow: "hidden",
  },
  otherContainer: {
    height: "300px",
    minHeight: "300px",
    padding: "15px",
    overflow: "hidden",
  },
  buttonsContainer: {
    width: "100%",
    padding: "10px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    overflow: "hidden",
  },
  otherIcons: {
    backgroundColor: "var(--swanWhite)",
    borderRadius: "50%",
    padding: "5px",
    fontSize: "35px",
    cursor: "pointer",
  },
  moreIcon: {
    padding: "5px",
    backgroundColor: "var(--black)",
    fontSize: "40px",
    width: "40px",
    color: "var(--white)",
    borderRadius: "50%",
    cursor: "pointer",
  },
  endIcon: {
    padding: "5px 15px",
    backgroundColor: "red",
    fontSize: "40px",
    color: "var(--white)",
    width: "70px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  meVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "fill",
  },
  otherVideo: {
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    objectFit: "fill",
  },
  popup: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,.3)",
    position: "absolute",
    top: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },

  chatContainer: {
    minHeight: "500px",
    overflow: "hidden",
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    backgroundColor: "#d1d8e0",
  },
  chatHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    overflow: "hidden",
    position: "relative",
  },
  chatMessages: {
    width: "100%",
    height: "300px",
    maxHeight: "300px",
    display: "flex",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  chatFooter: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  screenVideo: {
    height: "200px",
    borderRadius: "20px",
    position: "absolute",
    top: "10px",
    left: "10px",
    objectFit: "fill",
    border: "2px solid var(--white)",
  },
  textArea: {
    border: "none",
    resize: "none",
    width: "100%",
    direction: "rtl",
    textAlign: "right",
    padding: "10px",
  },
  input: {
    border: "1px solid var(--gray)",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    borderRadius: "10px",
    margin: "0 10px",
    backgroundColor: "var(--white)",
    direction: "ltr",
  },
  icons: {
    margin: "0 5px",
    backgroundColor: "var(--darkGreen)",
    padding: "10px",
    borderRadius: "10px",
    fontSize: "40px",
    color: "var(--white)",
  },
  message: {
    backgroundColor: "var(--lightCeladon)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "right",
    margin: "5px",
    overflow: "hidden",
    wordBreak: "break-word",
    textAlign: "right",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid var(--white)",
      borderRight: "10px solid transparent",
      borderTop: "10px solid var(--white)",
      borderBottom: "10px solid transparent",
      right: "-19px",
      top: "0px",
    },
  },
  messageOperator: {
    backgroundColor: "var(--white)",
    padding: "5px 10px",
    borderRadius: "10px",
    float: "left",
    margin: "5px",
    "&:before": {
      content: "",
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid var(--gray)",
      borderTop: "10px solid var(--gray)",
      borderBottom: "10px solid transparent",
      left: "-19px",
      top: "0px",
    },
  },
  notify: (props) => ({
    position: "absolute",
    top: "-5px",
    right: "-5px",
    color: "var(--white)",
    backgroundColor: "var(--chiliRed)",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    animation: props.newMessage != 0 ? `$bounce2 1s infinite` : "unset",
  }),
  "@keyframes bounce2": {
    "0%, 20%, 50%, 80%, 100%": { transform: "translateY(0)" },
    "40%": { transform: " translateY(-10px)" },
    "60%": { transform: "translateY(-5px)" },
  },
});

function VideoBoxCalling({
  isTyping,
  setIsTyping,
  otherTyping,
  onTypingMessage,
  uploadEvent,
  setUploadEvent,
  setFlagSend,
  localMedia,
  endCall,
  track,
  isShare,
  operator = false,
  children,
  sendMessage,
  shareScreen,
  local_screen_stream,
  setIsShare,
  roomId,
  stopDisplay,
  onKeyDown,
  setEditMessage,
}) {
  const [muted, setMuted] = useState(false);
  const [camOff, setCamOff] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [message, setMessageForm] = useRecoilState(Message);
  const [messagList, setMessagList] = useRecoilState(MessageList);
  const [socktid, setSocktid] = useRecoilState(Socketid);
  const [linkMessag, setLinkMessag] = useRecoilState(LinkMessage);
  const [messageSended, setMessageSended] = useRecoilState(MessageSended);
  const [messageDelivered, setMessageDelivered] = useRecoilState(MessageDelivered);
  const [newMessage, setNewMessage] = useState(0);
  const [editMessageInput, setEditMessageInput] = useState({
    message: "",
    msg_id: "",
    is_delete: false,
  });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const classes = useStyles({ newMessage });
  const srcMe = useRef();
  const srcOther = useRef();
  const userId = localStorage.getItem("data_nationalcode");
  const operatorId = localStorage.getItem("_id");

  const checkOperatorUser = (item) => {
    if (userId && item.national_code === userId && !operator && item.type === "user") {
      return true;
    } else if (operatorId && item.operator_id === operatorId && operator && item.type !== "user") {
      return true;
    } else {
      return false;
    }
  };
  const deleteMessageHandler = (msg) => {
    const obj = {
      ...msg,
      is_delete: true,
    };
    setEditMessage(obj);
    setDeleteOpen(false);
  };
  const editMessageHandler = () => {
    const obj = {
      ...editMessageInput,
      msg_id: editMessageInput.msg_id,
      message: editMessageInput.message,
    };
    setEditMessage(obj);
    setEditOpen(false);
  };
  useEffect(() => {
    if (localMedia) localMedia.getAudioTracks()[0].enabled = !muted;
    if (localMedia) localMedia.getVideoTracks()[0].enabled = !camOff;
  }, [camOff, muted]);
  const tabHandleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const chatRef = useRef(null);

  useEffect(() => {
    messagList.map((item, index) => {
      !socktid[index] && setNewMessage(newMessage + 1);
    });
  }, [messagList]);
  useEffect(() => {
    if (typeof uploadEvent !== "undefined") {
      setMessageForm(uploadEvent.name);
    }
  }, [uploadEvent]);
  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [messagList]);
  const deliverHandler = (index, item) => {
    // eslint-disable-next-line no-prototype-builtins
    let deliveryIndex = messageDelivered.findIndex((value) => value.msg_id === item.msg_id);
    if (messageDelivered) {
      return <DoneAll className={classes.checkIcon} />;
      // eslint-disable-next-line no-prototype-builtins
    } else if (messageSended) {
      return <Check className={classes.checkIcon} />;
    } else {
      return <TimelapseOutlined className={classes.checkIcon} />;
    }
  };
  useEffect(() => {
    if (localMedia) return (srcMe.current.srcObject = localMedia);
  }, [localMedia]);
  useEffect(() => {
    if (track) {
      const stream = new MediaStream([track]);
      if (srcOther.current.srcObject) {
        return srcOther.current.srcObject.addTrack(track);
      } else {
        srcOther.current.srcObject = stream;
        srcOther.current.play();
      }
    }
  }, [track]);
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.otherContainer}>
          <video ref={srcOther} className={classes.otherVideo} autoPlay={true} playsInline={true} />
        </div>
        <div className={classes.meContainer}>
          <video ref={srcMe} className={classes.meVideo} muted={true} autoPlay={true} />
        </div>

        <div className={classes.buttonsContainer}>
          {muted ? (
            <MicOffOutlined
              className={classes.otherIcons}
              style={{ backgroundColor: "var(--chiliRed)" }}
              onClick={(e) => setMuted(!muted)}
            />
          ) : (
            <MicOutlined className={classes.otherIcons} onClick={(e) => setMuted(!muted)} />
          )}
          {camOff ? (
            <VideocamOffOutlined
              className={classes.otherIcons}
              style={{ backgroundColor: "var(--chiliRed)" }}
              onClick={(e) => setCamOff(!camOff)}
            />
          ) : (
            <VideocamOutlined className={classes.otherIcons} onClick={(e) => setCamOff(!camOff)} />
          )}

          <div style={{ position: "relative", display: "flex" }}>
            <p className={classes.notify}>{newMessage}</p>
            <ChatBubble
              onClick={(e) => {
                setShowPopup(!showPopup);
                setNewMessage(0);
              }}
              className={classes.otherIcons}
            />
          </div>
          <CallEndOutlined className={classes.endIcon} onClick={endCall} />
        </div>
      </div>
      {showPopup && (
        <div className={classes.popup}>
          <div className={classes.chatContainer}>
            <div className={classes.chatHeader}>
              <CloseOutlined
                style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPopup(false);
                  setNewMessage(0);
                }}
              />
              <TabContext value={tabValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  width={"100%"}
                >
                  <TabList
                    variant={"standard"}
                    onChange={tabHandleChange}
                    aria-label="lab API tabs example"
                    sx={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
                  >
                    <Tab
                      style={{
                        margin: "0 5px",
                        color: "black",
                        borderRadius: "10px",
                      }}
                      label={"پیام"}
                      value={"1"}
                    />
                    {operator && (
                      <Tab
                        style={{
                          margin: "0 5px",
                          color: "black",
                          borderRadius: "10px",
                        }}
                        label={"تشخیص اپراتور"}
                        value={"2"}
                      />
                    )}
                    {/* {operator && (
                      <Tab
                        style={{
                          margin: "0 5px",
                          color: "black",
                          borderRadius: "10px",
                        }}
                        label={"مشترک"}
                        value={"3"}
                      />
                    )} */}
                  </TabList>
                </Box>
              </TabContext>
            </div>
            <div className={classes.chatMessages}>
              <TabContext value={tabValue}>
                <TabPanel
                  ref={chatRef}
                  value={"1"}
                  sx={{
                    width: "100%",
                    height: "300px",
                    display: "block",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  {otherTyping && <p>در حال نوشتن...</p>}
                  {messagList.length !== 0 &&
                    messagList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "100%",
                            display: "inline-block",
                            verticalAlign: "top",
                            position: "relative",
                          }}
                        >
                          <div
                            className={checkOperatorUser(item) ? classes.message : classes.messageOperator}
                            style={{
                              float: checkOperatorUser(item) ? "right" : "left",
                              backgroundColor: !checkOperatorUser(item) ? "var(--white)" : "var(--lightCeladon)",
                            }}
                          >
                            {item.file ? (
                              <a target="_blank" href={item.message} rel="noopener noreferrer">
                                جهت دانلود فایل کلیک کنید
                              </a>
                            ) : (
                              <div>
                                <p>{item.message}</p>
                                {checkOperatorUser(item) && deliverHandler(index, item)}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </TabPanel>
              </TabContext>
            </div>
            <div className={classes.chatFooter}>
              <div className={classes.input}>
                <Send className={classes.icons} onClick={sendMessage} style={{ transform: "rotate(180deg)" }} />
                <textarea
                  onKeyDown={onKeyDown}
                  rows={2}
                  value={message}
                  onChange={(e) => {
                    e.preventDefault();
                    setMessageForm(e.target.value);
                    typeof onTypingMessage !== "undefined" && onTypingMessage();
                  }}
                  placeholder={"پیام خود را وارد کنید..."}
                  className={classes.textArea}
                />
                <Button component={"label"} sx={{ backgroundColor: "transparent", marginRight: "5px" }}>
                  <input
                    type="file"
                    hidden={true}
                    onChange={(e) => {
                      setFlagSend(false);
                      setUploadEvent(e.target.files[0]);
                    }}
                  />
                  <AttachFile className={classes.icons} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoBoxCalling;
