import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl, Url } from "../../config";
import UserAction from "../../js/actions/user_action";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};
const useStylesAlert = makeStyles(stylesAlert);

const useStyles = makeStyles(styles);

function Users(props) {
  const classesAlert = useStylesAlert();
  const getUser = () => {
    let id = props.match.params.id;

    axios
      .get(ApiUrl + "/get-user/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setData({
          email: response.data.data.user.email,
          first_name: response.data.data.user.first_name,
          last_name: response.data.data.user.last_name,
          national_code: response.data.data.user.national_code,
          mobile: response.data.data.user.mobile,
        });
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const [data, setData] = useState({
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    national_code: "",
    mobile: "",
  });
  const [menus, setMenus] = useState([]);
  const getUsers = () => {
    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setMenus(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  useEffect(() => {
    getUsers();
    getUser();
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    let id = props.match.params.id;

    props.store.dispatch(UserAction.Edituser(data, id)).then(function (ec) {
      window.location.href = "/admin/users";
    });
  }

  function handle_change(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  const [password, setPassword] = useState(null);
  const [alert, setAlert] = useState(null);

  const updatePassword = (e) => {
    let id = props.match.params.id;
    axios({
      url: ApiUrl + "/update-password",
      method: "post",
      params: {},
      data: {
        password: password,
        id: id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="عملیات موفق"
            onConfirm={() => setAlert(false)}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      })
      .catch(function (error) {
        //
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="عملیات ناموفق"
            onConfirm={() => setAlert(false)}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.danger}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      })
      .finally(function () {
        //
      });
  };

  const updateLogin = (e) => {
    let id = props.match.params.id;
    axios({
      url: ApiUrl + "/logout",
      method: "post",
      params: {},
      data: {
        id: id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        //
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="عملیات موفق"
            onConfirm={() => setAlert(false)}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      })
      .catch(function (error) {
        //
        setAlert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px" }}
            title="عملیات ناموفق"
            onConfirm={() => setAlert(false)}
            confirmBtnCssClass={classesAlert.button + " " + classesAlert.danger}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
      })
      .finally(function () {
        //
      });
  };

  function handle_change_menu(e) {
    var options = e.target.options;
    var value = [];
    setData({
      ...data,
      menu: [],
    });
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setData({
      ...data,
      menu: value,
    });
  }

  const classes = useStyles();

  if (props.loading) return <div>...در حال ارسال</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ویرایش عضو - {pageTitle}</title>
      </Helmet>
      {alert}
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle} color={"var(--textColor)"}>
                    ویرایش عضو جدید
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>نام کاربری دیدار</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "username",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.username,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>ایمیل</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "email",
                          name: "email",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.email,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>تلفن همراه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "mobile",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.mobile,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نام</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "first_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.first_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>نام خانوادگی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "last_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.last_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>کد ملی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "national_code",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.national_code,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <FormLabel className={classes.labelHorizontal}>بخش های مربوطه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <select
                        onChange={(e) => {
                          handle_change_menu(e);
                        }}
                        name="menu"
                        className={"menus"}
                        multiple
                      >
                        {menus.map((e, i) => {
                          return (
                            <option style={{ background: `url(${Url + e.icon}) no-repeat` }} value={e._id} key={i}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"submit"} color="rose">
                  ذخیره
                </Button>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle} color={"var(--textColor)"}>
                    ویرایش رمز عبور{" "}
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>رمز عبور</FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      // success={requiredState === "success"}
                      // error={requiredState === "error"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "password",
                        onChange: (e) => {
                          setPassword(e.target.value);
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"button"} onClick={() => updatePassword()} color="rose">
                  تغییر رمز عبور
                </Button>
                <Button type={"button"} onClick={() => updateLogin()} color="success">
                  ایجاد امکان ورود مجدد
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
