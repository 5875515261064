import React, { useState } from "react";
import { Btn } from "../share/Btn/Btn";
import DetailBody from "./DetailBody";
import DetailChat from "./DetailChat";
import DetailCostomer from "./DetailCostomer";
import DetailProblem from "./DetailProblem";

function Detail({
  sendMessage,
  customer,
  confirmVip,
  userMobile,
  userNationalCode,
  loading,
  sbmitCustomerProblem,
  setFlagSend,
  setUploadEvent,
  uploadEvent,
  user,
  otherTyping,
  onTypingMessage,
  socketIo,
  onKeyDown,
  setEditMessage,
  editMessageInput,
  setEditMessageInput,
}) {
  const [tab, setTab] = useState(1);
  const [commentInfo, setCommentInfo] = useState(null);
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };
  return (
    <div className="Detail relative">
      <div className="d-flex align-center justify-between Detail__tabs">
        <Btn onClick={() => setTab(1)} className={tab === 1 ? "Detail__btnActive" : "Detail__btnDisctive"}>
          پیام
        </Btn>

        {/* {!user && (
          <Btn onClick={() => setTab(3)} className={tab === 3 ? "Detail__btnActive" : "Detail__btnDisctive"}>
            تشخیص اپراتور
          </Btn>
        )}

        {!user && (
          <Btn onClick={() => setTab(2)} className={tab === 2 ? "Detail__btnActive" : "Detail__btnDisctive"}>
            مشترک
          </Btn>
        )} */}
      </div>
      {tab === 1 && <DetailBody setEditMessage={setEditMessage} user={user} otherTyping={otherTyping} />}
      {tab === 1 && (
        <DetailChat
          user={user}
          sendMessage={sendMessage}
          setFlagSend={setFlagSend}
          setUploadEvent={setUploadEvent}
          uploadEvent={uploadEvent}
          onTypingMessage={onTypingMessage}
          onKeyDown={onKeyDown}
        />
      )}
      {tab === 2 && customer !== false && (
        <DetailCostomer
          customer={customer}
          confirmVip={confirmVip}
          userNationalCode={userNationalCode}
          userMobile={userMobile}
          loading={loading}
        />
      )}
      {tab === 3 && customer !== false && (
        <DetailProblem
          sbmitCustomerProblem={sbmitCustomerProblem}
          userNationalCode={userNationalCode}
          userMobile={userMobile}
        />
      )}
    </div>
  );
}

export default Detail;
