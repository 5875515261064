import axios from "axios";
import React, { useEffect } from "react";
import { ApiUrl } from "../../config";

const ClientCreateConfrence = (props) => {
  let uid = props.match.params.uid;
  useEffect(() => {
    axios({
      url: `${ApiUrl}/customer-confrence/${uid}`,
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function ({ data }) {
      //
      //
      //
      //
      //
      //
      data = data.data.confrence;

      let oid = data.operatorId;
      let nationalCode = data.nationalCode;
      let mobile = data.mobile;

      let name = "directCall";
      localStorage.setItem("data_nationalcode", nationalCode);
      localStorage.setItem("data_mobile", mobile);
      // setNationalCode(nationalCode);
      // setMobile(mobile);

      function makeCall() {
        props.history.push({
          pathname: "/client/call",
          state: {
            nationalCode,
            menu: oid,
            name: name,
            mobile,
          },
        });
      }

      function handleChat() {
        props.history.push({
          pathname: "/client/chat",
          state: {
            nationalCode,
            menu: data.operatorId,
            name: name,
            mobile,
          },
        });
      }

      if (data.type == "video") {
        makeCall();
      } else if (data.type == "text") {
        handleChat();
      }
    });
  });

  return <div></div>;
};
export default ClientCreateConfrence;
