import React, { useEffect, useState } from "react";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import axios from "axios";
import { ApiUrl } from "config";
import { toast } from "react-toastify";
import { Close } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  textarea: {
    resize: "none",
    borderRadius: "4px",
    width: "100%",
    border: "1px solid var(--black)",
    padding: "10px",
  },
  root: {
    width: "100%",
  },
});

function AddNews(props) {
  const classes = useStyles();
  const [queue, setQueue] = useState({});
  const [file, setFile] = useState("");
  const [uploadState, setUploadState] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState(null);
  const [data, setData] = useState({
    title: "",
    text: "",
    menu: "",
    status: true,
    is_main: false,
  });
  const [dataSelectQueue, setDataSelectQueue] = useState([]);
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const options = [];
        res.data.data.forEach((item) => {
          options.push({
            value: item._id,
            label: item.name === "کیپاد" ? "پی پاد" : item.name,
          });
        });
        setDataSelectQueue(options);
      })
      .catch((res) => {});
  };
  const addNews = async () => {
    setLoading(true);
    let form = new FormData();
    form.append("image", file, file.name);
    form.append("title", data.title);
    form.append("text", data.text);
    form.append("menu", queue.value);
    form.append("is_main", data.is_main);
    await axios
      .post(ApiUrl + "/save-blog", form, {
        headers: { Authorization: localStorage.getItem("token"), "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadState(progress);
        },
      })
      .then((res) => {
        toast.success("با موفقیت ثبت شد");
      })
      .catch((res) => toast.error("خطا در ثبت"));
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  useEffect(() => {
    getQueues();
  }, []);
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: 10,
      display: "flex",
      alignItems: "center",
      zIndex: 999999,
    }),
    control: (base) => ({
      ...base,
      // height: "100px",
      width: "100%",
      border: "1px solid black",
      zIndex: 999999,
    }),
    menu: (base) => ({
      ...base,
      height: "fit-content",
      position: "relative",
      zIndex: 999999,
    }),
  };
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
      width={"100%"}
      height={"100%"}
      sx={{ gap: "10px" }}
    >
      <TextField
        name="title"
        variant="outlined"
        placeholder="عنوان"
        className={classes.root}
        onChange={onChangeHandler}
        value={data.question}
      />

      <textarea
        name="text"
        className={classes.textarea}
        placeholder="محتوا"
        onChange={onChangeHandler}
        value={data.answer}
      />

      <Box width={"200px"} style={{ gap: "10px" }} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <CreatableSelect
          closeMenuOnSelect={false}
          isClearable
          onChange={handleChangeQueue}
          options={dataSelectQueue}
          styles={customStyles}
          placeholder="انتخاب صف"
          isDisabled={data.is_main}
        />
        <label>
          <Checkbox checked={data.is_main} onChange={(e) => setData({ ...data, is_main: e.target.checked })} />
          اسلاید اصلی
        </label>

        <Box display={"flex"} style={{ gap: "10px" }} justifyContent={"flex-start"} alignItems={"center"}>
          <Button
            variant={"text"}
            component={"label"}
            style={{
              width: "150px",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
              hidden
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
            {file ? file.name : " آپلود عکس"}
          </Button>
          {file && <Close onClick={(e) => setFile("")} style={{ cursor: "pointer" }} />}
        </Box>
        {/*<Box display={"flex"} style={{ gap: "10px" }} justifyContent={"flex-start"} alignItems={"center"}>*/}
        {/*  <Button*/}
        {/*    variant={"text"}*/}
        {/*    component={"label"}*/}
        {/*    style={{*/}
        {/*      width: "150px",*/}
        {/*      height: "100%",*/}
        {/*      minWidth: "100px",*/}
        {/*      backgroundColor: "var(--primary)",*/}
        {/*      color: "var(--white)"*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <input*/}
        {/*      type="file"*/}
        {/*      accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,.mp4"*/}
        {/*      hidden*/}
        {/*      multiple={true}*/}
        {/*      onChange={(e) => {*/}
        {/*        setGallery(e.target.files);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    {"ساخت گالری"}*/}
        {/*  </Button>*/}
        {/*  {file && <Close onClick={(e) => setFile("")} style={{ cursor: "pointer" }} />}*/}
        {/*</Box>*/}
      </Box>
      <Button color="primary" style={{ backgroundColor: "var(--primary)", color: "var(--white)" }} onClick={addNews}>
        {loading ? uploadState : "افزودن"}
      </Button>
    </Box>
  );
}

export default AddNews;
