import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";
import axios from "axios";
import { ApiUrl } from "../../config";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import { FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
    padding: "20px",
  },
  addFieldContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    padding: "20px",
  },
  input: {
    padding: "15px 10px",
    borderRadius: "5px",
    direction: "rtl",
    animation: "$field 1s",
    transition: "1s",
  },
  filedContainer: {
    overflow: "auto",
    maxHeight: "400px",
    width: "80%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "10px",
    padding: "10px",
    animation: "$field 1s",
    transition: "1s",
  },
  "@keyframe field": {
    from: {
      transform: "translateX(150%)",
    },
    to: {
      transform: "translateX(0)",
    },
  },
});

function FormCreator(props) {
  const initialState = {
    name: "",
    type: "text",
    placeholder: "",
    select: false,
    menu: "",
    required: false,
  };
  const [fields, setFields] = useState([]);
  const [formName, setFormName] = useState("");
  const [field, setField] = useState(initialState);
  const [menu, setMenu] = useState([]);
  const [image, setImage] = useState("");
  const [cities, setCities] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const classes = useStyles();

  const getCities = async () => {
    await axios
      .get(ApiUrl + "/get-cities", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        console.log(res.data.data);
        setCities(res.data.data);
      });
  };
  const getProvices = async () => {
    await axios
      .get(ApiUrl + "/get-provinces", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setProvinces(res.data.data);
      });
  };

  const addField = (e) => {
    // setField({...initialState})
    if (field.name && field.placeholder) {
      setFields((prevState) => [...prevState, field]);
    } else {
      toast.error("فیلد های خواسته شده را پر کنید");
    }
  };
  const deleteField = async (p) => {
    setField({ ...initialState });
    setFields((old) => {
      return old.filter((x) => x.name !== p.name);
    });
  };
  const getFields = async () => {};
  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.which);
    const regex = /^[a-zA-Z]$/;

    if (!regex.test(char)) {
      e.preventDefault();
    }
  };
  const submitFields = async () => {
    console.log(fields);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("name", formName);
    formData.append("menu", field.menu);
    formData.append("fields", JSON.stringify(fields));
    await axios
      .post(ApiUrl + "/create-service-model", formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success("با موفقیت ثبت شد");
      })
      .catch((res) => {
        toast.error("خطا در ثبت");
      });
  };
  const getMenu = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setMenu(res.data.data);
      })
      .catch(() => {
        toast.error("خطا در دریافت صف ها");
      });
  };
  useEffect(() => {
    getMenu();
    getCities();
    getProvices();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.addFieldContainer}>
        <TextField
          value={formName}
          sx={{ width: "200px" }}
          placeholder={"نام فرم"}
          onChange={(e) => setFormName(e.target.value)}
        />
        <TextField
          select
          onChange={(e) => setField({ ...field, menu: e.target.value })}
          InputLabelProps={{ shrink: true }}
          sx={{ width: "200px" }}
        >
          {menu.map((p, i) => {
            return (
              <MenuItem key={p._id} value={p._id}>
                {p.name}
              </MenuItem>
            );
          })}
        </TextField>
        <hr />
        <TextField
          select
          onChange={(e) => setField({ ...field, type: e.target.value, select: true })}
          value={field.type}
          InputLabelProps={{ shrink: true }}
          sx={{ width: "200px" }}
        >
          <MenuItem value={"text"}>متن</MenuItem>
          <MenuItem value={"tel"}>تلفن</MenuItem>
          <MenuItem value={"number"}>عدد</MenuItem>
          <MenuItem value={"password"}>رمزعبور</MenuItem>
          <MenuItem value={"select"}>انتخاب از لیست</MenuItem>
        </TextField>

        <Button
          variant={"text"}
          component={"label"}
          style={{
            width: "150px",
            height: "100%",
            minWidth: "100px",
            backgroundColor: "var(--primary)",
            color: "var(--white)",
          }}
        >
          <input
            type="file"
            accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,.png"
            hidden
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
          {image ? image.name : " آپلود عکس"}
        </Button>
        {field.type === "select" && (
          <TextField
            label={"رفرنس"}
            select
            onChange={(e) => setField({ ...field, ref: e.target.value })}
            defaultValue={"text"}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "200px" }}
          >
            <MenuItem value={"city"}>شهر</MenuItem>
            <MenuItem value={"province"}>استان</MenuItem>
          </TextField>
        )}
        <Tooltip arrow={true} title={"نام فرم فقط به زبان انگلیسی بدون فاصله"}>
          <TextField
            value={field.name}
            sx={{ width: "200px" }}
            placeholder={"نام فیلد"}
            onKeyPress={handleKeyPress}
            onChange={(e) => setField({ ...field, name: e.target.value })}
          />
        </Tooltip>
        <TextField
          value={field.placeholder}
          sx={{ width: "200px" }}
          placeholder={"متن داخل فیلد"}
          onChange={(e) => setField({ ...field, placeholder: e.target.value })}
        />
        <FormGroup>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={<Checkbox onChange={(event) => setField({ ...field, required: event.target.checked })} />}
            label="فیلد ضروری"
          />
        </FormGroup>
        <Button size={"large"} sx={{ width: "200px" }} onClick={addField}>
          افزودن فیلد
        </Button>
        {fields.length > 0 && (
          <Button sx={{ width: "200px" }} onClick={submitFields}>
            ثبت فرم
          </Button>
        )}
      </div>
      <div className={classes.filedContainer}>
        {fields.map((p, i) => {
          return (
            <Box key={i} display={"flex"} justifyConent={"space-between"} alignItems={"center"} gap={"5px"}>
              {p.select ? (
                <TextField
                  label={p.placeholder}
                  name={p.name}
                  select
                  placeholder={p.placeholder}
                  sx={{ width: "200px" }}
                  InputLabelProps={{ shrink: true }}
                >
                  {p.ref === "city"
                    ? cities.map((city) => {
                        return (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        );
                      })
                    : provinces.map((provice) => {
                        return (
                          <MenuItem key={provice.id} value={provice.id}>
                            {provice.name}
                          </MenuItem>
                        );
                      })}
                </TextField>
              ) : (
                <input className={classes.input} key={i} type={p.type} name={p.name} placeholder={p.placeholder} />
              )}
              <Button sx={{ borderRadius: "50%", width: "50px", height: "60px" }} onClick={(e) => deleteField(p)}>
                <Close fontSize={"large"} color={"error"} />
              </Button>
            </Box>
          );
        })}
      </div>
    </div>
  );
}

export default FormCreator;
