// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { ApiUrl } from "../../../config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function TextBoxDetail(props) {
  const [data, setData] = useState("");
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios({
      url: `${ApiUrl}/text-box-message/${props.match.params.id}`,
      method: "get",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      //   data: info,
    })
      .then(function (response) {
        setData(response.data.data.textBox);
        //
      })
      .catch(function (error) {});
  };
  const classes = useStyles();

  return (
    <div dir="rtl">
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              {data ? <h4 style={{ color: "#424242" }}>مشخصات مشتری</h4> : ""}
            </CardHeader>
            <CardBody>
              <div className={"history"}>
                {data ? (
                  <>
                    <label>
                      <span> نام مشتری:</span>

                      <span>{data && data.fullname}</span>
                    </label>
                    <br />
                    {/* <label>
                      <span> ایمیل:</span>

                      <span>{data && " " + data.textBox.email}</span>
                    </label> */}
                    <br />

                    <label>
                      <span> همراه:</span>

                      <span>{data && " " + data.mobile}</span>
                    </label>
                    <br />

                    <label>
                      <span> کد ملی:</span>

                      <span>{data && " " + data.nationalCode}</span>
                    </label>
                    <label>
                      <span> صف:</span>

                      <span>{data && " " + data.menu.name}</span>
                    </label>
                  </>
                ) : (
                  ""
                )}

                <br />

                <label>------------------------</label>
                <br />

                {/* <label>
                  <span> کد ملی مشتری :</span>

                  <span>{data && " " + data.user_national_code}</span>
                </label> */}
                {/* <label>
                  <span> همراه مشتری :</span>

                  <span>{data && data.mobile && " " + data.mobile}</span>
                </label> */}
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242" }}>پیام متنی</h4>
            </CardHeader>
            <CardBody>
              {data && (
                <div id={"messages"} className={"messages"}>
                  <div className="other">
                    {data.message}

                    <br />

                    <span
                      style={{
                        padding: 5,
                        margin: 5,
                        float: "left",
                        fontSize: 10,
                      }}
                    >
                      {moment(data.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
                    </span>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default TextBoxDetail;
