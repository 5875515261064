import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";
import { pageTitle } from "web-config";

const axios = require("axios");

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

function Histories(props) {
  const useStyles = makeStyles(styles);

  const { loading, pagination } = props;
  useEffect(() => {
    getOperators();
    getPollReportsAll();
    props.store.dispatch(ReportAction.GetAllQueues());
  }, loading);
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    //
  }, props.operators);
  const getOperators = () => {
    return props.store.dispatch(UserAction.GetOperators());
  };

  const getPollReportsAll = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.GetPollReportsALL(pagination));
  };

  const getPollReports = (pagination = { per_page: 20, page: 1 }) => {
    // setSelectedDayRange ,point,queue,nationalCode,mobile

    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    let operatorsId = [];

    if (operator && operator.length) {
      operator.forEach((item) => {
        operatorsId.push(item.value);
      });
    }
    let queuesId = [];
    if (queue && queue.length) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    let points = [];
    if (point) {
      point.forEach((item) => {
        points.push(item.value);
      });
    }
    let statuslist = [];
    if (status) {
      status.forEach((item) => {
        statuslist.push(item.value);
      });
    }
    return props.store.dispatch(
      ReportAction.GetPollReports(
        operatorsId,
        pagination,
        queuesId,
        dateG_from,
        dateG_to,
        nationalCodeArray,
        mobileArray,
        points,
        statuslist,
        timeStart,
        timeEnd
      )
    );
  };
  ////
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 490,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );
  /////

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  const getFileExcell = () => {
    let operatorsId = [];
    if (operator && operator.length) {
      operator.forEach((item) => {
        operatorsId.push(item.value);
      });
    }
    let mobileArray = [];
    if (stateMobile && stateMobile.length) {
      stateMobile.forEach((item) => {
        mobileArray.push(item.value);
      });
    }
    let nationalCodeArray = [];
    if (stateNationalCode && stateNationalCode.length) {
      stateNationalCode.forEach((item) => {
        nationalCodeArray.push(item.value);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    let points = [];
    if (point) {
      point.forEach((item) => {
        points.push(item.value);
      });
    }
    let statuslist = [];
    if (status) {
      status.forEach((item) => {
        statuslist.push(item.value);
      });
    }
    axios({
      url: ApiUrl + "/reports-poll-excell",
      method: "post",
      params: {},
      data: {
        operatorsId,
        queuesId,
        dateG_from,
        dateG_to,
        nationalCodeArray,
        mobileArray,
        points,
        statuslist,
        timeStart,
        timeEnd,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const [flag, setFlag] = React.useState("ALL");

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [operator, setOperator] = React.useState("");
  //
  let handleChangeOpeartor = (newValue, actionMeta) => {
    setOperator(newValue);
  };

  ///////////
  const [dataSelectPoint, setDataSelectPoint] = React.useState([
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ]);

  const [point, setPoint] = React.useState("");
  //
  let handleChangePoint = (newValue, actionMeta) => {
    setPoint(newValue);
  };
  /////////

  const [dataSelectStatus, setDataSelectStatus] = React.useState([
    { value: "chat", label: "تماس های متنی" },
    { value: "call", label: "تماس های تصویری" },
  ]);

  const [status, setStatus] = React.useState("");
  //
  let handleChangeStatus = (newValue, actionMeta) => {
    setStatus(newValue);
  };
  ///////////

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };

  const [size, setSize] = React.useState(20);

  const onPageChangeFunction = (pageIndex) => {
    //
    if (flag === "ALL") {
      getPollReportsAll({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "POLL") {
      getPollReports({ per_page: size, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    //
    setSize(pageSize);
    if (flag === "ALL") {
      getPollReportsAll({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "POLL") {
      getPollReports({ per_page: pageSize, page: pageIndex + 1 });
    }
  };

  ///////////////////////////////////////////////////////////////////
  const components = {
    DropdownIndicator: null,
  };
  const createOptionNationalCode = (label) => ({
    label,
    value: label,
  });
  const [stateNationalCode, setStateNationalCode] = React.useState([]);
  const [inputNationalCode, setInputNationalCode] = React.useState("");
  const handleChangeNationalCode = (value, actionMeta) => {
    setStateNationalCode(value);
  };
  const handleInputChangeNationalCode = (inputValue) => {
    setInputNationalCode(inputValue);
  };
  const handleKeyDownNationalCode = (event) => {
    if (!inputNationalCode) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateNationalCode;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputNationalCode;
          });
        }
        if (!found) {
          setInputNationalCode("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionNationalCode(inputNationalCode)];
          } else {
            stateB = [createOptionNationalCode(inputNationalCode)];
          }
          setStateNationalCode(stateB);
        }
        event.preventDefault();
    }
  };

  //////////////////////////////////

  //////////RangePicker
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    //
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  ////////////RangePicker

  ///////////////////////////////////////////////////////////////////
  const createOptionMobile = (label) => ({
    label,
    value: label,
  });
  const [stateMobile, setStateMobile] = React.useState([]);
  const [inputMobile, setInputMobile] = React.useState("");
  const handleChangeMobile = (newValue, actionMeta) => {
    setStateMobile(newValue);
  };
  const handleInputChangeMobile = (inputValueMobile) => {
    setInputMobile(inputValueMobile);
  };
  const handleKeyDownMobile = (event) => {
    if (!inputMobile) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let stateV = stateMobile;
        let found;
        if (stateV && stateV.length) {
          found = stateV.find((element) => {
            return element.value == inputMobile;
          });
        }

        if (!found) {
          setInputMobile("");
          let stateB;
          if (stateV) {
            stateB = [...stateV, createOptionMobile(inputMobile)];
          } else {
            stateB = [createOptionMobile(inputMobile)];
          }
          setStateMobile(stateB);
        }
        event.preventDefault();
    }
  };
  //////////////////////////////////

  const classes = useStyles();
  if (loading) {
    return <Spinner />;
  }

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>نظرسنجی - {pageTitle}</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>

            <Card>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => null}
                    isClearable
                    onChange={handleChangePoint}
                    options={dataSelectPoint}
                    defaultValue={point}
                    styles={customStyles}
                    placeholder="امتیاز مورد نظر نظر را وارد کنید"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <DatePicker
                    value={selectedDayRange}
                    renderInput={renderCustomInput}
                    onChange={setSelectedDayRange}
                    inputPlaceholder="زمان گزارش"
                    shouldHighlightWeekends
                    calendarPopperPosition="button"
                    locale="fa"
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    onChange={handleChangeOpeartor}
                    options={dataSelectOperator}
                    defaultValue={operator}
                    styles={customStyles}
                    placeholder="اپراتور مورد نظر را انتخاب کنید"
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <Box>
                    <CreatableSelect
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable
                      onChange={handleChangeQueue}
                      options={dataSelectQueue}
                      styles={customStyles}
                      defaultValue={queue}
                      placeholder="صف مورد نظر خود را انتخاب کنید"
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "8px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    components={components}
                    inputValue={inputMobile}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChangeMobile}
                    onInputChange={handleInputChangeMobile}
                    onKeyDown={handleKeyDownMobile}
                    placeholder="شماره تلفن عضو"
                    styles={customStyles}
                    value={stateMobile}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "8px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    components={components}
                    inputValue={inputNationalCode}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={handleChangeNationalCode}
                    onInputChange={handleInputChangeNationalCode}
                    onKeyDown={handleKeyDownNationalCode}
                    styles={customStyles}
                    placeholder="کدملی عضو"
                    value={stateNationalCode}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => null}
                    isClearable
                    onChange={handleChangeStatus}
                    options={dataSelectStatus}
                    defaultValue={status}
                    styles={customStyles}
                    placeholder="نوع تماس"
                  />
                </Grid>

                <Grid
                  item
                  xs={5}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <RangePicker
                    onChange={onChange}
                    placeholder={["زمان آغاز", "زمان پایان"]}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                    style={{
                      height: "60px",
                      width: "300px",
                      border: "1px solid black",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontSize: "17px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{
                    margin: "24px",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      //
                      setFlag("POLL");
                      getPollReports({ per_page: 20, page: 1 }, operator);
                    }}
                  >
                    اعمال
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      setFlag("ALL");
                      getPollReportsAll();
                    }}
                  >
                    دریافت همه گزارشات
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    name="buttonname"
                    style={{ height: 55 }}
                    onClick={() => {
                      getFileExcell();
                    }}
                  >
                    دریافت فایل
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <CardBody>
              <ReactTable
                rtl
                data={props.polls ? props.polls : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: "اپراتور",
                    accessor: "operator",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      let showName = "";
                      dataSelectOperator &&
                        dataSelectOperator.forEach((item) => {
                          //
                          //
                          if (item.value === row.value) {
                            //
                            showName = item.label;
                          }
                        });
                      return showName;
                    },
                  },
                  {
                    Header: "متن پیام",
                    accessor: "message",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "امتیاز",
                    accessor: "rate",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "شماره تلفن  مشتری",
                    accessor: "customer",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "نوع تماس",
                    accessor: "status",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>{row.value == "call" ? "تماس تصویری" : row.value == "chat" ? "تماس متنی" : "ثبت نشده"}</div>
                    ),
                  },
                  {
                    Header: "کدملی مشتری",
                    accessor: "user_national_code",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "صف",
                    accessor: "menu",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        if (row.value.name) {
                          return row.value.name;
                        } else {
                          return "ثبت نشده";
                        }
                      } else {
                        return "";
                      }
                    },
                  },
                  {
                    Header: "تاریخ",
                    accessor: "date",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      if (row.value) {
                        return (
                          <div>
                            {moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss") ===
                            "Invalid date"
                              ? moment(row.value, "YYYY-MM-DD HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")
                              : moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss")}
                          </div>
                        );
                      } else {
                        return "ثبت نشده";
                      }
                    },
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  //
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  //
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  //
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  //
  return {
    pagination: state.reports.pagination,
    loading: state.reports.loading,
    error: state.reports.error,
    polls: state.reports.polls,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};

const mapDispatchToProps = (dispatch) => {
  //
  return {
    UserAction: bindActionCreators({ UserAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
