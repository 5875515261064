import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Calendar, DateObject } from "react-multi-date-picker";
import { makeStyles } from "@material-ui/core/styles";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { TimePicker } from "antd";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast } from "react-toastify";
import moment from "jalali-moment";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles({
  calendar: {
    width: "100%",

    display: "flex",
    justifyContent: "center",
    "& div": {
      width: "100%",
    },
    "& span": {
      fontSize: "20px",
    },
  },
});

function Appointment(props) {
  const classes = useStyles();
  const [operators, setOperators] = useState([]);
  const [menus, setMenus] = useState([]);
  const [dates, setDates] = useState([]);
  const [appointments, setAppointments] = useState({
    dates: [],
    startTime: "",
    endTime: "",
    duration: 0,
    doctor: "",
    menu: "",
    price: "",
  });
  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-operators", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data);
      });
  };
  const getMenus = async () => {
    await axios
      .get(ApiUrl + "/menu-items", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setMenus(res.data.data.menu_items);
      });
  };
  useEffect(() => {
    getOperators();
    getMenus();
  }, []);
  useEffect(() => {
    dateHandler();
  }, [dates]);
  const dateHandler = () => {
    let convertedDate = [];
    dates.forEach((date) => {
      convertedDate.push(new Date(date.unix * 1000).toISOString());
    });
    setAppointments({ ...appointments, dates: convertedDate });
  };
  const submit = async () => {
    await axios
      .post(ApiUrl + "/create-appt", appointments, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        toast.success("نوبت با موفقیت ایجاد شد");
      })
      .catch((res) => {
        toast.error("خطا در ساخت نوبت");
      });
  };
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "500px",
        display: "flex",
        flexWrap: "wrap",
        padding: "16px",
        flexDirection: "column",
        borderRadius: "8px",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "flex-wrap",
            gap: "16px",
          }}
        >
          <TextField
            label={"انتخاب اپراتور"}
            fullWidth
            select
            name={"doctor"}
            onChange={(e) => setAppointments({ ...appointments, [e.target.name]: e.target.value })}
            InputLabelProps={{ shrink: true }}
            sx={{
              "& .MuiFormLabel-root": {
                top: "-20px !important",
              },
            }}
          >
            {operators.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.first_name + " " + item.last_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={"انتخاب صف"}
            fullWidth
            select
            name={"menu"}
            onChange={(e) => setAppointments({ ...appointments, [e.target.name]: e.target.value })}
            sx={{
              "& .MuiFormLabel-root": {
                top: "-20px !important",
              },
            }}
            InputLabelProps={{ shrink: true }}
          >
            {menus.map((item) => (
              <MenuItem key={item._id} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="number"
            fullWidth
            name={"duration"}
            onChange={(e) => setAppointments({ ...appointments, [e.target.name]: parseInt(e.target.value) })}
            label={"زمان هر اپراتور ( دقیقه ) "}
            sx={{
              "& .MuiFormLabel-root": {
                top: "-20px !important",
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          <NumericFormat
            label="قیمت (ریال)"
            InputLabelProps={{ shrink: true }}
            thousandsGroupStyle="thousand"
            thousandSeparator="/"
            onValueChange={(values) => {
              setAppointments({ ...appointments, price: values.value });
            }}
            sx={{
              "& .MuiFormLabel-root": {
                top: "-20px !important",
              },
              width: "100%",
            }}
            value={appointments.price}
            customInput={TextField}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            justifyContent: "center",
          }}
        >
          <TimePicker
            showSecond={false}
            inputReadOnly
            onChange={(e) => {
              setAppointments({
                ...appointments,
                startTime: ("0" + new Date(e).getHours()).slice(-2) + ":" + ("0" + new Date(e).getMinutes()).slice(-2),
              });
            }}
            placeholder={"زمان شروع نوبت دهی"}
            style={{ direction: "ltr", height: "50px", width: "100%" }}
          />
          <TimePicker
            showSecond={false}
            inputReadOnly
            onChange={(e) => {
              setAppointments({
                ...appointments,
                endTime: ("0" + new Date(e).getHours()).slice(-2) + ":" + ("0" + new Date(e).getMinutes()).slice(-2),
              });
            }}
            placeholder={"زمان پایان نوبت دهی"}
            style={{ direction: "ltr", height: "50px", width: "100%" }}
          />
        </Box>
      </Box>
      <Box width="100%">
        <Calendar
          onChange={(e) => setDates(e)}
          plugins={[
            // eslint-disable-next-line react/jsx-key
            <DatePanel header={"تاریخ حضور اپراتور"} />,
          ]}
          className={classes.calendar}
          multiple
          minDate={moment().toDate()}
          maxDate={new DateObject().toLastOfMonth()}
          calendar={persian}
          locale={persian_fa}
          weekDays={["شنبه", "یک‌شنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه"]}
        />
      </Box>
      <Button
        onClick={submit}
        sx={{
          padding: "16px 24px",
          backgroundColor: "var(--primary)",
          color: "var(--white)",
          fontSize: "1.2rem",
          "&:hover": {
            color: "var(--black)",
          },
        }}
      >
        ثبت تغییرات
      </Button>
    </Box>
  );
}

export default Appointment;
