import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast } from "react-toastify";
import blurImage from "assets/img/ticket/blur.jpg";

const useStyles = makeStyles({
  root: {},
  container: {
    backgroundColor: "var(--white)",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    border: "1px solid var(--lightGray)",
    overflow: "hidden",
    "& hr": {
      margin: 0,
    },
  },
  header: {
    width: "100%",
    maxHeight: "150px",
    padding: "40px 20px 10px 20px",
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  },

  info: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  },
  action: {},
  body: {
    backgroundColor: "var(--white)",
    width: "100%",
    height: "100%",
    maxHeight: "250px",
    padding: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
  },
  chat: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  operator: {
    backgroundColor: "var(--green)",
    borderRadius: "10px 10px 0 10px",
    padding: "10px 20px",
    float: "right",
    fontSize: "16px",
  },
  client: {
    backgroundColor: "var(--primary)",
    borderRadius: "10px 10px 10px 0px",
    padding: "10px 20px",
    float: "left",
    fontSize: "16px",
  },
  footer: {
    backgroundColor: "var(--white)",
    width: "100%",
    height: "100%",
    padding: "30px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    overflow: "hidden",
  },
  button: {
    backgroundColor: "var(--primary) !important",
    color: "var(--white) !important",
    fontWeight: "500 !important",
    width: "120px !important",
    fontSize: "16px !important",

    "&:hover": {
      color: "var(--black) !important",
    },
    "&:disabled": {
      backgroundColor: "var(--gray) !important",
      cursor: "not-allowed",
    },
  },
  circle: {
    backgroundColor: "var(--green)",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
  },
  form: {
    width: "100%",
    height: "100%",
    backgroundColor: "var(--darkWhite)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: "30px",
    padding: "20px",
    border: "1px solid var(--lightGray)",
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "40px",
    width: "100%",
  },
  inputDetail: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "40px !important",
  },
  content: {
    borderRadius: "5px",
    width: "100%",
    padding: "10px",
    border: "1px solid var(--lightGray)",
  },
  desc: {
    backgroundColor: "lightblue",
    width: "100%",
    padding: "20px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    transition: "0.5s",
    "&:hover": {
      transform: "scale(103%)",
      transition: "0.5s",
    },
  },
  textField: {
    minWidth: "200px !important",
    backgroundColor: "var(--white)",
    width: "100%",
    direction: "rtl",
    "& .MuiFormLabel-root": {
      top: "-20px !important",
    },
  },
  select: {
    minWidth: "100px",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
  },
});

function TicketAnswer(props) {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState({});
  const [messages, setMessages] = useState([]);
  const [ticketStatus, setStatus] = useState("");
  const [menu, setMenu] = useState("");
  const [content, setContent] = useState("");
  const [queues, setQueues] = useState([]);
  const [user, setUser] = useState({ _id: "", nationalCode: "" });
  const status = {
    Waiting: "var(--green)",
    Close: "var(--chiliRed)",
    Answered: "var(--primary)",
    gray: "var(--gray)",
  };

  const id = props.match.params.id;
  const getTicket = async () => {
    await axios
      .get(ApiUrl + "/get-tickets-messages/" + id, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then(async (res) => {
        setData(res.data.data.ticket);
        setStatus(res.data.data.ticket.status);
        await axios
          .get(ApiUrl + "/get-user/" + res.data.data.ticket.client._id, {
            headers: { Authorization: localStorage.getItem("token") },
          })
          .then((res) => {
            // setUser({_id:res.data.data._id,nationalCode:})
          });
        setUser({ ...user, _id: res.data.data.ticket.client._id });
        let sortedMessages = res.data.data.messages.sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1;
          } else {
            return -1;
          }
        });
        setMessages(sortedMessages);
      });
  };

  const getMenu = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setQueues(res.data.data);
      });
  };
  useEffect(() => {
    getTicket();
    getMenu();
  }, [ticketStatus]);
  useEffect(() => {
    const indexMenu = queues.findIndex((x) => x._id === data.menu);
    console.log(indexMenu);
    if (indexMenu !== -1) {
      setMenu(queues[indexMenu]._id);
    }
  }, [queues, data]);
  const chatRef = useRef(null);
  useEffect(() => {
    if (chatRef && chatRef.current) {
      chatRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [messages]);
  const submitMessageAdd = async () => {
    await axios
      .post(
        ApiUrl + "/add-tickets-message/" + id,
        { message: content, userType: "operator" },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        setMessages((prevState) => [...prevState, res.data.data]);
        toast.success("پاسخ ثبت شد");
      })
      .catch((res) => {
        toast.error(res.response.data.message);
      });
  };
  const updateStatus = async (value) => {
    await axios
      .patch(
        ApiUrl + "/update-ticket/" + id,
        { status: value, assign_operator: localStorage.getItem("_id") },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.success("وضعیت تغییر کرد");
      })
      .catch((res) => {
        toast.error("خطا در تغییر وضعیت");
      });
  };
  const updateMenu = async (value) => {
    await axios
      .patch(
        ApiUrl + "/update-ticket/" + id,
        { menu: value, assign_operator: localStorage.getItem("_id") },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.success("صف تغییر کرد");
      })
      .catch((res) => {
        toast.error("خطا در تغییر صف");
      });
  };
  const seenTicket = async () => {
    await axios.patch(
      ApiUrl + "/seen-tickets-messages/" + id,
      {},
      { headers: { Authorization: localStorage.getItem("token") } }
    );
  };
  useEffect(() => {
    seenTicket();
  }, []);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.info}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
            <span
              className={classes.circle}
              style={{
                backgroundColor: status[ticketStatus],
              }}
            ></span>
            <Typography fontSize={12} fontWeight={500}>
              تیکت#{data.number}
            </Typography>
          </div>
          <Typography fontSize={12} fontWeight={200} color={"var(--darkGray)"}>
            تاریخ ایجاد{" "}
            {new Date(data.createdAt).toLocaleDateString("fa-IR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </Typography>
        </div>

        <div className={classes.title}>
          <Typography fontWeight={800} fontSize={24}>
            {data.title}
          </Typography>
        </div>
      </div>
      <div className={classes.body} ref={chatRef}>
        {messages.map((item) => (
          <div
            key={item._id}
            className={classes.desc}
            style={{ backgroundColor: item.operator ? "var(--cyan100)" : "var(--red100)" }}
          >
            <Typography fontWeight={400} fontSize={12}>
              {item.message}
            </Typography>
            <hr />
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} fontSize={10}>
                {new Date(item.createdAt).toLocaleTimeString("fa-IR", { timeStyle: "short" })}
              </Typography>
              <Typography fontWeight={300} fontSize={10}>
                {new Date(item.createdAt).toLocaleDateString("fa-IR", { dateStyle: "short" })}
              </Typography>
            </Box>
          </div>
        ))}
      </div>
      <div className={classes.footer}>
        <div className={classes.form}>
          <Typography fontWeight={800} fontSize={16}>
            پاسخ به پیام
          </Typography>
          <div className={classes.inputs}>
            <div className={classes.inputDetail}>
              <TextField
                label="کد ملی"
                InputLabelProps={{ shrink: true }}
                variant={"outlined"}
                className={classes.textField}
                disabled={true}
                value={data.client && data.client.nationalCode ? data.client.nationalCode : ""}
              />
              <TextField
                label="وضعیت"
                InputLabelProps={{ shrink: true }}
                variant={"outlined"}
                className={classes.textField}
                value={ticketStatus}
                select
                onChange={(e) => {
                  setStatus(e.target.value);
                  updateStatus(e.target.value);
                }}
              >
                <MenuItem value={"Waiting"}>
                  <div className={classes.menuItem}>
                    <span className={classes.circle} style={{ backgroundColor: status.Waiting }}></span>
                    <span>باز</span>
                  </div>
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"Answered"}>
                  <div className={classes.menuItem}>
                    <span className={classes.circle} style={{ backgroundColor: status.Answered }}></span>
                    <span>پاسخ داده شده</span>
                  </div>
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"Close"}>
                  <div className={classes.menuItem}>
                    <span className={classes.circle} style={{ backgroundColor: status.Close }}></span>
                    <span>بسته</span>
                  </div>
                </MenuItem>
              </TextField>
              <TextField
                label="صف"
                InputLabelProps={{ shrink: true }}
                variant={"outlined"}
                className={classes.textField}
                select
                value={menu}
                onChange={(e) => {
                  setMenu(e.target.value);
                  updateMenu(e.target.value);
                }}
              >
                {queues.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <textarea
              className={classes.content}
              cols="30"
              rows="6"
              placeholder={"پاسخ"}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              disabled={ticketStatus === "Close" ? true : false}
            />
          </div>
          {ticketStatus !== "Close" && (
            <Button disabled={content ? false : true} className={classes.button} onClick={submitMessageAdd}>
              ثبت پاسخ
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TicketAnswer;
