import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { DeleteOutlined, DraftsOutlined, EmailOutlined, Visibility } from "@material-ui/icons";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ApiUrl, Url } from "../../config";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "16px",
  },
  table: {
    // overflow: "hidden",
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
    borderRadius: "10px !important",
    maxHeight: "calc(100vh - 200px)",
  },
  thead: {
    backgroundColor: "var(--white)",
    height: "40px",
    border: "none !important",
    boxShadow: "none !important",
  },
  tbody: {
    // border:"none"
  },
  tr: {
    height: "40px",
    border: "none",
  },
  td: {
    height: "30px",
    border: "none",
  },
  pagination: {
    backgroundColor: "var(--white)",
    borderRadius: "10px ",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
  },
  circle: {
    backgroundColor: "var(--green)",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    left: 2,
    top: 5,
  },
});
function FormValues(props) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [value, setValue] = useState(0);
  const [pagination, setPagination] = useState("");
  const [page, setPage] = useState({ size: 50, page: 1 });
  const [operators, setOperators] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(null);
  const getTickets = async () => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/get-service-forms?size=${page.size}&page=${page.page}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        setValue(0);
        setData(res.data.data?.histories);
        setDataFiltered(res.data.data?.histories);
        setPagination(res.data.data.pagination);
      })
      .catch((res) => {
        console.log(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPageChange = (pageIndex) => {
    setPage({ ...page, page: pageIndex + 1 });
  };
  const onPageSize = (pageSize, pageIndex) => {
    setPage({ ...page, size: pageSize });
  };

  useEffect(() => {
    getTickets();
  }, [page]);

  const priority = {
    Normal: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--green)",
          }}
        />
        <Typography>معمولی</Typography>
      </>
    ),
    Immediate: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--chiliRed)",
          }}
        />
        <Typography>فوری</Typography>
      </>
    ),
    High: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--primary)",
          }}
        />
        <Typography>زیاد</Typography>
      </>
    ),
    Low: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--gray)",
          }}
        />
        <Typography>کم</Typography>
      </>
    ),
  };
  const status = {
    Waiting: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--green)",
          }}
        />
        <Typography>باز</Typography>
      </>
    ),
    Close: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--chiliRed)",
          }}
        />
        <Typography>بسته</Typography>
      </>
    ),
    Answered: (
      <>
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "var(--primary)",
          }}
        />
        <Typography>پاسخ داده شده</Typography>
      </>
    ),
    gray: "var(--gray)",
  };

  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-operators", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data);
      });
  };

  const changeTicketStatus = async (formId, status) => {
    await axios
      .patch(
        ApiUrl + "/update-service-form/" + formId,
        { status },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        getTickets();
        toast.success("وضعیت تغییر کرد");
      })
      .catch((res) => {
        toast.error("خطا در تغییر وضعیت");
      });
  };

  useEffect(() => {
    getOperators();
  }, []);
  const confirm = async (id) => {
    await axios
      .delete(ApiUrl + "/service-form/" + id, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        toast.success("با موفقیت حذف شد");
        setAlert(null);
      })
      .catch((res) => {
        toast.error("خطا در حذف");
        setAlert(null);
      });
  };
  const deleteForm = (id) => {
    setShowAlert(true);
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="آیا حذف عضو اطمینان دارید؟"
        onConfirm={() => confirm(id)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="حذف"
        cancelBtnText="لغو"
        showCancel
      ></SweetAlert>
    );
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={"table-responsive bg-white " + classes.container}>
      <ReactTable
        rtl
        data={dataFiltered}
        loading={loading}
        loadingText={"در  حال بارگذاری"}
        style={{ borderRadius: "10px ", width: "100%" }}
        pagination={true}
        manual
        defaultPageSize={50}
        className={"bg-white "}
        getTableProps={() => ({ className: classes.table })}
        getTheadProps={() => ({ className: classes.thead })}
        getResizerProps={() => ({ className: classes.thead })}
        getTrProps={() => ({ className: classes.tr })}
        getTdProps={() => ({ className: classes.td })}
        getTbodyProps={() => ({ className: classes.tbody })}
        getPaginationProps={() => ({ className: classes.pagination })}
        filterable={true}
        rowsText={"سطر"}
        pageText={"صفحه"}
        nextText={"بعدی"}
        previousText={"قبلی"}
        ofText={"از"}
        noDataText={"نوبتی پیدا نشد"}
        columns={[
          {
            Header: "/",
            sortable: false,
            accessor: "model.icon",
            filterable: false,
            Cell: (row) => (
              <div>
                <img height={"20px"} style={{ objectFit: "contain" }} src={Url + row.value} alt="" />
              </div>
            ),
          },
          {
            Header: "تاریخ",
            accessor: "createdAt",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{new Date(row.value).toLocaleDateString("fa-IR", { dateStyle: "short" })}</div>,
          },

          {
            Header: "صف",
            accessor: "menu.name",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value}</div>,
          },
          {
            Header: "نام فرم",
            accessor: "model.name",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value}</div>,
          },
          {
            Header: "وضعیت",
            accessor: "status",
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <>
                <TextField
                  select
                  fullWidth
                  sx={{
                    "& .MuiSelect-select": {
                      paddingRight: 0,
                      paddingLeft: 0,
                      paddingTop: "1px",
                      paddingBottom: "1px",
                    },
                  }}
                  value={row.value}
                  onChange={(e) => changeTicketStatus(row.original._id, e.target.value)}
                >
                  <MenuItem value={"Waiting"}>
                    <div className={classes.menuItem}>
                      <span className={classes.circle} style={{ backgroundColor: "var(--green)" }}></span>
                      <span>باز</span>
                    </div>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} value={"Answered"}>
                    <div className={classes.menuItem}>
                      <span className={classes.circle} style={{ backgroundColor: "var(--primary)" }}></span>
                      <span>پاسخ داده شده</span>
                    </div>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} value={"Close"}>
                    <div className={classes.menuItem}>
                      <span className={classes.circle} style={{ backgroundColor: "var(--chiliRed)" }}></span>
                      <span>بسته</span>
                    </div>
                  </MenuItem>
                </TextField>
              </>
            ),
          },

          {
            Header: "عملیات",
            accessor: "_id",
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                }}
              >
                <DeleteOutlined
                  style={{ fill: "var(--chiliRed)", cursor: "pointer" }}
                  onClick={(e) => {
                    deleteForm(row.value);
                  }}
                />
                <a href={"/admin/show-form/" + row.value} target={"_blank"} style={{ display: "flex", gap: 2 }}>
                  <Visibility />
                  مشاهده
                </a>
              </Box>
            ),
          },
        ]}
        pageSize={pagination && pagination.size}
        page={pagination && pagination.page - 1}
        pages={pagination && pagination.total}
        onPageChange={(pageIndex) => {
          //
          onPageChange(pageIndex);
        }}
        onPageSizeChange={(pageSize, pageIndex) => {
          //
          onPageSize(pageSize, pageIndex);
        }}
        // onFilteredChange={(column, value) => {
        //   //
        // }}
        // className="-striped -highlight"
        showPaginationTop={false}
        showPaginationBottom={true}
      />
      {alert}
    </div>
  );
}

export default FormValues;
