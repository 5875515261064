// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect } from "react";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import CustomerAction from "../../js/actions/customer_action";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading, history } = props;
  useEffect(() => {
    fetchMore();
  }, loading);

  const fetchMore = () => {
    let id = props.match.params.id;
    return props.store.dispatch(CustomerAction.GetCustomerReport(id));
  };

  const classes = useStyles();
  if (loading) return "loading";

  return (
    <div dir="rtl">
      <GridContainer>
        <GridItem xs={6}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242" }}>مشخصات مشتری</h4>
            </CardHeader>
            <CardBody>
              <div className={"history"}>
                <label>
                  <span> نام مشتری:</span>

                  <span>{props.customer && " " + props.customer.first_name + " " + props.customer.last_name}</span>
                </label>
                <br />
                <label>
                  <span> ایمیل:</span>

                  <span>{props.customer && props.customer.email}</span>
                </label>
                <br />

                <label>
                  <span> همراه:</span>

                  <span>{props.customer && props.customer.mobile}</span>
                </label>
                <br />

                <label>
                  <span> کد ملی:</span>

                  <span>{props.customer && props.customer.national_code}</span>
                </label>

                <br />

                <label>------------------------</label>
                <br />

                <label>
                  <span> وضعیت عضویت :</span>

                  <span>{props.customer && props.customer.vip ? "vip" : "عضویت عادی"}</span>
                </label>
              </div>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 style={{ color: "#424242" }}> تاریخچه اپراتور ها </h4>
            </CardHeader>
            <CardBody>
              {props.customer &&
                props.customer.Problems.map((e, i) => {
                  return (
                    <div id={"messages"} className={"messages"} key={i}>
                      <div className={e.type == "operator" ? "me" : "other"}>
                        {e.problem}

                        <br />

                        <span
                          style={{
                            padding: 5,
                            margin: 5,
                            float: "left",
                            fontSize: 10,
                          }}
                        >
                          {moment(e.date, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD HH:mm:ss")}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer.customerHistory,
    loading: state.customer.loading,
    error: state.customer.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
