/* eslint-disable no-console */
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ApiUrl } from "config";
import React, { useEffect, useState } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: right,
    textAlign: "right",
    flexGrow: 1,
    width: "99%",
    marginTop: "20px",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    textAlign: "right",
    margin: theme.spacing(4, 0, 2),
  },
}));

function ViewAccessibility() {
  const classes = useStyles();
  const [access, setAccess] = useState();
  let level_data = [
    {
      name: "سطح 1",
      label: "level1",
    },
    {
      name: "سطح 2",
      label: "level2",
    },
    {
      name: "سطح 3",
      label: "level3",
    },
  ];

  let access_data = [
    { name: "callDetails", label: "تاریخچه اپراتور ها" },
    { name: "inputCall", label: "تماس های ورودی" },
    { name: "chartCalls", label: "نمودار اپراتور ها" },
    { name: "operatorsPoll", label: "گزارشات نظرسنجی" },
    {
      name: "reportEnterExit",
      label: "گزارش ورود و خروج از صف",
    },
    {
      name: "textBoxReport",
      label: "گزارش صندوق متنی",
    },
    {
      name: "mediaBoxReport",
      label: "گزارش صندوق تصویری",
    },
    {
      name: "textBoxMessages",
      label: "پیام های ارسالی",
    },
    { name: "transferCall", label: "گزارش انتقال تماس" },
    { name: "outCall", label: "گزارش تماس خروجی" },
    {
      name: "reportMidCalls",
      label: "گزارش اپراتور ها",
    },
    {
      name: "clientsReport",
      label: "گزارش کاربران",
    },
    {
      name: "customersList",
      label: "لیست کاربران",
    },
    {
      name: "addCustomers",
      label: "افزودن کاربر",
    },
    {
      name: "queuesList",
      label: "لیست صف ها",
    },
    { name: "addQueue", label: "افزودن صف" },
  ];
  let show_level = (name) => {
    access_data.find((o, i) => {
      if (o.name === name) {
        let data = access_data[i].label;
        return data;
      }
    });
  };
  useEffect(() => {
    axios({
      url: ApiUrl + "/rolls/",
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((response) => {
        let data = response.data.data.data;
        if (data && data.length > 0) {
          data.map((item, j) => {
            level_data.find((o, i) => {
              if (o.label === item.name) {
                data[j].fa_name = level_data[i].name;
                item.roll.map((item, n) => {
                  access_data.find((o, i) => {
                    if (item === o.name) {
                      data[j].roll[n] = o.label;
                    } else if (item == "reports") {
                      data[j].roll[n] = "";
                    } else if (item == "customers") {
                      data[j].roll[n] = " ";
                    } else if (item == "queue") {
                      data[j].roll[n] = "  ";
                    }
                  });
                });
              }
            });
          });
        }

        setAccess(response.data.data.data);
      })
      .catch((error) => {
        //
      })
      .finally(function () {
        // always executed
      });
  }, []);

  return (
    <div dir="rtl">
      <div className={classes.root}>
        {access?.map((item) => (
          <div key={item.name}>
            <h6 className={classes.cardIconTitle}>{item.fa_name}</h6>
            <div className={classes.demo}>
              <List dir="rtl">
                {item.roll.map((value) => (
                  <ListItem key={value}>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewAccessibility;
