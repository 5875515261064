import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DraftsOutlined, EmailOutlined, Visibility } from "@material-ui/icons";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../config";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FormValues from "components/FormValues/FormValues";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "16px",
  },
  table: {
    // overflow: "hidden",
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
    borderRadius: "10px !important",
    maxHeight: "calc(100vh - 200px)",
  },
  thead: {
    backgroundColor: "var(--white)",
    height: "40px",
    border: "none !important",
    boxShadow: "none !important",
  },
  tbody: {
    // border:"none"
  },
  tr: {
    height: "40px",
    border: "none",
  },
  td: {
    height: "30px",
    border: "none",
  },
  pagination: {
    backgroundColor: "var(--white)",
    borderRadius: "10px ",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
  },
  circle: {
    backgroundColor: "var(--green)",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    left: 2,
    top: 5,
  },
});

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Tickets(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const [pagination, setPagination] = useState("");
  const [page, setPage] = useState({ size: 20, page: 1 });
  const [operators, setOperators] = useState([]);

  const getTickets = async () => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/get-tickets?size=${page.size}&page=${page.page}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        setValue(0);
        setData(res.data.data?.histories);
        setPagination(res.data.data.pagination);
      })
      .catch((res) => {
        console.log(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPageChange = (pageIndex) => {
    setPage({ ...page, page: pageIndex + 1 });
  };
  const onPageSize = (pageSize, pageIndex) => {
    setPage({ ...page, size: pageSize });
  };
  const getTicketWithFilter = async (status = "") => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/get-tickets-with-filter?status=${status}&size=${page.size}&page=${page.page}`, {
        headers: { Authorization: localStorage.getItem("token") },
      })
      .then((res) => {
        setData(res.data.data.tickets);
        setPagination(res.data.data.pagination);
      })
      .catch((res) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    switch (value) {
      case 0:
        getTickets();
        break;
      case 1:
        getTicketWithFilter("Waiting");
        break;
      case 2:
        getTicketWithFilter("Close");
        break;
      case 3:
        getTicketWithFilter("Answered");
        break;
      default:
        break;
    }
  }, [value, page]);

  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-operators", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data);
      });
  };
  const assignOperator = async (ticketId, operatorId) => {
    await axios
      .patch(
        ApiUrl + "/update-ticket/" + ticketId,
        {
          assign_operator: operatorId,
        },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        getTickets();
        toast.success("به اپراتور جدید اختصاص یافت");
      });
  };
  const changeTicketStatus = async (ticketId, status) => {
    await axios
      .patch(
        ApiUrl + "/update-ticket/" + ticketId,
        { status },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        getTickets();
        toast.success("وضعیت تغییر کرد");
      })
      .catch((res) => {
        toast.error("خطا در تغییر وضعیت");
      });
  };
  const changeTicketPriority = async (ticketId, priority) => {
    await axios
      .patch(
        ApiUrl + "/update-ticket/" + ticketId,
        { priority },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        getTickets();
        toast.success("وضعیت تغییر کرد");
      })
      .catch((res) => {
        toast.error("خطا در تغییر وضعیت");
      });
  };
  useEffect(() => {
    getOperators();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={"table-responsive bg-white " + classes.container}>
      {value === 4 ? (
        <FormValues />
      ) : (
        <ReactTable
          rtl
          data={data}
          loading={loading}
          loadingText={"در  حال بارگذاری"}
          style={{ borderRadius: "10px ", width: "100%" }}
          pagination={true}
          manual
          defaultPageSize={20}
          className={"bg-white "}
          getTableProps={() => ({ className: classes.table })}
          getTheadProps={() => ({ className: classes.thead })}
          getResizerProps={() => ({ className: classes.thead })}
          getTrProps={() => ({ className: classes.tr })}
          getTdProps={() => ({ className: classes.td })}
          getTbodyProps={() => ({ className: classes.tbody })}
          getPaginationProps={() => ({ className: classes.pagination })}
          filterable={true}
          rowsText={"سطر"}
          pageText={"صفحه"}
          nextText={"بعدی"}
          previousText={"قبلی"}
          ofText={"از"}
          noDataText={"نوبتی پیدا نشد"}
          columns={[
            {
              Header: "/",
              sortable: false,
              accessor: "is_seen",
              filterable: false,
              Cell: (row) => (
                <div>
                  {row.value === false ? (
                    <EmailOutlined style={{ color: "var(--chiliRed)" }} />
                  ) : (
                    <DraftsOutlined style={{ color: "var(--green)" }} />
                  )}
                </div>
              ),
            },
            {
              Header: "شماره تیکت",
              sortable: false,
              accessor: "number",
              filterable: false,
              Cell: (row) => <div>{row.value}</div>,
            },

            {
              Header: "تاریخ",
              accessor: "createdAt",
              sortable: false,
              filterable: false,
              Cell: (row) => <div>{new Date(row.value).toLocaleDateString("fa-IR", { dateStyle: "short" })}</div>,
            },

            {
              Header: "صف",
              accessor: "menu.name",
              sortable: false,
              filterable: false,
              Cell: (row) => <div>{row.value}</div>,
            },
            {
              Header: "عنوان",
              accessor: "title",
              sortable: false,
              filterable: false,
              Cell: (row) => <div>{row.value}</div>,
            },
            {
              Header: "اپراتور",
              accessor: "assign_operator._id",
              sortable: false,
              filterable: false,
              Cell: (row) => (
                <>
                  <TextField
                    select
                    fullWidth
                    sx={{
                      "& .MuiSelect-select": {
                        paddingRight: 0,
                        paddingLeft: 0,
                        paddingTop: "1px",
                        paddingBottom: "1px",
                      },
                    }}
                    value={row.value}
                    placeholder={"اختصاص به"}
                    onChange={(e) => assignOperator(row.original._id, e.target.value)}
                  >
                    {operators.map((item) => (
                      <MenuItem value={item._id} key={item._id}>
                        {item.first_name + " " + item.last_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              ),
            },
            // {
            //   Header: "اولویت",
            //   accessor: "priority",
            //   sortable: false,
            //   filterable: false,
            //   Cell: (row) => (
            //     <>
            //       <TextField
            //         select
            //         fullWidth
            //         sx={{
            //           "& .MuiSelect-select": {
            //             paddingRight: 0,
            //             paddingLeft: 0,
            //             paddingTop: "1px",
            //             paddingBottom: "1px",
            //           },
            //         }}
            //         value={row.value}
            //         onChange={(e) => changeTicketPriority(row.original._id, e.target.value)}
            //       >
            //         <MenuItem value={"Immediate"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--chiliRed)" }}></span>
            //             <span>فوری</span>
            //           </div>
            //         </MenuItem>
            //         <MenuItem className={classes.menuItem} value={"High"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--primary)" }}></span>
            //             <span>زیاد</span>
            //           </div>
            //         </MenuItem>
            //         <MenuItem className={classes.menuItem} value={"Normal"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--green)" }}></span>
            //             <span>معمولی</span>
            //           </div>
            //         </MenuItem>
            //         <MenuItem className={classes.menuItem} value={"Low"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--gray)" }}></span>
            //             <span>کم</span>
            //           </div>
            //         </MenuItem>
            //       </TextField>
            //     </>
            //   ),
            // },
            // {
            //   Header: "وضعیت",
            //   accessor: "status",
            //   sortable: false,
            //   filterable: false,
            //   Cell: (row) => (
            //     <>
            //       <TextField
            //         select
            //         fullWidth
            //         sx={{
            //           "& .MuiSelect-select": {
            //             paddingRight: 0,
            //             paddingLeft: 0,
            //             paddingTop: "1px",
            //             paddingBottom: "1px",
            //           },
            //         }}
            //         value={row.value}
            //         onChange={(e) => changeTicketStatus(row.original._id, e.target.value)}
            //       >
            //         <MenuItem value={"Waiting"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--green)" }}></span>
            //             <span>باز</span>
            //           </div>
            //         </MenuItem>
            //         <MenuItem className={classes.menuItem} value={"Answered"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--primary)" }}></span>
            //             <span>پاسخ داده شده</span>
            //           </div>
            //         </MenuItem>
            //         <MenuItem className={classes.menuItem} value={"Close"}>
            //           <div className={classes.menuItem}>
            //             <span className={classes.circle} style={{ backgroundColor: "var(--chiliRed)" }}></span>
            //             <span>بسته</span>
            //           </div>
            //         </MenuItem>
            //       </TextField>
            //     </>
            //   ),
            // },

            {
              Header: "عملیات",
              accessor: "_id",
              sortable: false,
              filterable: false,
              Cell: (row) => (
                <a href={"/admin/open-ticket/" + row.value} target={"_blank"}>
                  <Visibility />
                  مشاهده
                </a>
              ),
            },
          ]}
          pageSize={pagination && pagination.size}
          page={pagination && pagination.page - 1}
          pages={pagination && pagination.total}
          onPageChange={(pageIndex) => {
            //
            onPageChange(pageIndex);
          }}
          onPageSizeChange={(pageSize, pageIndex) => {
            //
            onPageSize(pageSize, pageIndex);
          }}
          // onFilteredChange={(column, value) => {
          //   //
          // }}
          // className="-striped -highlight"
          showPaginationTop={false}
          showPaginationBottom={true}
        />
      )}
      <Box
        sx={{
          width: "200px",
          height: "100%",
          backgroundColor: "var(--white)",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="standard"
          value={value}
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--primary)",
              width: "5px",
              borderRadius: "10px",
            },
          }}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="همه تیکت ها" {...a11yProps(0)} style={{ fontSize: 16 }} />
          {/*<Tab label="پیام باز" {...a11yProps(1)} style={{ fontSize: 16 }} />*/}
          {/*<Tab label="پیام بسته" {...a11yProps(2)} style={{ fontSize: 16 }} />*/}
          {/*<Tab label="پیام پاسخ داده شده" {...a11yProps(3)} style={{ fontSize: 16 }} />*/}
          {/*<Tab label="فرم ها" {...a11yProps(4)} style={{ fontSize: 16 }} />*/}
        </Tabs>
      </Box>
    </div>
  );
}

export default Tickets;
