import { CronJob } from "cron";
import { openDB, deleteDB } from "idb";
const idb = window.indexedDB;
let db_name;
export const createCollectionIndexed = async (dbName) => {
  db_name = dbName;
  await openDB(dbName, 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("messages")) {
        db.createObjectStore("messages", { autoIncrement: true });
      }
    },
  });
};

export const createTransaction = async (data, dbName) => {
  await openDB(db_name, 2, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("messages")) return;
    },
  }).then((db) => {
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const message = messages.put(data);
  });
  // const dbPromise = idb.open(dbName);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   const tx = db.transaction("messages", "readwrite");
  //   const messages = tx.objectStore("messages");
  //   const message = messages.put(data);

  //   message.onsuccess = (event) => {
  //     tx.oncomplete = () => {
  //       db.close();
  //     };
  //   };
  // };
};

export const clearTransaction = (dbName) => {
  window.indexedDB
    .databases()
    .then((r) => {
      for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
    })
    .then(() => {
      // alert('All data cleared.');
    });
  // const dbPromise = idb.open(db_name);
  // dbPromise.onsuccess = () => {
  //   const db = dbPromise.result;
  //   if (!db.objectStoreNames.contains("messages")) return;
  //   const tx = db.transaction("messages", "readwrite");
  //   const messages = tx.objectStore("messages");
  //   const clear = messages.clear();
  //   clear.onsuccess = () => {};
  // };
};

export const updateIndexedDb = (data, setMessagList, dbName) => {
  const dbPromise = idb.open(db_name);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const req = messages.openCursor();

    req.onsuccess = (event) => {
      let cursor = event.target.result;

      if (cursor) {
        if (cursor.value.msg_id === data._id) {
          cursor.value.message = data.message;
          let res = cursor.update(cursor.value);
          res.onerror = function (e) {};
          readDataIndexedDb(setMessagList);
        }
        cursor.continue();
      }
    };
  };
};
export const deleteIndexedDb = (msgId, setMessagList, dbName) => {
  const dbPromise = idb.open(db_name);
  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readwrite");
    const messages = tx.objectStore("messages");
    const req = messages.openCursor();

    req.onsuccess = (event) => {
      let cursor = event.target.result;

      if (cursor) {
        if (cursor.value.msg_id === msgId) {
          let res = cursor.delete();
          res.onerror = function (e) {
            console.log(e);
          };
          readDataIndexedDb(setMessagList);
        }
        cursor.continue();
      }
    };
  };
};
export const readDataIndexedDb = (setMessagList) => {
  if (!db_name) return;
  const dbPromise = idb.open(db_name);

  dbPromise.onsuccess = () => {
    const db = dbPromise.result;
    if (!db.objectStoreNames.contains("messages")) return;
    const tx = db.transaction("messages", "readonly");
    const messages = tx.objectStore("messages");
    const message = messages.getAll();
    message.onsuccess = (query) => {
      setMessagList(query.srcElement.result);
    };
    tx.oncomplete = () => {
      db.close();
    };
  };
};

const job = new CronJob(
  "0 0 3 * * *", // cronTime
  clearTransaction, // onTick
  null, // onComplete
  true, // start
  "Asia/Tehran" // timeZone
);
job.start();
