import React, { useEffect, useState } from "react";
import CustomCard from "shared/CustomCard/CustomCard";
import FixedList from "shared/FixedList/FixedList";
import io from "socket.io-client";
import { Url } from "../../config";

const data = [
  {
    id: 1,
    name: "سعید محمدی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "اپراتور خسروی",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "پی پاد",
      },
      {
        id: 3,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 2,
    name: "علی رحیمی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "اپراتور خسروی",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 3,
    name: "پیمان آزاد",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "پی پاد",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 4,
    name: "حمید قربانی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "پی پاد",
      },
      {
        id: 3,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 5,
    name: "امیر اسدی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "پی پاد",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 6,
    name: "مینا رضایی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "پی پاد",
      },
      {
        id: 3,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
  {
    id: 7,
    name: "نیما شمس",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "اپراتور خسروی",
      },
      {
        id: 3,
        queue_id: "23143241",
        name: "پی پاد",
      },
    ],
  },
  {
    id: 8,
    name: "احمد کریمی",
    menu_list: [
      {
        id: 1,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 2,
        queue_id: "23143241",
        name: "رابین روم",
      },
      {
        id: 3,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 4,
        queue_id: "23143241",
        name: "رابین روم",
      },
      {
        id: 5,
        queue_id: "23143241",
        name: "رابین تاک",
      },
      {
        id: 6,
        queue_id: "23143241",
        name: "رابین روم",
      },
    ],
  },
];

const OnlineOperators = (props) => {
  const [onlineOperators, setOnlineOperators] = useState([]);
  const [tick, setTick] = useState(0);
  const [qsOp, setQsOpr] = useState({});

  useEffect(() => {
    let socket = io.connect(Url);

    // socket.on("online_operators", function (users) {

    //   setQsOpr({});
    //   let items = [];

    //   //
    //   if (users) {
    //     let users_items = users.filter((e) => {
    //       if (e) {
    //         e.menu.map((i) => {
    //           if (!items[i]) items[i] = [];
    //           items[i].push(e);
    //         });
    //       }
    //     });

    //     setQsOpr(items);
    //     setOnlineOperators(users_items);
    // }
    // });

    socket.on("online_operators", function (users) {
      setOnlineOperators(users);
    });
  }, []);

  return (
    <CustomCard style={{ backgroundColor: "#f50057" }} title={"اپراتور های آنلاین"} type={"onlineOperators"}>
      <FixedList operatorData={props.onlineOprts} />
      {/* <FixedList operatorData={null} /> */}
    </CustomCard>
  );
};

export default OnlineOperators;
