import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import ClientLayout from "layouts/Client";
import ClientLayoutBot from "layouts/ClientBot";
import ClientBotsaniar from "layouts/ClientBotsaniar";
import React from "react";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ClientCreateConfrence from "views/Confrence/ClientCreateConfrence";
import store from "./js/store/index";
import * as serviceWorker from "./serviceWorker";
import "./style/main.scss";
import ClientRoomConfrence from "./views/Confrence/ClientRoomConfrence";
import ClientRoomTxt from "./views/Confrence/ClientRoomTxt";
import Loading from "components/Loading";
import LoadingLayout from "layouts/LoadingLayout";
import { createTheme, ThemeProvider } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { mainColor, pageTitle, primaryColor, secondaryColor, secondaryColor2, textColorPrimary } from "web-config";

require("style.css");
const hist = createBrowserHistory();
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "IRANSans",
      direction: "rtl",
    },
  },

  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          textAlign: "right",
        },
      },
    },
  },
});

// تنظیم عنوان صفحه
document.title = pageTitle;

// تنظیم متغیرهای CSS
document.documentElement.style.setProperty("--primary", mainColor);
document.documentElement.style.setProperty("--secondary", secondaryColor);
document.documentElement.style.setProperty("--secondary2", secondaryColor2);
document.documentElement.style.setProperty("--textColor", textColorPrimary);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ToastContainer position="top-right" />
    <Provider store={store}>
      <ClearBrowserCacheBoundary auto fallback={<LoadingLayout />}>
        <RecoilRoot>
          <Router history={hist}>
            <Switch>
              <Route path="/auth" render={(props) => <AuthLayout {...props} store={store} />} />
              <Route path="/client-bot" component={ClientLayoutBot} store={store} />
              <Route path="/client-bo" component={ClientBotsaniar} />
              {/* <Route path="/client" component={ClientLayout} store={store} /> */}
              <Route path="/client" render={(props) => <ClientLayout {...props} store={store} />} />
              <Route path="/api/clint/room/:id" component={ClientRoomConfrence} />
              <Route path="/apii/clint/room/txt/:id" component={ClientRoomTxt} />
              <Route path="/admin" render={(props) => <AdminLayout {...props} store={store} />} />
              <Route path="/api/clientconf/:uid" component={ClientCreateConfrence} />

              <Redirect from="/admin" to="/admin/dashboard" />
              <Redirect from="/client" to="/admin" />
              <Redirect from="/" to="/admin" />
              {/*<Redirect from="/" to="/client/welcome" />*/}
            </Switch>
          </Router>
        </RecoilRoot>
      </ClearBrowserCacheBoundary>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
serviceWorker.register();

{
  /* <Route path="/client" component={ClientLayout} store={store}/> */
}
