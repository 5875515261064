import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import CustomerAction from "../../js/actions/customer_action";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function EditCustomer(props) {
  const getCustomer = () => {
    let id = props.match.params.id;
    axios
      .get(ApiUrl + "/get-customer-problem/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setData({
          email: response.data.data.customer.email,
          first_name: response.data.data.customer.first_name,
          last_name: response.data.data.customer.last_name,
          national_code: response.data.data.customer.national_code,
          mobile: response.data.data.customer.mobile,
          vip: response.data.data.customer.vip,
        });

        response.data.data.customer.vip ? setValue("true") : setValue("false");
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    type: "",
  });
  useEffect(() => {
    getCustomer();
  }, []);

  ///////////////
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    //
    setValue(event.target.value);
  };
  /////////////

  ////
  const [group, setgroup] = React.useState("");
  const customStyles = {
    control: (base) => ({
      ...base,
      height: "auto",
      minHeight: 55,
      marginTop: "30px",
      //minWidth: "600px"
    }),
  };
  let groupoptions = [
    { value: "1", label: "گروه 1" },
    { value: "2", label: "گروه 2" },
  ];
  let handleChangeGroup = (newValue, actionMeta) => {
    setgroup(newValue);
  };
  /////

  function onSubmit(e) {
    e.preventDefault();
    let id = props.match.params.id;
    let groupLabels = [];
    if (group && group.length) {
      group.forEach((item) => {
        groupLabels.push(item.label);
      });
    }
    props.store.dispatch(CustomerAction.editCustomer(data, id, value, groupLabels)).then(function (ec) {
      // window.location.href = "/admin/customers"
    });
  }

  function handle_change(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  const classes = useStyles();

  if (props.loading) return <div>...در حال ارسال</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ویرایش مشتری - {pageTitle}</title>
      </Helmet>
      <form onSubmit={(e) => onSubmit(e)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle} color={"var(--textColor)"}>
                    ویرایش عضو جدید
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>ایمیل</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "email",
                          name: "email",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.email,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نام</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "first_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.first_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={3}>
                      <FormLabel className={classes.labelHorizontal}>نام خانوادگی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={9}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "last_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.last_name,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">نوع عضویت:</FormLabel>
                        <RadioGroup aria-label="type" name="type" value={value} onChange={handleChange}>
                          <FormControlLabel value={"true"} control={<Radio />} label="عضویت ویژه" />
                          <FormControlLabel value={"false"} control={<Radio />} label="عضویت معمولی" />
                        </RadioGroup>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>گروه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <CreatableSelect
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        noOptionsMessage={() => null}
                        onChange={handleChangeGroup}
                        options={groupoptions}
                        styles={customStyles}
                        defaultValue={group}
                        placeholder="گروه عضویت"
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"submit"} color="rose">
                  ذخیره
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.customer.data,
  loading: state.customer.loading,
  error: state.customer.error,
});

const mapDispatchToProps = (dispatch) => ({
  CustomerAction: bindActionCreators({ CustomerAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
