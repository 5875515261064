import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ApiUrl, Url } from "../../config";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import CreatableSelect from "react-select/creatable";
import { ReactCrop, Crop } from "react-image-crop";
import Loading from "components/Loading";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    width: "150px",
    height: "200px",
    padding: "20px",
    background: "var(--gray)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: "5px",
    position: "relative",
  },
  image: {
    objectFit: (props) => "cover",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    transition: ".5s",
    filter: "brightness(45%)",
    "&:hover": {
      transform: "scale(105%)",
      transition: ".5s",
    },
  },
});

function CardView({ img = "", title = "", text = "", data = {}, api, setReload }) {
  const classes = useStyles();
  const history = useHistory();
  const [queue, setQueue] = useState({});
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("");
  const [dataSelectQueue, setDataSelectQueue] = useState([]);
  const [alert, setAlert] = useState(null);
  const [crop, setCrop] = useState({ aspect: 4 / 3 });
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState({
    _id: "",
    title: "",
    text: "",
    is_main: false,
    image: "",
    menu: "",
  });
  const updateHandler = async () => {
    setLoading(true);
    if (selectedFile) {
      const formData = new FormData();
      formData.append("image", edit.image, edit.image.name);
      await axios
        .patch(ApiUrl + `${api}/${data._id}`, formData, { headers: { Authorization: localStorage.getItem("token") } })
        .then((res) => {
          toast.success("با موفقیت ویرایش شد");
          setReload(true);
        })
        .catch((res) => {
          toast.error("خطا در ویرایش");
        })
        .finally(() => setLoading(false));
    } else {
      await axios
        .patch(ApiUrl + `${api}/${data._id}`, edit, { headers: { Authorization: localStorage.getItem("token") } })
        .then((res) => {
          toast.success("با موفقیت ویرایش شد");
          setReload(true);
        })
        .catch((res) => {
          toast.error("خطا در ویرایش");
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setEdit({ ...data });
  }, []);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    console.log(selectedFile);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
    setEdit({ ...edit, menu: newValue.value });
  };
  const customStyles = {
    valueContainer: () => ({
      padding: 10,
      display: "flex",
      alignItems: "center",
    }),
    control: (base) => ({
      ...base,
      height: "100%",
      width: "100%",
      border: "1px solid black",
    }),
    menu: (base) => ({
      ...base,
      position: "relative",
    }),
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const options = [];
        res.data.data.forEach((item) => {
          options.push({
            value: item._id,
            label: item.name === "کیپاد" ? "پی پاد" : item.name,
          });
        });
        setDataSelectQueue(options);
        const dataIndex = options.findIndex((x) => x.value === data.menu);
        setQueue(options[dataIndex]);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    getQueues();
    setEdit({
      ...data,
    });
  }, []);

  const confirm = async () => {
    updateHandler();
    setOpen(false);
  };
  const editItem = (e) => {
    const { name, value } = e.target;
    setEdit({ ...edit, [name]: value });
  };
  const deleteHandler = async () => {
    setLoading(true);
    await axios
      .patch(
        ApiUrl + `${api}/` + data._id,
        { status: false },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        toast.success("با موفقیت غیرفعال شد");
        setReload(true);
      })
      .catch((res) => {
        toast.error("خطا در انجام عملیات");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editHandler = (e) => {
    setOpen(!open);
  };
  const showEditInput = () => {
    return (
      <SweetAlert
        style={{ display: "block" }}
        onConfirm={() => confirm()}
        confirmBtnText="تایید"
        cancelBtnText="انصراف"
        showCancel={true}
        onCancel={() => setOpen(false)}
      >
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"5px"} sx={{ direction: "rtl" }}>
          <textarea
            name="title"
            value={edit.title}
            placeholder="عنوان"
            onChange={editItem}
            style={{
              padding: "10px",
              outline: "none",
              resize: "none",
              borderRadius: "4px",
              fontSize: "12px",
            }}
          />
          {data.text && (
            <textarea
              name="text"
              value={edit.text}
              placeholder="محتوا"
              onChange={editItem}
              style={{
                padding: "10px",
                outline: "none",
                resize: "none",
                borderRadius: "4px",
                fontSize: "12px",
              }}
            />
          )}
          {preview ? (
            <img
              style={{ maxHeight: "150px", objectFit: "none", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={preview}
              alt=""
            />
          ) : (
            <img
              style={{ maxHeight: "150px", objectFit: "contain", objectPosition: "center" }}
              width={"100%"}
              height={"fit-content"}
              src={Url + edit.image}
              alt=""
            />
          )}
          <Button
            variant={"text"}
            component={"label"}
            style={{
              width: "100%",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
              hidden
              onChange={(e) => {
                setEdit({ ...edit, image: e.target.files[0] });
                setSelectedFile(e.target.files[0]);
              }}
            />
            تغییر عکس
          </Button>
          <Button
            style={{
              width: "100%",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
            onClick={(e) => history.push("/admin/add-news-gallery/" + data._id)}
          >
            ویرایش گالری
          </Button>
        </Box>
      </SweetAlert>
    );
  };
  return (
    <div className={classes.container}>
      {open && showEditInput()}
      <img className={classes.image} src={Url + img} alt={title} />
      <Box width={"100%"} overflow={"hidden"} zIndex={999}>
        <Typography fontWeight={400} fontSize={12} color={"var(--white)"} textAlign={"right"}>
          {edit.title}
        </Typography>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        gap={"10px"}
        alignItems={"center"}
        position={"absolute"}
        bottom={5}
      >
        <Button
          sx={{
            backgroundColor: "var(--chiliRed)",
            color: "var(--white)",
            fontSize: "12px",
            border: "1px solid var(--gray)",
          }}
          onClick={deleteHandler}
        >
          غیرفعال
        </Button>
        <Button
          sx={{
            backgroundColor: "var(--primary)",
            color: "var(--white)",
            fontSize: "12px",
            border: "1px solid var(--gray)",
          }}
          onClick={(e) => setOpen(true)}
        >
          ویرایش
        </Button>
      </Box>
      {loading && (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
          left={0}
          right={0}
          marginTop={"auto"}
          marginBottom={"auto"}
        >
          <Loading showText={false} />
        </Box>
      )}
    </div>
  );
}

export default CardView;
