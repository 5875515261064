// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import ReactTable from "react-table";
import ClientAction from "../../js/actions/client_action";
import { pageTitle } from "web-config";

//

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  const { loading } = props;

  useEffect(() => {
    let id = props.match.params.id;
    props.store.dispatch(ClientAction.GetHistoryClientCall(id));
  }, loading);

  const [dataTable, setdataTable] = React.useState([]);

  useEffect(() => {
    //
    let menusList = [];
    let allCall = 0;
    if (props.client && props.client[0]) {
      let client = props.client[0];
      client.callByMobile &&
        client.callByMobile.forEach((item) => {
          if (item.nationalCode == client.nationalCode) {
            if (item.menu) {
              let el = menusList.find((el) => el.menu == item.menu[0]);
              if (el == undefined) {
                let tempObj = {};
                let tempMenu = {};
                tempObj.menu = item.menu[0];
                tempObj.number = 1;
                tempMenu.status = item.status;
                tempMenu.number = 1;
                tempObj.statusList = [tempMenu];
                menusList.push(tempObj);
              } else {
                let index = menusList.indexOf(el);
                menusList[index].number++;
                let statusItem = el.statusList.find((s) => s.status == item.status);
                //
                if (statusItem == undefined) {
                  let tempMenu = {};
                  tempMenu.status = item.status;
                  tempMenu.number = 1;
                  el.statusList.push(tempMenu);
                } else {
                  let indexStatus = el.statusList.indexOf(statusItem);
                  //
                  el.statusList[indexStatus].number++;
                }
              }
            }
            allCall++;
          }
        });
      setdataTable(menusList);
      //
    }
  }, props.client);

  const classes = useStyles();
  if (loading) return "loading";

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش اپراتور ها - {pageTitle}</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>گزارش اپراتور ها</h4>
            </CardHeader>

            <CardBody>
              <ReactTable
                rtl
                getTableProps={() => ({ style: { display: "block", textAlign: "right" } })}
                data={dataTable}
                loading={props.loading}
                pagination={false}
                showPagination={false}
                filterable
                manual
                columns={[
                  {
                    Header: "صف",
                    accessor: "menu",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تعداد کل تماس",
                    accessor: "number",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "تماس های متنی",
                    accessor: "statusList",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.value.map((e) => {
                          if (e.status == "calling-chat" || e.status == "answer-chat" || e.status == "rejected-chat") {
                            return (
                              <div className={"parts"}>
                                <span>
                                  {e.status == "calling-chat"
                                    ? "تماس بی پاسخ"
                                    : e.status == "answer-chat"
                                    ? "تماس پاسخ داده شده"
                                    : "تماس قطع شده"}
                                </span>
                                <span>{e.number}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ),
                  },
                  {
                    Header: "تماس های تصویری",
                    accessor: "statusList",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.value.map((e) => {
                          if (e.status == "calling" || e.status == "answer" || e.status == "rejected") {
                            return (
                              <div className={"parts"}>
                                <span>
                                  {e.status == "calling"
                                    ? "تماس بی پاسخ"
                                    : e.status == "answer"
                                    ? "تماس پاسخ داده شده"
                                    : "تماس قطع شده"}
                                </span>
                                <span>{e.number}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ),
                  },
                ]}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  //
  return {
    loading: state.client.loading,
    error: state.client.error,
    client: state.client.history,
    operators: state.user.operators,
  };
};

export default connect(mapStateToProps)(Histories);
