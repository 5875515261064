import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../config";
import {
  Block,
  Brightness1,
  Cancel,
  CancelOutlined,
  Close,
  DeleteOutlined,
  DraftsOutlined,
  EmailOutlined,
  Note,
  NoteOutlined,
  PinDrop,
  Visibility,
} from "@material-ui/icons";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import ReactTable from "react-table";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import { Divider } from "@material-ui/core";
import DatePicker, { Calendar, DateObject, getAllDatesInRange } from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import size from "react-element-popper/animations/size";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import moment from "jalali-moment";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    gap: "16px",
  },
  datePicker: {
    width: "100% !important",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px !important",
  },
  table: {
    // overflow: "hidden",
    width: "100%",
    height: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
    borderRadius: "10px !important",
    minHeight: "400px",
    maxHeight: "calc(100vh - 200px)",
  },
  thead: {
    backgroundColor: "var(--white)",
    height: "40px",
    border: "none !important",
    boxShadow: "none !important",
  },
  tbody: {
    // border:"none"
  },
  tr: {
    height: "40px",
    border: "none",
  },
  td: {
    height: "30px",
    border: "none",
  },
  pagination: {
    backgroundColor: "var(--white)",
    borderRadius: "10px ",
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
  },
  circle: {
    backgroundColor: "var(--green)",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    left: 2,
    top: 5,
  },
});

function ListAppointments(props) {
  const initPerson = {
    firstName: "",
    lastName: "",
    mobile: "",
    comment: "",
    id: "",
  };
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [pagination, setPagination] = useState("");
  const [page, setPage] = useState({ size: 20, page: 1 });
  const [operators, setOperators] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [person, setPerson] = useState(initPerson);
  const [menu, setMenu] = useState([]);
  const [filter, setFilter] = useState({
    menu: "all",
    doctor: "all",
    date: "",
    is_use: "all",
  });

  const handleOpenModal = (data) => {
    setOpenModal(true);
    console.log(data);
    setPerson({
      firstName: data?.booking?.client.first_name,
      lastName: data?.booking?.client.last_name,
      mobile: data?.booking?.client.mobile,
      comment: data.comment,
    });
    setModalData(data);
  };
  const getData = async () => {
    setLoading(true);
    await axios
      .get(ApiUrl + `/get-booked-appts?size=${page.size}&page=${page.page}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        // setLoading(false);
        // setData(res.data.data.histories);
        // setPagination(res.data.data.pagination);
      });
  };
  const getOperators = async () => {
    await axios
      .get(ApiUrl + "/get-operators", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setOperators(res.data.data);
      });
  };
  const getMenu = async () => {
    await axios
      .get(ApiUrl + "/menu-items?status=true", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setMenu(res.data.data.menu_items);
      });
  };
  const getDefinedAppts = async () => {
    setLoading(true);
    await axios
      .get(
        ApiUrl +
          `/defined-appts-filter?size=${page.size}&page=${page.page}&is_use=${
            filter.is_use === "all" ? "" : filter.is_use
          }&menu=${filter.menu === "all" ? "" : filter.menu}&doctor=${
            filter.doctor === "all" ? "" : filter.doctor
          }&date=${filter.date === "all" ? "" : filter.date}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.data.histories);
        setLoading(false);
        setData(res.data.data.histories);
        setPagination(res.data.data.pagination);
      })
      .finally((res) => {
        setLoading(false);
      });
  };
  const onPageChange = (pageIndex) => {
    setPage({ ...page, page: pageIndex + 1 });
  };
  const onPageSize = (pageSize, pageIndex) => {
    setPage({ ...page, size: pageSize });
  };
  const onFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };
  const onModalChange = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.value });
  };
  const onModalCancel = () => {
    setModalData(null);
    setPerson(initPerson);
    setOpenModal(false);
  };
  const deleteAppt = async () => {
    await axios
      .delete(ApiUrl + "/delete-appt/" + modalData._id, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        toast.success("نوبت با موفقیت حذف شد");
        onModalCancel();
        getDefinedAppts();
      })
      .catch((res) => {
        toast.error("خطا در حذف نوبت");
      });
  };
  const cancelAppt = async () => {
    await axios
      .delete(ApiUrl + "/cancel-appt-op/" + modalData?.booking?._id, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success("نوبت با موفقیت لغو شد");
        getDefinedAppts();
        onModalCancel();
      })
      .catch((res) => {
        toast.error("خطا در لغو نوبت");
      });
  };
  const addBookedAppt = async () => {
    await axios
      .post(
        ApiUrl + "/book-appt",
        {
          ...person,
          id: modalData._id,
        },
        { headers: { Authorization: localStorage.getItem("token") } }
      )
      .then((res) => {
        getDefinedAppts();
        toast.success(res.data.message);
        onModalCancel();
      })
      .catch((err) => {})
      .finally(() => {});
  };
  useEffect(() => {
    getOperators();
    getMenu();
  }, []);
  useEffect(() => {
    // getData();
    getDefinedAppts();
  }, [page, filter]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap", justifyContent: "center" }}>
        <TextField
          select
          name={"doctor"}
          onChange={onFilterChange}
          value={filter.doctor}
          label={"اپراتور"}
          InputLabelProps={{ shrink: true }}
          sx={{
            minWidth: "47%",
            direction: "rtl",
            "& .MuiFormLabel-root": {
              top: "-20px !important",
            },
          }}
        >
          <MenuItem value={"all"}>همه</MenuItem>
          {operators.map((item, index) => (
            <MenuItem key={index} value={item._id}>
              {item.first_name + " " + item.last_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          name={"menu"}
          value={filter.menu}
          onChange={onFilterChange}
          label={"صف"}
          InputLabelProps={{ shrink: true }}
          sx={{
            minWidth: "47%",
            direction: "rtl",
            "& .MuiFormLabel-root": {
              top: "-20px !important",
            },
          }}
        >
          <MenuItem value={"all"}>همه</MenuItem>
          {menu.map((item, index) => (
            <MenuItem key={index} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          onChange={onFilterChange}
          name={"is_use"}
          value={filter.is_use}
          label={"وضعیت نوبت"}
          InputLabelProps={{ shrink: true }}
          sx={{
            minWidth: "47%",
            direction: "rtl",
            "& .MuiFormLabel-root": {
              top: "-20px !important",
            },
          }}
        >
          <MenuItem value={"all"}>همه</MenuItem>
          <MenuItem value={true}>رزرو</MenuItem>
          <MenuItem value={false}>آزاد</MenuItem>
        </TextField>
        <DatePicker
          inputClass={classes.datePicker}
          containerStyle={{
            width: "47%",
            padding: "4px",
            borderRadius: "4px",
            border: "1px solid var(--gray)",
          }}
          onChange={(e) =>
            setFilter({
              ...filter,
              date: moment(e.unix * 1000).format("YYYY-MM-DD"),
            })
          }
          placeholder={"تاریخ"}
          calendar={persian}
          locale={persian_fa}
          // minDate={new DateObject().toFirstOfMonth()}
          // maxDate={new DateObject().toLastOfMonth()}
          calendarPosition="bottom-center"
        />
      </Box>
      <ReactTable
        rtl
        data={data}
        loading={loading}
        loadingText={"در  حال بارگذاری"}
        style={{ borderRadius: "10px ", width: "100%" }}
        pagination={true}
        manual
        defaultPageSize={20}
        className={"bg-white "}
        getTableProps={() => ({ className: classes.table })}
        getTheadProps={() => ({ className: classes.thead })}
        getResizerProps={() => ({ className: classes.thead })}
        getTrProps={() => ({ className: classes.tr })}
        getTdProps={() => ({ className: classes.td })}
        getTbodyProps={() => ({ className: classes.tbody })}
        getPaginationProps={() => ({ className: classes.pagination })}
        filterable={true}
        rowsText={"سطر"}
        pageText={"صفحه"}
        nextText={"بعدی"}
        previousText={"قبلی"}
        ofText={"از"}
        noDataText={"نوبتی پیدا نشد"}
        columns={[
          {
            Header: "وضعیت",
            sortable: false,
            accessor: "is_use",
            filterable: false,
            Cell: (row) => <div>{row.value ? <Typography>رزرو</Typography> : <Typography>آزاد</Typography>}</div>,
          },
          {
            Header: "اپراتور",
            sortable: false,
            accessor: "doctor",
            filterable: false,
            Cell: (row) => <div>{row.value.first_name + " " + row.value.last_name}</div>,
          },
          {
            Header: "صف",
            sortable: false,
            accessor: "menu",
            filterable: false,
            Cell: (row) => <div>{row.value.name}</div>,
          },
          {
            Header: "موبایل",
            sortable: false,
            accessor: "booking.client.mobile",
            filterable: false,
            Cell: (row) => <div>{!row.value ? "---" : row.value}</div>,
          },
          {
            Header: "نام کاربر",
            sortable: false,
            accessor: "_id",
            filterable: false,
            Cell: (row) => (
              <div>
                {row.original.booking
                  ? row.original.booking.client?.first_name + " " + row.original.booking.client?.last_name
                  : "---"}
              </div>
            ),
          },
          {
            Header: "قیمت",
            accessor: "price",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value}</div>,
          },
          {
            Header: "تاریخ نوبت",
            accessor: "jalaly_date",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value}</div>,
          },
          {
            Header: "زمان نوبت",
            accessor: "time",
            sortable: false,
            filterable: false,
            Cell: (row) => <div>{row.value}</div>,
          },

          {
            Header: "عملیات",
            accessor: "_id",
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <Button
                sx={{ padding: "2px 5px" }}
                onClick={(e) => handleOpenModal(row.original)}
                startIcon={<Visibility style={{ marginLeft: "5px" }} />}
              >
                مشاهده
              </Button>
            ),
          },
        ]}
        pageSize={pagination && pagination.size}
        page={pagination && pagination.page - 1}
        pages={pagination && pagination.total}
        onPageChange={(pageIndex) => {
          //
          onPageChange(pageIndex);
        }}
        onPageSizeChange={(pageSize, pageIndex) => {
          //
          onPageSize(pageSize, pageIndex);
        }}
        // onFilteredChange={(column, value) => {
        //   //
        // }}
        // className="-striped -highlight"
        showPaginationTop={false}
        showPaginationBottom={true}
      />
      <Modal open={openModal} onClose={onModalCancel}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            backgroundColor: "var(--white)",
            boxShadow: 24,
            height: 500,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              direction: "rtl",
            }}
          >
            <Typography fontSize={"1.5rem"} fontWeight={500} width={"100%"}>
              اطلاعات نوبت
            </Typography>
            <Divider orientation={"horizontal"} style={{ width: "100%" }} />
            <TextField
              label={"نام"}
              required
              onChange={onModalChange}
              name={"firstName"}
              value={person.firstName}
              InputLabelProps={{ shrink: true }}
              sx={{
                minWidth: "300px !important",
                backgroundColor: "var(--white)",
                direction: "rtl",
                "& .MuiFormLabel-root": {
                  top: "-20px !important",
                },
              }}
            />
            <TextField
              label={"نام خانوادگی"}
              name={"lastName"}
              required
              onChange={onModalChange}
              value={person.lastName}
              InputLabelProps={{ shrink: true }}
              sx={{
                minWidth: "300px !important",
                backgroundColor: "var(--white)",
                direction: "rtl",
                "& .MuiFormLabel-root": {
                  top: "-20px !important",
                },
              }}
            />
            <TextField
              label={"شماره تلفن"}
              required
              name={"mobile"}
              onChange={onModalChange}
              value={person.mobile}
              InputLabelProps={{ shrink: true }}
              sx={{
                minWidth: "300px !important",
                backgroundColor: "var(--white)",
                direction: "rtl",
                "& .MuiFormLabel-root": {
                  top: "-20px !important",
                },
              }}
            />
            <TextField
              label={"توضیحات"}
              onChange={onModalChange}
              value={person.comment}
              InputLabelProps={{ shrink: true }}
              name={"comment"}
              sx={{
                minWidth: "300px !important",
                backgroundColor: "var(--white)",
                direction: "rtl",
                "& .MuiFormLabel-root": {
                  top: "-20px !important",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={deleteAppt}
              disabled={modalData?.is_use}
              startIcon={<DeleteOutlined style={{ marginLeft: "8px" }} />}
              sx={{
                background: "var(--chiliRed)",
                fontSize: "1.2rem",
                color: "var(--white)",
                "&:hover": { color: "var(--black)" },
              }}
            >
              حذف نوبت
            </Button>
            <Button
              onClick={cancelAppt}
              disabled={modalData?.is_use !== true}
              startIcon={<CancelOutlined style={{ marginLeft: "8px" }} />}
              sx={{
                background: "var(--orange)",
                fontSize: "1.2rem",
                color: "var(--white)",
                "&:hover": { color: "var(--black)" },
              }}
            >
              لغو نوبت
            </Button>
            <Button
              onClick={addBookedAppt}
              disabled={modalData?.is_use}
              startIcon={<NoteOutlined style={{ marginLeft: "8px" }} />}
              sx={{
                background: "var(--primary)",
                fontSize: "1.2rem",
                color: "var(--white)",
                "&:hover": { color: "var(--black)" },
              }}
            >
              رزرو نوبت
            </Button>
          </Box>
          <Close
            fontSize={"large"}
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              backgroundColor: "var(--white)",
              boxShadow: "0 0 4px rgba(0,0,0,.5)",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={onModalCancel}
          />
        </Box>
      </Modal>
    </Box>
  );
}

export default ListAppointments;
