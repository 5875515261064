import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import moment from "moment-jalaali";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { connect } from "react-redux/";
import CreatableSelect from "react-select/creatable";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import ReportAction from "../../js/actions/report_action";
import UserAction from "../../js/actions/user_action";
import Spinner from "../../shared/Spinner/Spinner";
import Reset from "./../../assets/img/reset.png";
import { pageTitle } from "web-config";

const axios = require("axios");

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Histories(props) {
  //

  const { loading, pagination } = props;
  useEffect(() => {
    getOperators();
    getAllReports();
    props.store.dispatch(ReportAction.GetAllQueues());
  }, loading);
  useEffect(() => {
    let data = props.operators;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.first_name + " " + item.last_name,
        });
      });
      setDataSelectOperator(options);
    }
    //
  }, props.operators);
  const getOperators = () => {
    return props.store.dispatch(UserAction.GetOperators());
  };
  const getAllReports = (pagination = { per_page: 20, page: 1 }) => {
    return props.store.dispatch(ReportAction.GetAllQueueEnterExit(pagination));
  };
  const getFilterReports = (pagination = { per_page: 20, page: 1 }) => {
    let arrayOperatorsId = [];
    if (operator) {
      operator.forEach((item) => {
        arrayOperatorsId.push(item.value);
      });
    }
    let typeEnterEXitArray = [];
    if (typeEnterEXit) {
      typeEnterEXit.forEach((item) => {
        typeEnterEXitArray.push(item.value);
      });
    }
    //
    let reasonArray = [];
    if (reason && reason.length) {
      reason.forEach((item) => {
        reasonArray.push(item.label);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    //
    props.store.dispatch(
      ReportAction.GetEnterEXitQueueReports(
        pagination,
        arrayOperatorsId,
        typeEnterEXitArray,
        selectedDayRange,
        timeStart,
        timeEnd,
        reasonArray,
        queuesId
      )
    );
  };

  const handleSimple = (event) => {
    setSimpleSelect(event.target.value);
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [dataSelectOperator, setDataSelectOperator] = React.useState([]);
  const [operator, setOperator] = React.useState("");
  const [flag, setFlag] = React.useState("ALL");
  const [reason, setReason] = React.useState("");
  const [typeEnterEXit, setTypeEnterEXit] = React.useState("");

  let handleChange = (newValue, actionMeta) => {
    setOperator(newValue);
  };
  let handleChangeType = (newValue, actionMeta) => {
    setTypeEnterEXit(newValue);
  };
  let handleChangeReason = (newValue, actionMeta) => {
    setReason(newValue);
  };

  ////
  useEffect(() => {
    let data = props.queues;
    let options = [];
    if (data) {
      data.forEach((item) => {
        options.push({
          value: item._id,
          label: item.name === "کیپاد" ? "پی پاد" : item.name,
        });
      });
      setDataSelectQueue(options);
    }
  }, [props.queues]);
  const [queue, setQueue] = React.useState("");
  const [dataSelectQueue, setDataSelectQueue] = React.useState([]);
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  ////

  //////////RangePicker
  const { RangePicker } = TimePicker;
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
  function onChange(time, timeString) {
    //
    settimeStart(timeString[0]);
    settimeEnd(timeString[1]);
  }
  ////////////RangePicker

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 55,
      border: "1px solid black",
    }),
  };
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const renderCustomInput = ({ ref }) => (
    <>
      <input
        readOnly
        ref={ref} // necessary
        placeholder="زمان گزارش را انتخاب کنید"
        value={
          selectedDayRange.from && selectedDayRange.to
            ? `${selectedDayRange.from.year}` +
              "/" +
              `${selectedDayRange.from.month}` +
              "/" +
              `${selectedDayRange.from.day}` +
              " تا " +
              `${selectedDayRange.to.year}` +
              "/" +
              `${selectedDayRange.to.month}` +
              "/" +
              `${selectedDayRange.to.day}`
            : ""
        }
        style={{
          textAlign: "center",
          padding: "8px",
          fontSize: "1rem",
          border: "1px solid ",
          borderRadius: "5px",
          outline: "none",
          height: 55,
          minHeight: 35,
          width: 500,
        }}
        className="my-custom-input-class" // a styling class
      />
      <a>
        <img
          src={Reset}
          style={{ width: "30px", height: "30px" }}
          onClick={() => {
            setSelectedDayRange({
              from: null,
              to: null,
            });
          }}
        />
      </a>
    </>
  );

  const [size, setSize] = React.useState(20);
  const onPageChangeFunction = (pageIndex) => {
    //
    if (flag === "ALL") {
      getAllReports({ per_page: size, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterReports({ per_page: size, page: pageIndex + 1 });
    }
  };
  const onPageSizeChangeFunction = (pageSize, pageIndex) => {
    //
    setSize(pageSize);
    if (flag === "ALL") {
      getAllReports({ per_page: pageSize, page: pageIndex + 1 });
    }
    if (flag === "FILTER") {
      getFilterReports({ per_page: 20, page: pageIndex + 1 });
    }
  };

  const getFileExcell = () => {
    let dateG_from;
    let dateG_to;
    if (selectedDayRange.to) {
      let dateFromString =
        selectedDayRange.from.year + "-" + selectedDayRange.from.month + "-" + selectedDayRange.from.day;
      let dateToString = selectedDayRange.to.year + "-" + selectedDayRange.to.month + "-" + selectedDayRange.to.day;
      dateG_from = moment(dateFromString, "jYYYY-jM-jD").format("YYYY-MM-DD");
      dateG_to = moment(dateToString, "jYYYY-jM-jD").format("YYYY-MM-DD");
    }
    let arrayOperatorsId = [];
    if (operator) {
      operator.forEach((item) => {
        arrayOperatorsId.push(item.value);
      });
    }
    let typeEnterEXitArray = [];
    if (typeEnterEXit) {
      typeEnterEXit.forEach((item) => {
        typeEnterEXitArray.push(item.value);
      });
    }
    let reasonArray = [];
    if (reason && reason.length) {
      reason.forEach((item) => {
        reasonArray.push(item.label);
      });
    }
    let queuesId = [];
    if (queue) {
      queue.forEach((item) => {
        queuesId.push(item.value);
      });
    }
    axios({
      url: ApiUrl + "/reports-queue-enter-exit-excell",
      method: "post",
      params: {},
      data: {
        dateTo: dateG_to,
        dateFrom: dateG_from,
        operatorsId: arrayOperatorsId,
        typeEnterExit: typeEnterEXitArray,
        timeStart,
        timeEnd,
        reasonArray,
        queuesId,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.xlsx");
        link.click();
      })
      .catch(() => {
        toast.error("دانلود فایل با خطا مواجه شد", { position: toast.POSITION.TOP_CENTER });
      });
  };

  const classes = useStyles();
  if (loading) return <Spinner />;

  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>گزارش ورود و خروج - {pageTitle}</title>
      </Helmet>
      <GridContainer>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={5} style={{ margin: "24px", marginLeft: "0px", marginButton: "0px" }}>
              <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                isClearable
                onChange={handleChange}
                options={dataSelectOperator}
                styles={customStyles}
                defaultValue={operator}
                placeholder="اپراتور مورد نظر را انتخاب کنید"
              />
            </Grid>
            <Grid item xs={6} style={{ margin: "24px", marginButton: "0px" }}>
              <DatePicker
                value={selectedDayRange}
                renderInput={renderCustomInput}
                onChange={setSelectedDayRange}
                inputPlaceholder="زمان گزارش"
                shouldHighlightWeekends
                calendarPopperPosition="bottom"
                locale="fa"
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                marginBottom: "24px",
                marginRight: "24px",
                marginTop: "0px",
              }}
            >
              <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                isClearable
                onChange={handleChangeReason}
                options={[
                  { value: "1", label: "قطع اتصال" },
                  { value: "2", label: "استراحت" },
                  { value: "3", label: "آموزش" },
                  { value: "4", label: "پیگیری" },
                  { value: "5", label: "امور محوله" },
                  { value: "6", label: "پایان شیفت" },
                ]}
                styles={customStyles}
                placeholder="علت خروج را انتخاب کنید"
                defaultValue={reason}
              />
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                marginBottom: "24px",
                marginRight: "24px",
                marginTop: "0px",
              }}
            >
              <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                isClearable
                onChange={handleChangeType}
                options={[
                  { value: "enter", label: "ورود" },
                  { value: "exit", label: "خروج" },
                ]}
                styles={customStyles}
                placeholder="نوع ورود و خروج را انتخاب کنید"
                defaultValue={typeEnterEXit}
              />
            </Grid>

            <Grid item xs={5} style={{ margin: "24px", marginBottom: "0px", marginTop: "0px" }}>
              <Box>
                <CreatableSelect
                  isMulti
                  closeMenuOnSelect={false}
                  isClearable
                  onChange={handleChangeQueue}
                  options={dataSelectQueue}
                  styles={customStyles}
                  defaultValue={queue}
                  placeholder="صف مورد نظر خود را انتخاب کنید"
                />
              </Box>
            </Grid>

            <Grid item xs={5} style={{ margin: "0  24px 24px" }}>
              <RangePicker
                onChange={onChange}
                placeholder={["زمان آغاز", "زمان پایان"]}
                getPopupContainer={(trigger) => trigger.parentElement}
                defaultValue={[moment(timeStart, "HH:mm:ss"), moment(timeEnd, "HH:mm:ss")]}
                defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                style={{
                  height: "55px",
                  width: "300px",
                  border: "1px solid black",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "17px",
                }}
              />
            </Grid>

            <Grid item xs={6} style={{ marginBottom: "24px", marginRight: "42px" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  setFlag("FILTER");
                  getFilterReports({ per_page: 20, page: 1 });
                }}
              >
                اعمال
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  setFlag("ALL");
                  getAllReports();
                }}
              >
                دریافت همه گزارشات
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                name="buttonname"
                style={{ height: 55 }}
                onClick={() => {
                  getFileExcell();
                }}
              >
                دریافت فایل
              </Button>
            </Grid>
          </Grid>
        </Card>

        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
            </CardHeader>
            <CardBody>
              <ReactTable
                rtl
                data={props.enterExitQueue ? props.enterExitQueue : []}
                loading={props.loading}
                pagination={true}
                filterable
                manual
                columns={[
                  {
                    Header: " اپراتور",
                    accessor: "operator",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => {
                      let showName = "";
                      dataSelectOperator &&
                        dataSelectOperator.forEach((item) => {
                          //
                          //
                          if (item.value === row.value) {
                            //
                            showName = item.label;
                          }
                        });
                      return showName;
                    },
                  },
                  {
                    Header: "نوع",
                    accessor: "type",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => <div>{row.value === "enter" ? "ورود" : " خروج"}</div>,
                  },

                  {
                    Header: "تاریخ",
                    accessor: "date",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>{moment(row.value, "YYYY-MM-DDTHH:mm:ss.sTZD").format("jYYYY/jM/jD HH:mm:ss")}</div>
                    ),
                  },
                  {
                    Header: "علت",
                    accessor: "reason",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "صف",
                    accessor: "menus",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div>
                        {row.value.map((item, index) => {
                          return <div key={index}> {item.name}</div>;
                        })}
                      </div>
                    ),
                  },
                ]}
                pageSize={props.pagination && props.pagination.size}
                page={props.pagination && props.pagination.page}
                pages={props.pagination && props.pagination.total}
                onPageChange={(pageIndex) => {
                  //
                  onPageChangeFunction(pageIndex);
                }}
                onPageSizeChange={(pageSize, pageIndex) => {
                  //
                  onPageSizeChangeFunction(pageSize, pageIndex);
                }}
                onFilteredChange={(column, value) => {
                  //
                }}
                className="-striped -highlight"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => {
  //
  return {
    pagination: state.reports_2.pagination,
    loading: state.reports_2.loading,
    error: state.reports_2.error,
    enterExitQueue: state.reports_2.enterExitQueue,
    operators: state.user.operators,
    queues: state.reports_2.queues,
  };
};

const mapDispatchToProps = (dispatch) => {
  //
  return {
    UserAction: bindActionCreators({ UserAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Histories);
