// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle, roseColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { toast, ToastContainer } from "react-toastify";
import { ApiUrl, Url, ConfUrl } from "../../config";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [data, setData] = useState([]);
  const [dataid, setDataid] = useState([]);
  const [loading, isLoading] = useState(false);
  const [user, setUser] = useState("");
  const [see, setSee] = useState(false);
  const [logic, setLogic] = useState(false);
  const [name, setName] = useState(false);

  const getUsers = () => {
    axios
      .get(ApiUrl + "/get-rooms-operator", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);
        setName(false);
        setData(response.data.data.confrences);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const getcoustomerList = () => {
    axios
      .get(ApiUrl + "/all-customer-confrence", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);
        //
        setName(true);
        setData(response.data.data.confrences);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };
  const changestatus = (id, status) => {
    isLoading(true);

    axios
      .get(ApiUrl + "/change-menu-item/" + id + "?status=" + status, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        isLoading(false);
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  useEffect(() => {
    !logic ? getUsers() : getcoustomerList();
  }, [logic]);
  const getusertype = () => {
    isLoading(true);

    axios({
      url: ApiUrl + "/get-user/" + localStorage.getItem("_id"),
      method: "get",
      params: {},
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then(function (response) {
        // if (response.data && response.data.data && response.data.data.user)
        setUser(response.data.data.user);
        getUsers();
      })
      .catch(function (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("_id");
        props.history.push({
          pathname: "/auth",
        });
      })
      .finally(function () {
        // always executed
      });
  };
  const classes = useStyles();
  return (
    <div dir="rtl">
      <button onClick={() => setLogic(false)} className={logic ? "ticketBtn-disactive" : "ticketBtn-active"}>
        اتاق اپراتور
      </button>
      {/* <button
        onClick={() => setLogic(true)}
        className={logic ? "ticketBtn-active" : "ticketBtn-disactive"}
        style={{ marginRight: "20px" }}
      >
        اتاق مشتری
      </button> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>اتاق ها - {pageTitle}</title>
      </Helmet>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader className={classes.cardIconTitle} color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>React Table</h4>
            </CardHeader>
            <CardBody>
              {dataid && dataid.map((index, id) => index === localStorage.getItem("_id") && setSee(true))}

              <ReactTable
                rtl
                getTableProps={() => ({
                  style: { display: "block", textAlign: "right" },
                })}
                data={data instanceof Array ? data.reverse() : []}
                loading={loading}
                filterable
                //   defaultFilterMethod={(row) => String(row.type) !== "video"}
                columns={[
                  name
                    ? {
                        Header: "کد ملی مشتری",
                        accessor: "nationalCode",
                        sortable: false,
                        filterable: false,
                      }
                    : {
                        Header: "نام اتاق",
                        accessor: "name",
                        sortable: false,
                        filterable: false,
                      },
                  name && {
                    Header: " شماره همراه مشتری",
                    accessor: "mobile",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "عملیات",
                    accessor: "_id",
                    sortable: false,
                    filterable: false,
                    Cell: (row) => (
                      <div className="actions-right">
                        <a href={`${ConfUrl}${row.value}`} rel="noopener noreferrer">
                          <button className={"status-btn active"}>ورود</button>
                        </a>
                        <button
                          className={"status-btn active"}
                          onClick={() => {
                            navigator.clipboard.writeText(`${Url.split("api.").join("")}api/clint/room/${row.value}`);
                            toast.success("لینک اتاق با موفقیت کپی شد", {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          }}
                        >
                          کپی لینک
                        </button>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <ToastContainer />
    </div>
  );
}

export default Users;
