import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Ringtone from "components/Ringtone/Ringtone";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Helmet } from "react-helmet";
import { connect } from "react-redux/";
import { bindActionCreators } from "redux";
import { ApiUrl, Url } from "../../config";
import UserAction from "../../js/actions/user_action";
import UserProfile from "../../components/UserProfile/UserProfile";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import DeafaultOperator from "../../assets/img/pic/operator_default.png";
import io from "socket.io-client";
import { pageTitle } from "web-config";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    national_code: "",
    mobile: "",
  });
  const [menus, setMenus] = useState([]);
  const [alertFlag, setAlertFlag] = useState(null);
  const [showAllert, setShowAllert] = useState(
    <SweetAlert
      style={{ display: "block", marginTop: "-100px", fontSize: 10 }}
      title="اطلاعات با موفقیت ویرایش شد"
      onConfirm={() => setAlertFlag(false)}
      confirmBtnCssClass={classes.button + " " + classes.success}
      confirmBtnText="بازگشت"
    ></SweetAlert>
  );

  /////
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const handleClickShowPassword = () => {
    passwordType == "text" ? setPasswordType("password") : setPasswordType("text");
    setShowPassword(!showPassword);
  };
  ////

  /////
  const [showPasswordPre, setShowPasswordPre] = useState(false);
  const [passwordPre, setPasswordPre] = useState("");
  const [passwordTypePre, setPasswordTypePre] = useState("password");
  const handleClickShowPasswordPre = () => {
    passwordTypePre == "text" ? setPasswordTypePre("password") : setPasswordTypePre("text");
    setShowPasswordPre(!showPasswordPre);
  };
  ////

  /////
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordTypeAgain, setPasswordTypeAgain] = useState("password");
  const [showModal, setShowModal] = useState(false);
  const [showProfileChange, setShowProfileChange] = useState(false);
  const handleClickShowPasswordAgain = () => {
    passwordTypeAgain == "text" ? setPasswordTypeAgain("password") : setPasswordTypeAgain("text");
    setShowPasswordAgain(!showPasswordAgain);
  };
  ////

  const getUser = () => {
    let id = localStorage.getItem("_id");

    axios
      .get(ApiUrl + "/get-user/" + id, {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setData({
          email: response.data.data.user.email,
          first_name: response.data.data.user.first_name,
          last_name: response.data.data.user.last_name,
          national_code: response.data.data.user.national_code,
          mobile: response.data.data.user.mobile,
          username: response.data.data.user.username,
        });
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const updatePassword = (e) => {
    if (password && passwordAgain) {
      if (password === passwordAgain) {
        let id = localStorage.getItem("_id");
        axios({
          url: ApiUrl + "/update-password",
          method: "post",
          params: {},
          data: {
            password: password,
            passwordPre: passwordPre,
            id: id,
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("token"),
          },
        })
          .then(function (response) {
            //
            setAlertFlag(true);
            setShowAllert(
              <SweetAlert
                style={{ display: "block", marginTop: "-100px", fontSize: 10 }}
                title="رمز با موفقیت تغییر یافت"
                onConfirm={() => setAlertFlag(false)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                confirmBtnText="بازگشت"
              ></SweetAlert>
            );
            setPassword("");
            setPasswordPre("");
            setPasswordAgain("");
          })
          .catch(function (error) {
            //
            setAlertFlag(true);
            setShowAllert(
              <SweetAlert
                style={{ display: "block", marginTop: "-100px", fontSize: 10 }}
                title="رمز وارد شده صحیح نمی باشد"
                onConfirm={() => setAlertFlag(false)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                confirmBtnText="بازگشت"
              ></SweetAlert>
            );
            setPassword("");
            setPasswordPre("");
            setPasswordAgain("");
          })
          .finally(function () {
            //
          });
      } else {
        //

        setShowAllert(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px", fontSize: 10 }}
            title="رمز وارد شده تطابق ندارد"
            onConfirm={() => setAlertFlag(false)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            confirmBtnText="بازگشت"
          ></SweetAlert>
        );
        setAlertFlag(true);
        setPassword(null);
        setPasswordPre(null);
        setPasswordAgain(null);
      }
    } else {
      setShowAllert(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px", fontSize: 10 }}
          title="وارد کردن فیلدهای رمز اجباری است"
          onConfirm={() => setAlertFlag(false)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          confirmBtnText="بازگشت"
        ></SweetAlert>
      );
      setAlertFlag(true);
    }
  };

  const getMenus = () => {
    axios
      .get(ApiUrl + "/menu-items", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setMenus(response.data.data.menu_items);
      })
      .catch(function (error) {})
      .finally(function () {});
  };
  useEffect(() => {
    getMenus();
    getUser();
  }, []);

  function onSubmit(e) {
    e.preventDefault();
    let id = localStorage.getItem("_id");
    props.store.dispatch(UserAction.Edituser(data, id)).then(function (ec) {
      setAlertFlag(true);
    });
  }

  function handle_change(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  function handle_change_menu(e) {
    var options = e.target.options;
    var value = [];
    setData({
      ...data,
      menu: [],
    });
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setData({
      ...data,
      menu: value,
    });
  }

  const [operatorStatus, setOperatorStatus] = useState("off");
  const [operatorQueues, setOperatorQueues] = useState([]);
  const [onlineOprts, setOnlineOprts] = useState([]);

  useEffect(() => {
    const channel = new BroadcastChannel("tab");

    channel.postMessage("another-tab");
    // note that listener is added after posting the message

    channel.addEventListener("message", (msg) => {
      // if (msg.data === 'another-tab') {
      // message received from 2nd tab
      // alert('یک تب باز دارید');
      // }
    });
  }, []);
  useEffect(() => {
    let socket = io.connect(Url);
    socket.emit("get_online_oprators");
  }, []);
  useEffect(() => {
    let socket = io.connect(Url);
    socket.emit("get_online_oprators");
    socket.on("online_operators", function (users) {
      setOnlineOprts(users);
      let flag = 0;
      if (users && users.length > 0) {
        users.map((user) => {
          if (user["_id"] == localStorage.getItem("_id")) {
            setOperatorStatus("on");
            flag = 1;
          }
        });
        if (flag == 0) {
          setOperatorStatus("off");
        }
      }
    });
  }, []);

  if (props.loading) return <div>...در حال ارسال</div>;
  return (
    <div dir="rtl">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ویرایش عضو - {pageTitle}</title>
      </Helmet>
      {alertFlag && showAllert}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <form onSubmit={(e) => onSubmit(e)}>
            <UserProfile userStatus={operatorStatus} userQueues={operatorQueues} style={{ marginTop: "60px" }} />
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle} color={"var(--textColor)"}>
                    ویرایش عضو جدید
                  </h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>نام کاربری دیدار</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "username",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.username,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>ایمیل</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "email",
                          name: "email",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.email,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>تلفن همراه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "mobile",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.mobile,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>نام</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "first_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.first_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <FormLabel className={classes.labelHorizontal}>نام خانوادگی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "last_name",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.last_name,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={2}>
                      <FormLabel className={classes.labelHorizontal}>کد ملی</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <CustomInput
                        // success={requiredState === "success"}
                        // error={requiredState === "error"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          name: "national_code",
                          onChange: (e) => {
                            handle_change(e);
                          },
                          value: data.national_code,
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <FormLabel className={classes.labelHorizontal}>بخش های مربوطه</FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <select
                        onChange={(e) => {
                          handle_change_menu(e);
                        }}
                        name="menu"
                        className={"menus"}
                        multiple
                      >
                        {menus.map((e, i) => {
                          return (
                            <option style={{ background: `url(${Url + e.icon}) no-repeat` }} value={e._id} key={i}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </GridItem>
                  </GridContainer>
                </form>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type={"submit"} color="rose">
                  ذخیره
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} color={"var(--textColor)"}>
                  ویرایش رمز عبور{" "}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>رمز عبور پیشین</FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={requiredState === "error"}
                    style={{
                      background: `url('@material-ui/icons/Visibility') no-repeat`,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: passwordTypePre,
                      name: "passwordAgain",
                      onChange: (e) => {
                        setPasswordPre(e.target.value);
                      },
                      value: passwordPre,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPasswordPre}>
                            {showPasswordPre ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  >
                    sd
                  </CustomInput>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>رمز عبور جدید</FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: passwordType,
                      name: "password",
                      onChange: (e) => {
                        setPassword(e.target.value);
                      },
                      value: password,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormLabel className={classes.labelHorizontal}>تکرار رمز عبور جدید</FormLabel>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: passwordTypeAgain,
                      name: "passwordAgain",
                      onChange: (e) => {
                        setPasswordAgain(e.target.value);
                      },
                      value: passwordAgain,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPasswordAgain}>
                            {showPasswordAgain ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button type={"button"} onClick={() => updatePassword()} color="rose">
                تغییر رمز عبور
              </Button>
            </CardFooter>
          </Card>
          <Ringtone />
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
