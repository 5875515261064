import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl, Url } from "../../config";

function FormValuesPage(props) {
  const [data, setData] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");

  const formId = props.match.params.id;
  const getData = async () => {
    await axios
      .get(ApiUrl + "/get-service-form/" + formId, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
        res.data.data.fields.forEach((field) => {
          if (field.ref === "city") {
            getCityById(field.value);
          } else if (field.ref === "province") {
            getProvinceById(field.value);
          }
        });
      });
  };
  const getCityById = async (id) => {
    await axios
      .get(ApiUrl + "/get-city/" + id, { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setCity(res.data.data[0]);
      });
  };
  const getProvinceById = async (id) => {
    await axios
      .get(ApiUrl + "/get-provinces/", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setProvince(res.data.data.filter((item) => item.id == id)[0]);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          backgroundColor: "var(--darkWhite)",
          padding: "10px 20px",
          borderRadius: "5px",
        }}
      >
        <img src={data.model ? Url + data.model.icon : ""} height={"35px"} alt="آیکون" />
        <Typography component="h1" variant="h5">
          {data.model ? data.model.name : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {data.fields &&
          data.fields.map((field, index) => (
            <Box
              key={field._id}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <TextField
                label={field.placeholder}
                placeholder={field.placeholder}
                value={field.ref === "city" ? city.name : field.ref === "province" ? province.name : field.value}
                name={field.name}
                readOnly={true}
                InputLabelProps={{ shrink: true }}
                type={field.type}
                sx={{
                  minWidth: "200px !important",
                  backgroundColor: "var(--white)",
                  width: "100%",
                  direction: "rtl",
                  "& .MuiFormLabel-root": {
                    top: "-20px !important",
                  },
                }}
              ></TextField>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default FormValuesPage;
