import { css } from "@emotion/react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor, roseColor, whiteColor } from "assets/jss/material-dashboard-pro-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux/";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { ApiUrl } from "../../config";
import UserAction from "../../js/actions/user_action";
import AddClientConfrence from "../Confrence/AddClientConfrence";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor,
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: grayColor[11],
    fontSize: ".875rem",
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: grayColor[0],
  },
  "rt-resizable-header-content": {
    textAlign: "right",
    fontFamily: "IRANSans !important",
  },
};

const useStyles = makeStyles(styles);

function Users(props) {
  const [loading, isLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const success = () => {
    toast.success("اتاق با موفقیت ایجاد شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const [users, setusers] = useState([]);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(ApiUrl + "/get-users?size=1000&page=1", {
        params: {},
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        setusers(response.data.data.users);
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  const onSubmit = (data) => {
    // e.preventDefault();
    // var bodyFormData = new FormData(data);
    //
    isLoading(true);
    // e.target.reset();
    axios({
      url: ApiUrl + "/create-confrence",
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: data,
    })
      .then(function (response) {
        //
        isLoading(false);
        success();
      })
      .catch(function (error) {
        isLoading(false);
      })
      .finally(function () {
        isLoading(false);
      });
  };

  const classes = useStyles();

  if (props.loading) return <div>...در حال ارسال</div>;
  return (
    <div>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>افزودن اتاق - خانه بدن</title>
      </Helmet>
      <form id={"from"}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}> */}
      <AddClientConfrence success={success} />
      {/* </GridItem>
        </GridContainer>
      </form>
      <ToastContainer /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.user.data,
  loading: state.user.loading,
  error: state.user.error,
});

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators({ UserAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
