import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import React, { useEffect, useState } from "react";
import CircularButton from "shared/Buttons/CircularButton/CircularButton";
import io from "socket.io-client";
import { Url } from "../../config";
import OnlineClients from "../OnlineClients/OnlineClients";
import OnlineOperators from "../OnlineOperators/OnlineOperators";
import UserProfile from "../UserProfile/UserProfile";
import styles from "./OperatorDashboard.module.scss";
import { Calendar } from "react-multi-date-picker";
import { makeStyles } from "@material-ui/core/styles";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const useStyles = makeStyles({
  calendar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const OperatorDashboard = (props) => {
  const [operatorStatus, setOperatorStatus] = useState("off");
  const [operatorQueues, setOperatorQueues] = useState([]);
  const [onlineOprts, setOnlineOprts] = useState([]);
  const [dateValue, setDateValue] = useState(new Date());
  const classes = useStyles();
  useEffect(() => {
    const channel = new BroadcastChannel("tab");

    channel.postMessage("another-tab");
    // note that listener is added after posting the message

    channel.addEventListener("message", (msg) => {
      // if (msg.data === 'another-tab') {
      // message received from 2nd tab
      // alert('یک تب باز دارید');
      // }
    });
  }, []);
  useEffect(() => {
    let socket = io.connect(Url);
    socket.emit("get_online_oprators");
  }, []);
  useEffect(() => {
    let socket = io.connect(Url);
    socket.emit("get_online_oprators");
    socket.on("online_operators", function (users) {
      setOnlineOprts(users);
      let flag = 0;
      if (users && users.length > 0) {
        users.map((user) => {
          if (user["_id"] == localStorage.getItem("_id")) {
            setOperatorStatus("on");
            flag = 1;
          }
        });
        if (flag == 0) {
          setOperatorStatus("off");
        }
      }
    });
  }, []);

  return (
    <>
      {/* <Hidden xsDown> */}
      {/* <div style={{ padding: "0 4rem", display : 'flex', justifyContent : 'center',}}> */}
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid container sx={12} sm={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignSelf: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <UserProfile userStatus={operatorStatus} userQueues={operatorQueues} />
          </div>
          <Hidden xsDown>
            <div
              style={{ width: "100%", marginTop: "2rem", border: "1px solid var(--lightGray)", borderRadius: "5px" }}
            >
              <OnlineOperators onlineOprts={onlineOprts} />
            </div>
          </Hidden>
        </Grid>

        <Grid item sm={1}></Grid>

        <Grid
          container
          sm={2}
          sx={12}
          className={styles.statusBtn}
          justifyContent={"center"}
          alignContent={"center"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "center",
            textAlign: "center",
            width: "100%",
            margin: "2rem 0",
            // backgroundColor : 'blue'
          }}
        >
          <Grid item sx={2}></Grid>
          <Grid item sx={6} sm={12} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CircularButton
              operatorStatus={operatorStatus}
              setOperatorStatus={setOperatorStatus}
              setOperatorQueues={setOperatorQueues}
            />
          </Grid>
          <Grid item sx={2}></Grid>
        </Grid>

        <Grid item sm={1}></Grid>

        <Grid
          container
          sx={12}
          sm={4}
          style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        >
          <div
            style={{
              width: "100%",
              border: "1px solid var(--lightGray)",
              borderRadius: "5px",
            }}
          >
            {/*<GeneralInfo />*/}
            <Calendar
              calendar={persian}
              className={classes.calendar}
              value={dateValue}
              onChange={setDateValue}
              locale={persian_fa}
              showOtherDays={true}
            />
          </div>
          <Hidden smUp>
            <div
              style={{ width: "100%", marginTop: "2rem", border: "1px solid var(--lightGray)", borderRadius: "5px" }}
            >
              <OnlineOperators onlineOprts={onlineOprts} />
            </div>
          </Hidden>
          <div style={{ width: "100%", marginTop: "2rem", border: "1px solid var(--lightGray)", borderRadius: "5px" }}>
            <OnlineClients />
          </div>
        </Grid>
      </Grid>

      {/* </div> */}
    </>
  );
};

export default OperatorDashboard;
