import FAQCard from "../FAQCard/FAQCard";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl } from "config";
import React, { useEffect, useState } from "react";
import CardView from "components/CardView/CardView";
import Slider from "react-slick";
import SweetAlert from "react-bootstrap-sweetalert";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

function AdminNews(props) {
  const [blogData, setBlogData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [queue, setQueue] = useState([]);
  const [word, setWord] = useState("");
  const [reload, setReload] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    adaptiveHeight: true,
    arrows: false,
    // appendDots: (dots) => <ul className={classes.dots}>{dots}</ul>,
    // customPaging: (i) => (
    //     <div className="ft-slick__dots--custom">
    //         <div className="custom-dot" />
    //     </div>
    // ),
  };

  const getNews = async () => {
    await axios
      .get(ApiUrl + "/main-blog", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        setBlogData(res.data.data.blog);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.forEach(async (item) => {
          await axios
            .get(ApiUrl + "/menu-blog/" + item._id, {
              headers: { Authorization: localStorage.getItem("token") },
            })
            .then((res) => {
              if (res.data.data.length > 0) {
                let obj = {
                  menu: item._id,
                  name: item.name,
                  news: res.data.data,
                };
                setMenu((prev) => [...prev, obj]);
              }
            });
        });
        setQueue(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    getNews();
    getQueues();
  }, []);
  useEffect(() => {
    // getFaqs();
  }, [word]);
  return (
    <Box width={"100%"} sx={{ direction: "ltr" }} display={"list-item"} overflow={"auto"} maxHeight={"600px"}>
      {/*<Box width={"100%"}>*/}
      {/*  <TextField*/}
      {/*    variant="outlined"*/}
      {/*    placeholder="جستجو"*/}
      {/*    onChange={(e) => setWord(e.target.value)}*/}
      {/*    style={{ width: "100%" }}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box
        width={"100%"}
        padding={"16px"}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Box>
          <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
            صفحه اصلی
          </Typography>
          <hr />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "16px",
              flexWrap: "wrap",
            }}
          >
            {blogData.length > 0
              ? blogData.map((p, index) => {
                  p.status === false && index++;
                  return (
                    <Box key={p._id}>
                      <CardView
                        setReload={setReload}
                        data={p}
                        img={p.image}
                        title={p.title}
                        text={p.text}
                        api={"/update-blog"}
                      />
                    </Box>
                  );
                })
              : "هیچ خبری در صفحه اصلی وجود ندارد"}
          </Box>
        </Box>
        {menu.map((item) => {
          return (
            <Box key={item._id}>
              <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
                {item.name}
              </Typography>
              <hr />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  gap: "16px",
                  flexWrap: "wrap",
                }}
              >
                {item.news.map((p, index) => {
                  p.status === false && index++;
                  return (
                    <Box key={p._id}>
                      <CardView
                        setReload={setReload}
                        data={p}
                        img={p.image}
                        title={p.title}
                        text={p.text}
                        api={"/update-blog"}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default AdminNews;
