import React, { useEffect } from "react";

function VideoConfrence() {
  useEffect(() => {
    videoPlay();
  }, []);
  const videoPlay = () => {
    var video = document.querySelector("#videoElement");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
        })
        .catch(function (err0r) {
          //
        });
    }
  };
  return (
    <div>
      <video autoPlay="true" id="videoElement"></video>
    </div>
  );
}

export default VideoConfrence;
