import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import styles from "../../style/Recharts/CallsStatus.module.scss";
import { BsSortDown } from "react-icons/bs";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment-jalaali";
import Loading from "components/Loading";
import ButtonXlsx from "components/Recharts/ButtonChartXls/ButtonXlsx";
import Select from "react-select";
import { ApiUrl } from "../../config";

const token = localStorage.getItem("token");

function CallsStatus(props) {
  const [days, setDays] = useState(10);
  const [chartData, setChartData] = useState([]);
  const [apiRequest, setApiRequest] = useState("daily-status-call-history");
  const [status, setStatus] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const daysOption = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 30, label: 30 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
  ];

  const data = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${ApiUrl}/${apiRequest}/${days}`, {
        headers: {
          Authorization: token,
        },
      });
      convertData(res.data.data);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      return (
        <div
          style={{
            zIndex: 1000,
            backgroundColor: "#f9f9f9",
            padding: "5px",
            borderRadius: "8px",
            opacity: "0.9",
            display: "flex",
          }}
        >
          <p className="label" style={{ textAlign: "center" }}>
            {" "}
            تاریخ: <span>{label}</span>
          </p>
          {payload.map((item, index) => {
            return (
              <div key={index}>
                {item.name == "answer" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس های تصویری موفق: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "rejected" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس های تصویری رد شده توسط اپراتور: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "calling" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس تصویری بی پاسخ یا لغو شده توسط عضو: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "answerChat" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس های متنی موفق: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "rejectedChat" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس های متنی رد شده توسط اپراتور: <span>{item.value}</span>
                  </p>
                ) : null}
                {item.name == "callingChat" ? (
                  <p className="label" style={{ textAlign: "center", margin: "5px" }}>
                    {" "}
                    تماس های متنی بی پاسخ یا لغو شده توسط عضو: <span>{item.value}</span>
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    data();
  }, [days]);

  const convertData = (data) => {
    let arr = [];

    data.forEach((item) => {
      let obj = {
        date: "",
        calling: 0,
        answer: 0,
        rejected: 0,
        callingChat: 0,
        answerChat: 0,
        rejectedChat: 0,
        count: 0,
      };
      obj.date = new Date(item._id.day).toLocaleDateString("fa-IR", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      });
      obj.count = parseInt(item.count);

      setStatus([
        ...status,
        {
          date: new Date(item._id.day).toLocaleDateString("fa-IR", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }),
          count: parseInt(item.count),
        },
      ]);
      if (item._id.status === "calling") {
        obj.calling = parseInt(item.count);
      }
      if (item._id.status === "answer") {
        obj.answer = parseInt(item.count);
      }
      if (item._id.status === "rejected") {
        obj.rejected = parseInt(item.count);
      }
      if (item._id.status === "calling-chat") {
        obj.callingChat = parseInt(item.count);
      }
      if (item._id.status === "answer-chat") {
        obj.answerChat = parseInt(item.count);
      }
      if (item._id.status === "rejected-chat") {
        obj.rejectedChat = parseInt(item.count);
      }
      arr.push(obj);
    });
    let res = arr.reduce((ac, a) => {
      let ind = ac.findIndex((x) => x.date === a.date);
      // eslint-disable-next-line no-unused-expressions
      ind === -1
        ? ac.push(a)
        : ((ac[ind].calling += a.calling),
          (ac[ind].answer += a.answer),
          (ac[ind].rejected += a.rejected),
          (ac[ind].callingChat += a.callingChat),
          (ac[ind].answerChat += a.answerChat),
          (ac[ind].rejectedChat += a.rejectedChat));
      return ac;
    }, []);
    res.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });
    setChartData(res);
    return setLoading(false);
  };
  const selectDayHandler = (e) => {
    setDays(e.value);
  };

  const LabelCustom = ({ active, label, payload }) => {
    if (active && payload && payload.length) {
      return <div className={styles.customLabel}></div>;
    }
    return <></>;
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: "1px solid #9c27b0",
      transition: ".5s",
    }),
    option: (provided, state) => ({
      fontSize: "1rem",
      borderBottom: "none",
      color: state.isSelected ? "black" : "blue",
      fontWeight: state.isSelected && "bold",
      padding: 20,
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        color: "white",
        background: "#9c27b0",
        transition: ".3s",
      },
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: 200,
      fontSize: "1rem",
      border: "1px solid black",
      borderRadius: "5px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <Card>
      <CardHeader color="info">
        <h4>تعداد تماس روزانه بر اساس وضعیت</h4>
      </CardHeader>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.filterWrapper}>
            <div className={styles.selectContainer}>
              <Select
                styles={customStyles}
                defaultValue={days}
                options={daysOption}
                onChange={selectDayHandler}
                placeholder={"تعداد روز های گذشته"}
                className={styles.SelectBox}
              />
            </div>
            <ButtonXlsx apiRequest={apiRequest} days={days} />
          </div>
          <div className={styles.chart}>
            <ResponsiveContainer className={styles.rechartResponsiveContainer}>
              {!loading ? (
                <LineChart
                  width={300}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey={"date"} orientation="bottom" allowDuplicatedCategory={false} />
                  <YAxis dataKey={"count"} orientation="left" domain={[0, (dataMax) => dataMax * 2]} type={"number"} />

                  <Line type="monotone" dataKey="calling" stackId="1" stroke="#f3c53e" fill="#f3c53e" />
                  <Line type="monotone" dataKey="answer" stackId="1" stroke="#59AA2B" fill="#59AA2B" />
                  <Line type="monotone" dataKey="rejected" stackId="1" stroke="#F04F47" fill="#F04F47" />
                  <Line type="monotone" dataKey="callingChat" stackId="1" stroke="#ffa801" fill="#F7F7B9" />
                  <Line type="monotone" dataKey="answerChat" stackId="1" stroke="#00acc1" fill="#00acc1" />
                  <Line type="monotone" dataKey="rejectedChat" stackId="1" stroke="#BB2124" fill="#BB2124" />
                  <Tooltip
                    cursor={{ stroke: "grey", strokeWidth: 1 }}
                    content={<CustomTooltip />}
                    position={{
                      x: 0,
                      y: -100,
                    }}
                  />
                </LineChart>
              ) : (
                <Loading loading={loading} />
              )}
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default CallsStatus;
