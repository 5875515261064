import FAQCard from "components/FAQCard/FAQCard";
import { Box, TextField, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl } from "config";
import React, { useEffect, useState } from "react";

function AdminFAQ(props) {
  const [faqData, setFaqData] = useState([]);
  const [menu, setMenu] = useState([]);
  const [queue, setQueue] = useState([]);
  const [word, setWord] = useState("");
  const getFaqs = async () => {
    await axios
      .get(ApiUrl + "/faqs", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const filteredData = res.data.data.faqs.filter((el) => {
          if (word === "") {
            return el;
          } else {
            return el.question.match(word) || el.answer.match(word);
          }
        });

        setFaqData(filteredData);
      })
      .catch((res) => {});
  };
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        res.data.data.forEach(async (item) => {
          await axios
            .get(ApiUrl + "/menu-faq/" + item._id, {
              headers: { Authorization: localStorage.getItem("token") },
            })
            .then((res) => {
              if (res.data.data.faqs.length > 0) {
                let obj = {
                  menu: item._id,
                  name: item.name,
                  faqs: res.data.data.faqs,
                };
                setMenu((prev) => [...prev, obj]);
              }
            });
        });
        setQueue(res.data.data);
      })
      .catch((res) => {});
  };
  useEffect(() => {
    getFaqs();
    getQueues();
  }, []);
  useEffect(() => {
    getFaqs();
  }, [word]);
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"24px"} sx={{ direction: "ltr" }}>
      {/* <Box width={"100%"}>
        <TextField
          variant="outlined"
          placeholder="جستجو"
          onChange={(e) => setWord(e.target.value)}
          style={{ width: "100%" }}
        />
      </Box> */}
      <Box width={"100%"} padding={"16px"} maxHeight={"400px"} overflow={"auto"}>
        {menu.map((item) => {
          return (
            <Box key={item._id}>
              <Typography fontSize={"16px"} fontWeight={700} margin={"10px 0"}>
                {item.name}
              </Typography>
              <Box padding={"0 10px"}>
                {item.faqs.map((p) => {
                  return <FAQCard key={item._id} data={p} admin={true} />;
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default AdminFAQ;
