import React, { useEffect, useState } from "react";
import axios from "axios";
import { ApiUrl } from "../../config";
import { toast } from "react-toastify";
import { Box, Button, makeStyles, TextField } from "@material-ui/core";
import { Close, FileCopy, ImageRounded } from "@material-ui/icons";
import { MenuItem } from "@mui/material";
import FormLabel from "@material-ui/core/FormLabel";
import { TbPng } from "react-icons/tb";

const useStyles = makeStyles({
  textarea: {
    resize: "none",
    borderRadius: "8px",
    width: "100%",
    border: "1px solid var(--black)",
    padding: "10px",
  },
  root: {
    width: "100%",
  },
});

function AddProductSlider(props) {
  const classes = useStyles();
  const [queue, setQueue] = useState({});
  const [file, setFile] = useState("");
  const [uploadState, setUploadState] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    text: "",
    image: "",
    icon: "",
    position: "bottom",
  });
  const [dataSelectQueue, setDataSelectQueue] = useState([]);
  const getQueues = async () => {
    await axios
      .get(ApiUrl + "/get-queues", { headers: { Authorization: localStorage.getItem("token") } })
      .then((res) => {
        const options = [];
        res.data.data.forEach((item) => {
          options.push({
            value: item._id,
            label: item.name === "کیپاد" ? "پی پاد" : item.name,
          });
        });
        setDataSelectQueue(options);
      })
      .catch((res) => {});
  };
  const addSlide = async () => {
    setLoading(true);
    let form = new FormData();
    form.append("image", data.image, data.image.name);
    form.append("name", data.title);
    form.append("text", data.text);
    form.append("icon", data.icon);
    form.append("position", data.position);
    await axios
      .post(ApiUrl + "/save-product-slide", form, {
        headers: { Authorization: localStorage.getItem("token"), "content-type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadState(progress);
        },
      })
      .then((res) => {
        toast.success("با موفقیت ثبت شد");
      })
      .catch((res) => toast.error("خطا در ثبت"))
      .finally(() => setLoading(false));
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  useEffect(() => {
    getQueues();
  }, []);

  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      padding: 10,
      display: "flex",
      alignItems: "center",
      zIndex: 999999,
    }),
    control: (base) => ({
      ...base,
      // height: "100px",
      width: "100%",
      border: "1px solid black",
      zIndex: 999999,
    }),
    menu: (base) => ({
      ...base,
      height: "fit-content",
      position: "absolute",
      zIndex: 999999,
    }),
  };
  let handleChangeQueue = (newValue, actionMeta) => {
    setQueue(newValue);
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
      width={"100%"}
      height={"100%"}
      sx={{ gap: "10px" }}
    >
      <TextField
        name="title"
        variant="outlined"
        placeholder="عنوان"
        className={classes.root}
        onChange={onChangeHandler}
        value={data.title}
      />
      <TextField
        name="text"
        variant="outlined"
        placeholder="توضیحات اسلاید"
        className={classes.root}
        onChange={onChangeHandler}
        value={data.text}
      />
      <FormLabel>موقعیت متن اسلایدر</FormLabel>
      <TextField
        name="position"
        variant="outlined"
        style={{ width: "200px" }}
        placeholder="موقعیت متن روی اسلاید"
        className={classes.root}
        onChange={onChangeHandler}
        select
        value={data.position}
      >
        <MenuItem value={"top"}>بالا</MenuItem>
        <MenuItem value={"bottom"}>پایین</MenuItem>
      </TextField>
      <Box width={"200px"} style={{ gap: "10px" }} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Box display={"flex"} style={{ gap: "10px" }} justifyContent={"flex-start"} alignItems={"center"}>
          <Button
            endIcon={<ImageRounded style={{ margin: "0 10px" }} />}
            variant={"text"}
            component={"label"}
            style={{
              width: "200px",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf"
              hidden
              onChange={(e) => {
                setData({ ...data, image: e.target.files[0] });
              }}
            />
            {data.image ? data.image.name : " آپلود عکس اسلاید"}
          </Button>
          {data.image && <Close onClick={(e) => setData({ ...data, image: "" })} style={{ cursor: "pointer" }} />}
        </Box>
        <Box display={"flex"} style={{ gap: "10px" }} justifyContent={"flex-start"} alignItems={"center"}>
          <Button
            endIcon={<TbPng style={{ margin: "0 10px" }} />}
            variant={"text"}
            component={"label"}
            style={{
              width: "200px",
              height: "100%",
              minWidth: "100px",
              backgroundColor: "var(--primary)",
              color: "var(--white)",
            }}
          >
            <input
              type="file"
              accept=".jpg,.jpeg,.ico,.png"
              hidden
              onChange={(e) => {
                setData({ ...data, icon: e.target.files[0] });
              }}
            />
            {data.icon ? data.icon.name : " آیکون"}
          </Button>
          {data.icon && <Close onClick={(e) => setData({ ...data, icon: "" })} style={{ cursor: "pointer" }} />}
        </Box>
      </Box>
      <Button
        color="primary"
        style={{ backgroundColor: "var(--primary)", color: "var(--white)", width: "200px" }}
        onClick={addSlide}
        disabled={loading}
      >
        {loading ? uploadState : "افزودن"}
      </Button>
    </Box>
  );
}

export default AddProductSlider;
